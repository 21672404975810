import '../Navbar.css';
import Axios from 'axios';
import { Col, Row } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import * as ReactBootstrap from 'react-bootstrap';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import Container from 'react-bootstrap/Container';
import { Form, FormControl, Button, Image } from 'react-bootstrap';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Navbar from '../../Navbar';
import Modal from 'react-bootstrap/Modal';
import ConfirmAlert from '../common/ConfirmAlert';
import './../CQ.module.css';
import { Card } from 'react-bootstrap';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
// import FooterComponent from '../FooterComponent';
import ReactStars from 'react-rating-stars-component';
import { getRatingUtilityClass } from '@mui/material';
const { SearchBar, ClearSearchButton } = Search;
const { ExportCSVButton } = CSVExport;

const Allcomplaints = () => {
  const [players, setPlayers] = useState([]);
  const [players2, setPlayers2] = useState([]);
  const [players3, setPlayers3] = useState([]);
  const [players4, setPlayers4] = useState([]);
  const [players5, setPlayers5] = useState([]);
  const [playerslabel, setPlayerslabel] = useState([]);
  const [players2label, setPlayers2label] = useState([]);
  const [players3label, setPlayers3label] = useState([]);
  const [players4label, setPlayers4label] = useState([]);
  const [players5label, setPlayers5label] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, settotal] = useState(0);
  const [approved, setapproved] = useState(0);
  const [newtoday, setnewtoday] = useState(0);
  const [rejected, setrejected] = useState(0);
  const [draft, setdraft] = useState(0);
  const [rate, setrate] = useState(0);
  const [finalrole, setfinalrole] = useState();
  const [buttondisabled, setbuttondisabled] = useState();
  const [rejectionreason, setrejectionreason] = useState();
  const [isloading, setisloading] = useState(true);
  const [successData, setSuccessData] = useState();
  const [submitdraftstatus, setsubmitdraftstatus] = useState(false);
  const [requeststatus, setrequeststatus] = useState();
  const [successData2, setSuccessData2] = useState();
  const [successData3, setSuccessData3] = useState();
  const [successData4, setSuccessData4] = useState();
  const [requestno, setrequestno] = useState();
  const [retailerid, setretailerid] = useState('');
  const [columnhide, setcolumnhide] = useState(true);
  const [columnhide2, setcolumnhide2] = useState(true);

  const [value, setValue] = useState('1');
  const handleChangetab = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    Axios.get(`/fetchRole?email=${localStorage.getItem('username')}`).then((response) => {
      if (response?.data?.recordset?.length > 0) {
        localStorage.setItem('role', response?.data?.recordset[0]?.roleName);
      } else {
        localStorage.setItem('role', 'Employee');
      }
      if (response?.data?.recordset?.length > 0) {
        localStorage.setItem('firstName', response?.data?.recordset[0]?.firstName);
        localStorage.setItem('lastName', response?.data?.recordset[0]?.lastName);
        localStorage.setItem('employeeID', response?.data?.recordset[0]?.employeeID);
        localStorage.setItem('company', response?.data?.recordset[0]?.company);
        localStorage.setItem('warehouse', response?.data?.recordset[0]?.warehouse);
      } else {
        localStorage.setItem('firstName', '');
        localStorage.setItem('lastName', '');
        localStorage.setItem('employeeID', '');
        localStorage.setItem('company', '');
        localStorage.setItem('warehouse', '');
      }
    });
  }, []);
  useEffect(() => {
    Axios.post('/activitylogs/api', {
      requestno: null,
      activityname: 'Home Page Visited',
      activitydescription: 'Home page is visited by the user',
      pagename: 'Home Page',
      emailid: localStorage.getItem('username'),
      empid: localStorage.getItem('employeeID'),
    }).then((response) => {
      console.log(response.data);
    });
  }, []);
  const columns = [
    {
      dataField: 'dbaName',
      text: 'DBA Name',
      formatter: linkFormatter,
      headerStyle: {
        backgroundColor: '#000',
        color: '#e3af32',
        fontWeight: '400',
      },
      style: { fontSize: '14px' },
      sort: true,
    },
    {
      dataField: 'requestNo',
      text: 'Request No',
      hidden: true,
      headerStyle: {
        backgroundColor: '#000',
        color: '#e3af32',
        fontWeight: '400',
      },
      style: { fontSize: '14px' },
      sort: true,
    },
    {
      dataField: 'requestDate',
      text: 'Submitted On',
      headerStyle: {
        backgroundColor: '#000',
        color: '#e3af32',
        fontWeight: '400',
      },
      style: { fontSize: '14px' },
      sort: true,
    },
    {
      dataField: 'finalName',
      text: 'Sales Rep',
      hidden: columnhide,
      headerStyle: {
        backgroundColor: '#000',
        color: '#e3af32',
        fontWeight: '400',
      },
      style: { fontSize: '14px' },
      sort: true,
    },
    {
      dataField: 'requestorEmailID',
      text: 'Email',
      headerStyle: {
        backgroundColor: '#000',
        color: '#e3af32',
        fontWeight: '400',
      },
      style: { fontSize: '14px' },
      sort: true,
      hidden: true,
    },
    {
      dataField: 'status',
      text: 'Status',
      headerStyle: {
        backgroundColor: '#000',
        color: '#e3af32',
        fontWeight: '400',
      },
      style: { fontSize: '14px' },
      sort: true,
    },
    {
      dataField: 'jiraID',
      text: 'Jira ID',
      headerStyle: {
        backgroundColor: '#000',
        color: '#e3af32',
        fontWeight: '400',
      },
      style: { fontSize: '14px' },
      sort: true,
    },
    {
      dataField: 'rejectionReason',
      text: 'Rejection Reason',
      headerStyle: {
        backgroundColor: '#000',
        color: '#e3af32',
        fontWeight: '400',
      },
      style: { fontSize: '14px' },
      sort: true,
    },
  ];

  const columns5 = [
    {
      dataField: 'dbaName',
      text: 'DBA Name',
      formatter: linkFormatter,
      headerStyle: {
        backgroundColor: '#000',
        color: '#e3af32',
        fontWeight: '400',
      },
      style: { fontSize: '14px' },
      sort: true,
    },
    {
      dataField: 'requestNo',
      text: 'Request No',
      hidden: true,
      headerStyle: {
        backgroundColor: '#000',
        color: '#e3af32',
        fontWeight: '400',
      },
      style: { fontSize: '14px' },
      sort: true,
    },
    {
      dataField: 'requestDate',
      text: 'Submitted On',
      headerStyle: {
        backgroundColor: '#000',
        color: '#e3af32',
        fontWeight: '400',
      },
      style: { fontSize: '14px' },
      sort: true,
    },
    {
      dataField: 'finalName',
      text: 'Sales Rep',
      hidden: columnhide,
      headerStyle: {
        backgroundColor: '#000',
        color: '#e3af32',
        fontWeight: '400',
      },
      style: { fontSize: '14px' },
      sort: true,
    },
    {
      dataField: 'requestorEmailID',
      text: 'Email',
      headerStyle: {
        backgroundColor: '#000',
        color: '#e3af32',
        fontWeight: '400',
      },
      style: { fontSize: '14px' },
      sort: true,
      hidden: true,
    },
    {
      dataField: 'retailerID',
      text: 'Retailer ID',
      headerStyle: {
        backgroundColor: '#000',
        color: '#e3af32',
        fontWeight: '400',
      },
      style: { fontSize: '14px' },
      sort: true,
    },
    {
      dataField: 'status',
      text: 'Status',
      headerStyle: {
        backgroundColor: '#000',
        color: '#e3af32',
        fontWeight: '400',
      },
      style: { fontSize: '14px' },
      sort: true,
    },
    {
      dataField: 'jiraID',
      text: 'Jira ID',
      headerStyle: {
        backgroundColor: '#000',
        color: '#e3af32',
        fontWeight: '400',
      },
      style: { fontSize: '14px' },
      sort: true,
    },
  ];

  const columns3 = [
    {
      dataField: 'dbaName',
      text: 'DBA Name',
      formatter: linkFormatter,
      headerStyle: {
        backgroundColor: '#000',
        color: '#e3af32',
        fontWeight: '400',
      },
      style: { fontSize: '14px' },
      sort: true,
    },
    {
      dataField: 'requestNo',
      text: 'Request No',
      hidden: true,
      headerStyle: {
        backgroundColor: '#000',
        color: '#e3af32',
        fontWeight: '400',
      },
      style: { fontSize: '14px' },
      sort: true,
    },
    {
      dataField: 'requestDate',
      text: 'Submitted On',
      headerStyle: {
        backgroundColor: '#000',
        color: '#e3af32',
        fontWeight: '400',
      },
      style: { fontSize: '14px' },
      sort: true,
    },
    {
      dataField: 'finalName',
      text: 'Sales Rep',
      hidden: columnhide,
      headerStyle: {
        backgroundColor: '#000',
        color: '#e3af32',
        fontWeight: '400',
      },
      style: { fontSize: '14px' },
      sort: true,
    },
    {
      dataField: 'requestorEmailID',
      text: 'Email',
      headerStyle: {
        backgroundColor: '#000',
        color: '#e3af32',
        fontWeight: '400',
      },
      style: { fontSize: '14px' },
      sort: true,
      hidden: true,
    },
    {
      dataField: 'status',
      text: 'Status',
      headerStyle: {
        backgroundColor: '#000',
        color: '#e3af32',
        fontWeight: '400',
      },
      style: { fontSize: '14px' },
      sort: true,
    },
    {
      dataField: 'rejectionReason',
      text: 'Rejection Reason',
      headerStyle: {
        backgroundColor: '#000',
        color: '#e3af32',
        fontWeight: '400',
      },
      style: { fontSize: '14px' },
      sort: true,
    },
  ];

  async function hideAlert() {
    window.location.reload();
  }

  async function hideAlert2() {
    window.location.reload();
  }

  const handleApprove = () => {
    setShow9(false);
    setShow8(true);
    setbuttondisabled(true);
    setisloading(true);
    Axios.post('/approval/api', {
      requestno: requestno,
      approverfirstname: localStorage.getItem('firstName'),
      approverlastname: localStorage.getItem('lastName'),
      approverempid: localStorage.getItem('employeeID'),
      approveremailid: localStorage.getItem('username'),
      approvercompany: localStorage.getItem('company'),
      approverwarehouse: localStorage.getItem('warehouse'),
    }).then((response) => {
      setShow8(false);
      console.log('Approval response', response);
      if (response.data == 'Retailer ID Exhausted') {
        setrequeststatus('Failed');
        setsubmitdraftstatus('Retailer ID Exhausted');
        setSuccessData2(true);
      } else if (response.data == 'JIRA API error') {
        setrequeststatus('Failed');
        setsubmitdraftstatus('JIRA API/SQL Error');
        setSuccessData2(true);
      } else if (response.data == 'Warehousecode undefined') {
        setrequeststatus('Failed');
        setsubmitdraftstatus('Warehousecode Undefined');
        setSuccessData2(true);
      } else if (response.data == 'Already Approved') {
        setrequeststatus('Failed');
        setsubmitdraftstatus(
          'This request is already approved/rejected. Close this to refresh your page',
        );
        setSuccessData2(true);
      } else {
        setretailerid(response.data);
        setrequeststatus('Approved');
        setsubmitdraftstatus(`Request No: ${requestno} is Approved, Retailer ID: `);
        setSuccessData4(true);
      }
    });
  };

  const handleReject = () => {
    setShow7(false);
    setShow8(true);
    setbuttondisabled(true);
    setisloading(true);
    Axios.post('/reject/api', {
      requestno: requestno,
      approverfirstname: localStorage.getItem('firstName'),
      approverlastname: localStorage.getItem('lastName'),
      approverempid: localStorage.getItem('employeeID'),
      approveremailid: localStorage.getItem('username'),
      approvercompany: localStorage.getItem('company'),
      approverwarehouse: localStorage.getItem('warehouse'),
      rejectionreason: rejectionreason,
    }).then((response) => {
      if (response.data == 'Already Approved') {
        setrequeststatus('Failed');
        setsubmitdraftstatus(
          'This request is already approved/rejected. Close this to refresh your page',
        );
        setSuccessData2(true);
      } else {
        setShow8(false);
        setrequeststatus('Rejected');
        setsubmitdraftstatus(`Request Rejected`);
        setSuccessData(true);
      }
    });
  };

  const handleShow7 = () => setShow7(true);

  const handleClose7 = () => {
    setShow7(false);
  };
  const [show7, setShow7] = useState(false);

  const handleShow8 = () => setShow8(true);

  const handleClose8 = () => {
    setShow8(false);
  };
  const [show8, setShow8] = useState(false);

  const handleShow9 = () => setShow9(true);

  const handleClose9 = () => {
    setShow9(false);
  };
  const [show9, setShow9] = useState(false);

  const columns2 = [
    {
      dataField: 'dbaName',
      text: 'DBA Name',
      formatter: linkFormatter,
      headerStyle: {
        backgroundColor: '#000',
        color: '#e3af32',
        fontWeight: '400',
      },
      style: { fontSize: '14px' },
      sort: true,
    },
    {
      dataField: 'requestNo',
      text: 'Request No',
      hidden: true,
      headerStyle: {
        backgroundColor: '#000',
        color: '#e3af32',
        fontWeight: '400',
      },
      style: { fontSize: '14px' },
      sort: true,
    },
    {
      dataField: 'requestDate',
      text: 'Submitted On',
      headerStyle: {
        backgroundColor: '#000',
        color: '#e3af32',
        fontWeight: '400',
      },
      style: { fontSize: '14px' },
      sort: true,
    },
    {
      dataField: 'finalName',
      text: 'Sales Rep',
      hidden: columnhide,
      headerStyle: {
        backgroundColor: '#000',
        color: '#e3af32',
        fontWeight: '400',
      },
      hidden: columnhide,
      style: { fontSize: '14px' },
      sort: true,
    },
    {
      dataField: 'requestorEmailID',
      text: 'Email',
      headerStyle: {
        backgroundColor: '#000',
        color: '#e3af32',
        fontWeight: '400',
      },
      hidden: true,
      style: { fontSize: '14px' },
      sort: true,
    },
    {
      dataField: 'retailerID',
      text: 'Retailer ID',
      headerStyle: {
        backgroundColor: '#000',
        color: '#e3af32',
        fontWeight: '400',
      },
      style: { fontSize: '14px' },
      sort: true,
    },
    {
      dataField: 'status',
      text: 'Status',
      headerStyle: {
        backgroundColor: '#000',
        color: '#e3af32',
        fontWeight: '400',
      },
      style: { fontSize: '14px' },
      sort: true,
    },
    {
      dataField: 'jiraID',
      text: 'Jira ID',
      headerStyle: {
        backgroundColor: '#000',
        color: '#e3af32',
        fontWeight: '400',
      },
      style: { fontSize: '14px' },
      sort: true,
    },
    {
      dataField: 'rejectionReason',
      text: 'Rejection Reason',
      headerStyle: {
        backgroundColor: '#000',
        color: '#e3af32',
        fontWeight: '400',
      },
      style: { fontSize: '14px' },
      sort: true,
    },

    {
      dataField: 'order3basePkey',
      text: 'Approve/Reject',
      hidden: true,
      headerStyle: {
        backgroundColor: '#000',
        color: '#e3af32',
        fontWeight: '400',
      },
      style: { fontSize: '14px' },
      sort: true,
      editable: false,
      formatter: (cell, row) => {
        console.log('Table Role:', finalrole);
        if (row)
          if (finalrole == 'Sales_Manager' && row.status == 'Submitted') {
            return (
              <div style={{ alignContent: 'center' }}>
                <Button
                  className="text-center btn btn-success"
                  disabled={buttondisabled}
                  onClick={(e) => {
                    handleShow9();
                    setrequestno(row.requestNo);
                  }}
                >
                  Approve
                </Button>
                {'  '}
                <Button
                  className="btn btn-danger"
                  disabled={buttondisabled}
                  onClick={(e) => {
                    handleShow7();
                    setrequestno(row.requestNo);
                  }}
                >
                  Reject
                </Button>
              </div>
            );
          }
      },
    },
  ];

  const columns6 = [
    {
      dataField: 'dbaName',
      text: 'DBA Name',
      formatter: linkFormatter,
      headerStyle: {
        backgroundColor: '#000',
        color: '#e3af32',
        fontWeight: '400',
      },
      style: { fontSize: '14px' },
      sort: true,
    },
    {
      dataField: 'requestNo',
      text: 'Request No',
      hidden: true,
      headerStyle: {
        backgroundColor: '#000',
        color: '#e3af32',
        fontWeight: '400',
      },
      style: { fontSize: '14px' },
      sort: true,
    },
    {
      dataField: 'requestDate',
      text: 'Submitted On',
      headerStyle: {
        backgroundColor: '#000',
        color: '#e3af32',
        fontWeight: '400',
      },
      style: { fontSize: '14px' },
      sort: true,
    },
    {
      dataField: 'finalName',
      text: 'Sales Rep',
      hidden: columnhide,
      headerStyle: {
        backgroundColor: '#000',
        color: '#e3af32',
        fontWeight: '400',
      },
      hidden: columnhide,
      style: { fontSize: '14px' },
      sort: true,
    },
    {
      dataField: 'requestorEmailID',
      text: 'Email',
      headerStyle: {
        backgroundColor: '#000',
        color: '#e3af32',
        fontWeight: '400',
      },
      hidden: true,
      style: { fontSize: '14px' },
      sort: true,
    },
    {
      dataField: 'status',
      text: 'Status',
      headerStyle: {
        backgroundColor: '#000',
        color: '#e3af32',
        fontWeight: '400',
      },
      style: { fontSize: '14px' },
      sort: true,
    },

    {
      dataField: 'order3basePkey',
      text: 'Approve/Reject',
      hidden: true,
      headerStyle: {
        backgroundColor: '#000',
        color: '#e3af32',
        fontWeight: '400',
      },
      style: { fontSize: '14px' },
      sort: true,
      editable: false,
      formatter: (cell, row) => {
        console.log('Table Role:', finalrole);
        if (row)
          if (finalrole == 'Sales_Manager' && row.status == 'Submitted') {
            return (
              <div style={{ alignContent: 'center' }}>
                <Button
                  className="text-center btn btn-success"
                  disabled={buttondisabled}
                  onClick={(e) => {
                    handleShow9();
                    setrequestno(row.requestNo);
                  }}
                >
                  Approve
                </Button>
                {'  '}
                <Button
                  className="btn btn-danger"
                  disabled={buttondisabled}
                  onClick={(e) => {
                    handleShow7();
                    setrequestno(row.requestNo);
                  }}
                >
                  Reject
                </Button>
              </div>
            );
          }
      },
    },
  ];
  function linkFormatter(cell, row, rowIndex, extraData) {
    if (cell) {
      const url = '/request/num=' + row.requestNo;
      return <a href={url}>{row.dbaName}</a>;
    }
    return <span></span>;
  }
  var username, role, userId;

  useEffect(() => {
    Axios.get(`/fetchRole?email=${localStorage.getItem('username')}`).then((response) => {
      username = localStorage.getItem('username');
      role = localStorage.getItem('role');
      setfinalrole(response?.data?.recordset[0]?.roleName);
      if (response?.data?.recordset[0]?.roleName == 'Sales_Manager') {
        setcolumnhide(false);
      }
      Axios.post('/hometable/api', {
        email: localStorage.getItem('username'),
        role: response?.data?.recordset[0]?.roleName,
        warehouse: response?.data?.recordset[0]?.warehouse,
      }).then((response) => {
        console.log('hi');
        setPlayers(response?.data);
        setPlayerslabel(`All (${response.data.length})`);
      });

      Axios.post('/hometable/api/submitted', {
        email: localStorage.getItem('username'),
        role: response?.data?.recordset[0]?.roleName,
        warehouse: response?.data?.recordset[0]?.warehouse,
      }).then((response) => {
        console.log('hi');
        setPlayers2(response?.data);
        setPlayers2label(`Submitted (${response.data.length})`);
      });

      Axios.post('/hometable/api/approved', {
        email: localStorage.getItem('username'),
        role: response?.data?.recordset[0]?.roleName,
        warehouse: response?.data?.recordset[0]?.warehouse,
      }).then((response) => {
        console.log('hi');
        setPlayers3(response?.data);
        setPlayers3label(`Approved (${response.data.length})`);
      });

      Axios.post('/hometable/api/rejected', {
        email: localStorage.getItem('username'),
        role: response?.data?.recordset[0]?.roleName,
        warehouse: response?.data?.recordset[0]?.warehouse,
      }).then((response) => {
        console.log('hi');
        setPlayers4(response?.data);
        setPlayers4label(`Rejected (${response.data.length})`);
      });

      Axios.post('/hometable/api/draft', {
        email: localStorage.getItem('username'),
        role: response?.data?.recordset[0]?.roleName,
        warehouse: response?.data?.recordset[0]?.warehouse,
      }).then((response) => {
        console.log('hi');
        setPlayers5(response?.data);
        setPlayers5label(`Draft (${response.data.length})`);
      });

      Axios.post('/homedashboard/api', {
        email: localStorage.getItem('username'),
        role: response?.data?.recordset[0]?.roleName,
        warehouse: response?.data?.recordset[0]?.warehouse,
      }).then((response) => {
        console.log('hi');
        settotal(response?.data[0].count);
      });

      Axios.post('/homedashboard/api/newtoday', {
        email: localStorage.getItem('username'),
        role: response?.data?.recordset[0]?.roleName,
        warehouse: response?.data?.recordset[0]?.warehouse,
      }).then((response) => {
        console.log('hi');
        setnewtoday(response?.data[0].count);
      });

      Axios.post('/homedashboard/api/approved', {
        email: localStorage.getItem('username'),
        role: response?.data?.recordset[0]?.roleName,
        warehouse: response?.data?.recordset[0]?.warehouse,
      }).then((response) => {
        console.log('hi');
        setapproved(response?.data[0].count);
      });

      Axios.post('/homedashboard/api/rejected', {
        email: localStorage.getItem('username'),
        role: response?.data?.recordset[0]?.roleName,
        warehouse: response?.data?.recordset[0]?.warehouse,
      }).then((response) => {
        console.log('hi');
        setrejected(response?.data[0].count);
      });

      Axios.post('/homedashboard/api/draft', {
        email: localStorage.getItem('username'),
        role: response?.data?.recordset[0]?.roleName,
        warehouse: response?.data?.recordset[0]?.warehouse,
      }).then((response) => {
        console.log('hi');
        setdraft(response?.data[0].count);
      });
    });
  }, []);

  const options = {
    onSizePerPageChange: (sizePerPage, page) => {
      console.log('Size per page change!!!');
      console.log('Newest size per page:' + sizePerPage);
      console.log('Newest page:' + page);
    },
    onPageChange: (page, sizePerPage) => {
      console.log('Page change!!!');
      console.log('Newest size per page:' + sizePerPage);
      console.log('Newest page:' + page);
    },
  };

  if (
    localStorage.getItem('username')?.length == 0 ||
    localStorage.getItem('role') == 'Employee' ||
    (localStorage.getItem('role') != 'Sales_Representative' &&
      localStorage.getItem('role') != 'Sales_Manager' &&
      localStorage.getItem('role') != 'MDM_Team')
  )
    return (
      <>
        <div>
          You are not authorized to view this page. Please get access by contacting the CMD team.
        </div>
        <div>
          To gain access please mail to any one of the below DL your Firstname, Lastname, Employee
          ID, Email ID, Company, Warehouse & Role.
        </div>
        <div>CMD DLs: DLWODCMD@anheuser-busch.com or DL-GCCWODCMD@anheuser-busch.com</div>
      </>
    );

  return (
    <div className="Allcomplaints background">
      {/* {loading ? ( */}
      <div>
        <Container style={{ paddingLeft: '8vw', paddingRight: '8vw' }}>
          <div style={{ paddingTop: '2rem' }}>
            <Card>
              <Row>
                <Col style={{ float: 'left' }}>
                  <h1 className="page-title" style={{ color: '#e3af32' }}>
                    Welcome, {localStorage.getItem('firstName')} {localStorage.getItem('lastName')}
                  </h1>
                </Col>
                <Col style={{ float: 'right' }}>
                  <h1 className="page-title" style={{ color: '#e3af32' }}>
                    Role: {localStorage.getItem('role')}
                  </h1>
                </Col>
              </Row>
            </Card>
          </div>
          <div>
            <h3 className="complaints-title">Retailer Requests</h3>
          </div>
          <Modal show={show7} onHide={handleClose7} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>Reject Request</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label className="form-label">Rejection Reason</label>
                      <textarea
                        id="add-comment-follow-up"
                        value={rejectionreason}
                        disabled={
                          localStorage.getItem('role') == 'Sales_Representative' ||
                          localStorage.getItem('role') == 'MDM_Team'
                        }
                        onChange={(e) => setrejectionreason(e.target.value)}
                        className="form-control"
                        rows="3"
                        placeholder="Enter comment"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose7}>
                Cancel
              </Button>
              <Button variant="danger" onClick={handleReject}>
                Reject
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={show8} onHide={handleClose8} backdrop="static" keyboard={false}>
            <Modal.Header>
              <Modal.Title>Loading</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      Your Request is loading. Please wait{'  '}
                      <ReactBootstrap.Spinner className="text-center" animation="border" />
                    </div>
                  </div>
                </div>
              </form>
            </Modal.Body>
          </Modal>

          <Modal show={show9} onHide={handleClose9} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>Approve Request</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      Are you sure you want to approve this request? This action is irreversible.
                    </div>
                  </div>
                </div>
              </form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose9}>
                Cancel
              </Button>
              <Button variant="success" onClick={handleApprove}>
                Approve
              </Button>
            </Modal.Footer>
          </Modal>

          {/* <div className="row col-12 grid text-center" style={{alignItems:"center", paddingLeft:"6vw"}}>
              <div className="col-sm-4 col-lg-2 col-6 row-cards">
                <Col>
                  <Card className="card">
                    <Card.Body className="card-body p-1 text-center">
                      <Card.Title id="total-complaints" style={{ color: '#000' }} class="h1 m-0">
                        {total}
                      </Card.Title>
                      <Card.Text className="text-muted mb-4">Total Requests</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </div>
              {(localStorage.getItem("role") === "Sales_Manager" || localStorage.getItem("role") === "MDM_Team") && ( <>
                <div className="col-sm-4 col-lg-2 col-6 row-cards">
                  <Col>
                    <Card className="card">
                      <Card.Body className="card-body p-1 text-center">
                        <Card.Title id="total-complaints" style={{ color: '#000' }} class="h1 m-0">
                          {players2.length}
                        </Card.Title>
                        <Card.Text className="text-muted mb-4">Submitted</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </div>
              </>)}
              <>
                <div className="col-sm-4 col-lg-2 col-6 row-cards">
                  <Col>
                    <Card className="card">
                      <Card.Body className="card-body p-1 text-center">
                        <Card.Title id="total-complaints" style={{ color: '#000' }} class="h1 m-0">
                          {approved}
                        </Card.Title>
                        <Card.Text className="text-muted mb-4">Total Approved</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </div>
              </>{' '}
              <>
                <div className="col-sm-4 col-lg-2 col-6 row-cards">
                  <Col>
                    <Card className="card">
                      <Card.Body className="card-body p-1 text-center">
                        <Card.Title id="total-complaints" style={{ color: '#000' }} class="h1 m-0">
                          {rejected}
                        </Card.Title>
                        <Card.Text className="text-muted mb-4">Total Rejected</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </div>
              </>{' '}
              <>
                <div className="col-sm-4 col-lg-2 col-6 row-cards">
                  <Col>
                    <Card className="card">
                      <Card.Body className="card-body p-1 text-center">
                        <Card.Title id="total-complaints" style={{ color: '#000' }} class="h1 m-0">
                          {newtoday}
                        </Card.Title>
                        <Card.Text className="text-muted mb-4">New Today</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </div>
              </>
              {localStorage.getItem("role") === "Sales_Representative" && ( <>
                <div className="col-sm-4 col-lg-2 col-6 row-cards">
                  <Col>
                    <Card className="card">
                      <Card.Body className="card-body p-1 text-center">
                        <Card.Title id="total-complaints" style={{ color: '#000' }} class="h1 m-0">
                          {draft}
                        </Card.Title>
                        <Card.Text className="text-muted mb-4">Total Draft</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </div>
              </>)}
            </div>
                  <hr /> */}
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChangetab} aria-label="lab API tabs example">
                  <Tab label={playerslabel} value="1" />
                  <Tab label={players2label} value="2" />
                  <Tab label={players3label} value="3" />
                  <Tab label={players4label} value="4" />
                  <Tab label={players5label} value="5" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <div>
                  <ToolkitProvider
                    bootstrap4
                    keyField="name"
                    data={players}
                    columns={columns2}
                    pagination={paginationFactory(options)}
                    exportCSV={{ onlyExportFiltered: true, exportAll: false }}
                    search
                  >
                    {(props) => (
                      <div className="mar">
                        <h3 className="complaints-title">All Requests</h3>
                        <div>
                          <SearchBar {...props.searchProps} />
                          <ClearSearchButton
                            className="btn ml-1 btn-warning mb-2 text-white"
                            {...props.searchProps}
                          />
                          <ExportCSVButton
                            className="export-button noBorder"
                            {...props.csvProps}
                            style={{ textAlign: 'right' }}
                          >
                            Export to CSV
                          </ExportCSVButton>
                        </div>
                        <div className="table-up noBorder expanded-container">
                          <BootstrapTable
                            className="table-background-2 bootstrap-table-2"
                            {...props.baseProps}
                            pagination={paginationFactory()}
                            filter={filterFactory()}
                            striped
                            hover
                            condensed
                          />
                        </div>
                      </div>
                    )}
                  </ToolkitProvider>
                </div>
              </TabPanel>

              <TabPanel value="2">
                <div>
                  <ToolkitProvider
                    bootstrap4
                    keyField="name"
                    data={players2}
                    columns={columns6}
                    pagination={paginationFactory(options)}
                    exportCSV={{ onlyExportFiltered: true, exportAll: false }}
                    search
                  >
                    {(props) => (
                      <div className="mar">
                        <h3 className="complaints-title">Submitted</h3>
                        <div>
                          <SearchBar {...props.searchProps} />
                          <ClearSearchButton
                            className="btn ml-1 btn-warning mb-2 text-white"
                            {...props.searchProps}
                          />
                          <ExportCSVButton
                            className="export-button noBorder"
                            {...props.csvProps}
                            style={{ textAlign: 'right' }}
                          >
                            Export to CSV
                          </ExportCSVButton>
                        </div>
                        <div className="table-up noBorder expanded-container">
                          <BootstrapTable
                            className="table-background-2 bootstrap-table-2"
                            {...props.baseProps}
                            pagination={paginationFactory()}
                            filter={filterFactory()}
                            striped
                            hover
                            condensed
                          />
                        </div>
                      </div>
                    )}
                  </ToolkitProvider>
                </div>
              </TabPanel>

              <TabPanel value="3">
                <div>
                  <ToolkitProvider
                    bootstrap4
                    keyField="name"
                    data={players3}
                    columns={columns5}
                    pagination={paginationFactory(options)}
                    exportCSV={{ onlyExportFiltered: true, exportAll: false }}
                    search
                  >
                    {(props) => (
                      <div className="mar">
                        <h3 className="complaints-title">Approved</h3>
                        <div>
                          <SearchBar {...props.searchProps} />
                          <ClearSearchButton
                            className="btn ml-1 btn-warning mb-2 text-white"
                            {...props.searchProps}
                          />
                          <ExportCSVButton
                            className="export-button noBorder"
                            {...props.csvProps}
                            style={{ textAlign: 'right' }}
                          >
                            Export to CSV
                          </ExportCSVButton>
                        </div>
                        <div className="table-up noBorder expanded-container">
                          <BootstrapTable
                            className="table-background-2 bootstrap-table-2"
                            {...props.baseProps}
                            pagination={paginationFactory()}
                            filter={filterFactory()}
                            striped
                            hover
                            condensed
                          />
                        </div>
                      </div>
                    )}
                  </ToolkitProvider>
                </div>
              </TabPanel>
              <TabPanel value="4">
                <div>
                  <ToolkitProvider
                    bootstrap4
                    keyField="name"
                    data={players4}
                    columns={columns3}
                    pagination={paginationFactory(options)}
                    exportCSV={{ onlyExportFiltered: true, exportAll: false }}
                    search
                  >
                    {(props) => (
                      <div className="mar">
                        <h3 className="complaints-title">Rejected</h3>
                        <div>
                          <SearchBar {...props.searchProps} />
                          <ClearSearchButton
                            className="btn ml-1 btn-warning mb-2 text-white"
                            {...props.searchProps}
                          />
                          <ExportCSVButton
                            className="export-button noBorder"
                            {...props.csvProps}
                            style={{ textAlign: 'right' }}
                          >
                            Export to CSV
                          </ExportCSVButton>
                        </div>
                        <div className="table-up noBorder expanded-container">
                          <BootstrapTable
                            className="table-background-2 bootstrap-table-2"
                            {...props.baseProps}
                            pagination={paginationFactory()}
                            filter={filterFactory()}
                            striped
                            hover
                            condensed
                          />
                        </div>
                      </div>
                    )}
                  </ToolkitProvider>
                </div>
              </TabPanel>
              <TabPanel value="5">
                <div>
                  <ToolkitProvider
                    bootstrap4
                    keyField="name"
                    data={players5}
                    columns={columns6}
                    pagination={paginationFactory(options)}
                    exportCSV={{ onlyExportFiltered: true, exportAll: false }}
                    search
                  >
                    {(props) => (
                      <div className="mar">
                        <h3 className="complaints-title">Draft</h3>
                        <div>
                          <SearchBar {...props.searchProps} />
                          <ClearSearchButton
                            className="btn ml-1 btn-warning mb-2 text-white"
                            {...props.searchProps}
                          />
                          <ExportCSVButton
                            className="export-button noBorder"
                            {...props.csvProps}
                            style={{ textAlign: 'right' }}
                          >
                            Export to CSV
                          </ExportCSVButton>
                        </div>
                        <div className="table-up noBorder expanded-container">
                          <BootstrapTable
                            className="table-background-2 bootstrap-table-2"
                            {...props.baseProps}
                            pagination={paginationFactory()}
                            filter={filterFactory()}
                            striped
                            hover
                            condensed
                          />
                        </div>
                      </div>
                    )}
                  </ToolkitProvider>
                </div>
              </TabPanel>
            </TabContext>
          </Box>
          {successData && (
            <ConfirmAlert
              confirm={hideAlert}
              hideAlert={hideAlert}
              cancelButton={false}
              confirmBtnText="OK"
              type={successData === true ? 'success' : 'error'}
              title={successData === true ? `${requeststatus}` : 'Error'}
              body={
                successData === true
                  ? `${submitdraftstatus} - Request No: ${requestno}`
                  : `${successData}`
              }
            />
          )}
          {successData4 && (
            <ConfirmAlert
              confirm={hideAlert}
              hideAlert={hideAlert}
              cancelButton={false}
              confirmBtnText="OK"
              type={successData4 === true ? 'success' : 'error'}
              title={successData4 === true ? `${requeststatus}` : 'Error'}
              body={successData4 === true ? `${submitdraftstatus} ${retailerid}` : `${successData}`}
            />
          )}
          {successData2 == true && (
            <ConfirmAlert
              confirm={hideAlert}
              hideAlert={hideAlert}
              cancelButton={false}
              confirmBtnText="OK"
              type={'error'}
              title={'Error'}
              body={`${submitdraftstatus}`}
            />
          )}
          {successData3 && (
            <ConfirmAlert
              confirm={hideAlert2}
              hideAlert={hideAlert2}
              cancelButton={false}
              confirmBtnText="OK"
              type={successData3 === true ? 'success' : 'error'}
              title={successData3 === true ? `${requeststatus}` : 'Error'}
              body={
                successData3 === true ? `${submitdraftstatus} - ${requestno}` : `${successData3}`
              }
            />
          )}
        </Container>
        <div className="bottom-fixed"></div>
      </div>
      {/* ) : (
        <ReactBootstrap.Spinner animation="border" /> */}
      {/* )} */}
    </div>
  );
};

export default Allcomplaints;
