const prod = {
  urls: {
    // getEmployeeInfoURL:
    //   'http://localhost:5000/api/employeeinfo',
    // postCaseURL: 'http://localhost:5000/api/cases/submit',
    // getCasesURL: 'http://localhost:5000/api/cases/submitted',
    // getCaseDataURL: 'http://localhost:5000/api/cases/data',
    getEmployeeInfoURL:
      'https://acoe-cv19-track-api.azurewebsites.net/api/employeeinfo',
    postCaseURL:
      'https://acoe-cv19-track-api.azurewebsites.net/api/cases/submit',
    getCasesURL:
      'https://acoe-cv19-track-api.azurewebsites.net/api/cases/submitted',
    getCaseDataURL:
      'https://acoe-cv19-track-api.azurewebsites.net/api/cases/data',
    getStatesURL:
      'https://acoe-cv19-track-api.azurewebsites.net/api/states',
    getCitiesURL:
      'https://acoe-cv19-track-api.azurewebsites.net/api/cities',
    getMedicalRepsURL: 'https://acoe-cv19-track-api.azurewebsites.net/api/medicalreps'
  },
}

const test = {
  urls: {
    getEmployeeInfoURL:
      'https://acoe-cv19-track-api-dev.azurewebsites.net/api/employeeinfo',
    postCaseURL:
      'https://acoe-cv19-track-api-dev.azurewebsites.net/api/cases/submit',
    getCasesURL:
      'https://acoe-cv19-track-api-dev.azurewebsites.net/api/cases/submitted',
    getCaseDataURL:
      'https://acoe-cv19-track-api-dev.azurewebsites.net/api/cases/data',
    getStatesURL:
      'https://acoe-cv19-track-api-dev.azurewebsites.net/api/states',
    getCitiesURL:
      'https://acoe-cv19-track-api-dev.azurewebsites.net/api/cities',
    getVaccinationDependentsURL: 
    'https://acoe-cv19-track-api-dev.azurewebsites.net/api/vaccinations/dependents',
    getMedicalRepsURL: 
    'https://acoe-cv19-track-api-dev.azurewebsites.net/api/medicalreps'
  },
}

export const urls = process.env.REACT_APP_ENV == 'prod' ? prod.urls : test.urls
