import React, { useEffect, useState } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import userService from '../services/dataService';
import defaultProfilePhoto from '../assets/images/profile.jpg';
import { loginRequest, snowBackendRequest } from '../utils/authConfig';
import FeatherIcon from 'feather-icons-react';
import abiLogo from '../assets/images/abi_logo copy.png';
import appLogo from '../assets/images/Hive_Logo_Set/SVG/Logo without name/Hive_Logo_White.svg';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import { useHistory, Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import './cq-portal/Navbar.css';
import { Center } from '@mantine/core';
import { BiSupport } from 'react-icons/bi';



const NavBar = () => {
  let history = useHistory();
  const isAuthenticated = useIsAuthenticated();
  const [name, changeName] = useState('');
  const [email, changeEmail] = useState('');
  const [finalrole, setfinalrole] = useState('');
  const { accounts, instance } = useMsal();
  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: '/',
    });
    localStorage.removeItem('username');
    localStorage.removeItem('role');
    localStorage.removeItem('name');
    <Redirect to={{ pathname: '/' }} />;
  };
  const [isDropDownOpen, setisDropDownOpen] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const togglingDropDown = () => {
    setisDropDownOpen(!isDropDownOpen);
  };
  const [roleAssigned, setRoleAssigned] = useState();
  // useEffect(()=>{
  //   axios.get(`/fetchRole?email=${localStorage.getItem('username')}`)
  //   .then((response)=>{

  //     console.log("Use Effect :", response.data.recordset)
  //     if( response?.data?.recordset?.length>0)
  //    { setRoleAssigned(response?.data?.recordset[0]?.roleName);
  //    localStorage.setItem('role', response?.data?.recordset[0]?.roleName);
  //    setfinalrole(response?.data?.recordset[0]?.roleName);}
  //    else
  //    { setRoleAssigned('Employee');
  //      localStorage.setItem('role', 'Employee');}

  //      if( response?.data?.recordset?.length>0)
  //    {
  //    localStorage.setItem('firstName', response?.data?.recordset[0]?.firstName);
  //    localStorage.setItem('lastName', response?.data?.recordset[0]?.lastName);
  //    localStorage.setItem('employeeID', response?.data?.recordset[0]?.employeeID);
  //    localStorage.setItem('company', response?.data?.recordset[0]?.company);
  //    localStorage.setItem('warehouse', response?.data?.recordset[0]?.warehouse);}
  //    else
  //    {
  //    localStorage.setItem('firstName','');
  //    localStorage.setItem('lastName', '');
  //    localStorage.setItem('employeeID', '');
  //    localStorage.setItem('company', '');
  //    localStorage.setItem('warehouse', '');}
  //   })
  // },[])

  useEffect(() => {
    if (accounts.length !== 0) {
      changeName(accounts[0].name);
      changeEmail(accounts[0].username);
      localStorage.setItem('username', accounts[0].username);
      localStorage.setItem('name', accounts[0].name);
    } else {
      changeName('');
      changeEmail('');
    }
  }, [accounts]);

  useEffect(() => {
    if (accounts.length > 0) {
      console.log('starting below');

      instance

        .acquireTokenSilent({
          ...snowBackendRequest,

          account: accounts[0],
        })

        .then((response) => {
          localStorage.setItem('snow_api_access_token', response?.accessToken);
        })

        .catch((err) => {
          // toast(`Error occured while acquiring token: ${err}`);

          handleLogout();

          // navigate('/');
        });

      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((response) => {
          console.log(response);
          userService
            .getMSGraphPhoto(response.accessToken)
            .then((image) => {
              if (image.type === 'image/jpeg') setProfilePhoto(image);
            })
            .catch((err) => console.log(err));

          if (accounts.length !== 0) {
            changeName(accounts[0].name);
            changeEmail(accounts[0].username);
            localStorage.setItem('username', accounts[0].username);
            localStorage.setItem('name', accounts[0].name);
            axios.get(`/fetchRole?email=${accounts[0].username}`).then((response) => {
              console.log('Account :', response.data.recordset);

              if (response?.data?.recordset?.length > 0) {
                setRoleAssigned(response?.data?.recordset[0]?.roleName);
                localStorage.setItem('role', response?.data?.recordset[0]?.roleName);
                setfinalrole(response?.data?.recordset[0]?.roleName);
              } else {
                setRoleAssigned('Employee');
                localStorage.setItem('role', 'Employee');
                setfinalrole('Employee');
              }
              if (response?.data?.recordset?.length > 0) {
                localStorage.setItem('firstName', response?.data?.recordset[0]?.firstName);
                localStorage.setItem('lastName', response?.data?.recordset[0]?.lastName);
                localStorage.setItem('employeeID', response?.data?.recordset[0]?.employeeID);
                localStorage.setItem('company', response?.data?.recordset[0]?.company);
                localStorage.setItem('warehouse', response?.data?.recordset[0]?.warehouse);
                localStorage.setItem('userID', response?.data?.recordset[0]?.userID);
              } else {
                localStorage.setItem('firstName', '');
                localStorage.setItem('lastName', '');
                localStorage.setItem('employeeID', '');
                localStorage.setItem('company', '');
                localStorage.setItem('warehouse', '');
                localStorage.setItem('userID', '');
              }
            });
          } else {
            changeName('');
            changeEmail('');
          }
        })
        .catch((err) => {
          console.log(`Error occured while acquiring token: ${err}`);
        });
    }
  }, [accounts]);

  const [isHeaderOpen, setisHeaderOpen] = useState(false);

  const togglingHeader = () => {
    setisHeaderOpen(!isHeaderOpen);
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  return (
    <div className="top-nav">
      <div className="header py-4">
        <div className="container">
          <div className="d-flex" style={{ height: '1.5rem', display: 'flex', paddingLeft: '8vw'  }}>
            <a className="header-brand" href="/">
              <img
                src={appLogo}
                className="header-brand-img"
                style={{ marginRight: '1rem' }}
                alt="App Logo"
              />
              <img
                src={abiLogo}
                className="header-brand-img"
                alt="AB InBev Logo"
                style={{ borderLeft: '1px solid #c9c9c9', paddingLeft: '0.5rem', height: '1.5rem' }}
              />
            </a>
            <div className="d-flex order-lg-2 ml-auto text-left">
              <span className="golden-text" style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                {/* {process.env.REACT_APP_ENV == 'prod' ? null : (
                  <strong>{`BETA`}</strong>
                )} */}
              </span>
              <div className="dropdown" style={{ display: 'flex', paddingRight: '8vw' }}>
                  {isMobile && ( 
                  <div className="d-lg-none ml-2" style={{ marginTop: '-4px' }}>
                    <Link to="/contact-us" style={{ textDecoration: 'none', color: 'white' }}>
                      <BiSupport size={34} />
                    </Link>
                  </div>
                )}
                        <button
                    variant="contained"
                    className="ml-3 rounded-lg m-auto d-none d-lg-block"
                    style={{
                      background: "linear-gradient(90deg,#e3af32 0%,#f4e00f 100%)",
                      outline: "none",
                      border: "none",
                      cursor: 'pointer',
                      fontWeight: 'bold',
                      fontSize: '11.3px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '140px',
                      height: '30px',
                      margin: '0.001rem',
                      padding: '0', 
                      lineHeight: '30px',
                    }}
                  >
                    <Link
                      to={`/contact-us`}
                      style={{ textDecoration: "none", color: "black"   }}
                    >
                      Raise Support Ticket
                    </Link>
                  </button>
                  
                <a className="nav-link pr-0 leading-none" onClick={togglingDropDown}>
                  <img
                    className="avatar"
                    src={profilePhoto ? URL.createObjectURL(profilePhoto) : defaultProfilePhoto}
                    alt=""
                  />
                  <span className="ml-2 d-none d-lg-block">
                    <span className="golden-text">
                      <strong>{accounts.length > 0 ? accounts[0].name : ''}</strong>
                    </span>
                    <span>
                      &emsp;&emsp;&emsp;
                      <FeatherIcon icon="chevron-down" size={14} />
                    </span>
                    <small className="text-muted d-block mt-1">
                      {accounts.length > 0 ? accounts[0].username : ''}
                    </small>
                  </span>
                  {/* <img
                    className="avatar"
                    src={defaultProfilePhoto
                    }
                    alt=""
                  />
                  <span className="ml-2 d-none d-lg-block">
                    <span className="text-default">
                      <strong>
                        {`Abhay Kumar`}
                      </strong>
                    </span>
                    <span>
                      &emsp;&emsp;&emsp;
                      <FeatherIcon icon="chevron-down" size={14} />
                    </span>
                    <small className="text-muted d-block mt-1">
                      {`abhay.kumar@ab-inbev.com`}
                    </small>
                  </span> */}
                </a>
                {isDropDownOpen && (
                  <div
                    className="dropdown-menu dropdown-menu-right dropdown-menu-arrow show"
                    style={{ cursor: 'pointer', marginRight: '15vw' }}
                  >
                    
                    <a className="dropdown-item text-left" onClick={handleLogout}>
                      Sign out
                    </a>
                  </div>
                )}
                  
              </div>
            </div>
            <a
              className={`header-toggler d-lg-none ml-3 ml-lg-0 ${isHeaderOpen ? '' : 'collapsed'}`}
              onClick={togglingHeader}
            >
              <span className="header-toggler-icon"></span>
            </a>
          </div>
        </div>
      </div>
      <div
        className={`header collapse d-lg-flex p-0 ${isHeaderOpen ? 'show' : ''}`}
        id="headerMenuCollapse"
        style={{ background: 'linear-gradient(90deg,#e3af32 0%,#f4e00f 100%)' }}
      >
        <div style={{ display: 'flex', paddingLeft: '8vw', alignItems: 'center' }} className="container">
          <div className="row align-items-center">
            <div className="col-lg order-lg-first">
              <ul className="nav nav-tabs border-0 flex-column flex-lg-row">
                <li className="nav-item">
                    <a
                    className={`nav-link ${window.location.pathname == '/' ? ' active' : ''}`}
                    onClick={() => {
                      history.push('/');
                    }}
                  >
                    <FeatherIcon icon="home" size={14} />
                    &nbsp;{'Home'}
                  </a>
                </li>

                {(localStorage.getItem('role') === 'Sales_Representative' ||
                  localStorage.getItem('role') === 'Sales_Manager') && (
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        window.location.pathname == '/add-retailer' ? ' active' : ''
                      }`}
                      onClick={() => {
                        history.push('/add-retailer');
                      }}
                    >
                      <FeatherIcon icon="activity" size={14} />
                      &nbsp;{'Add Retailer'}
                    </a>
                  </li>
                )}
                {finalrole === 'MDM_Team' && (
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        window.location.pathname == '/register' ? ' active' : ''
                      }`}
                      onClick={() => {
                        history.push('/register');
                      }}
                    >
                      <FeatherIcon icon="check" size={14} />
                      &nbsp;{'Register'}
                    </a>
                  </li>
                )}

                {/* {['Medical', 'MedicalSupport'].includes(role) && (
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        window.location.pathname == '/certificates'
                          ? ' active'
                          : ''
                      }`}
                      onClick={() => {
                        history.push('/certificates')
                      }}
                    >
                      <FeatherIcon icon="file-text" size={14} />
                      &nbsp;{'Certificates'}
                    </a>
                  </li>
                )} */}

                {/* {role != '' && (
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        window.location.pathname.includes('/case')
                          ? ' active'
                          : ''
                      }`}
                      onClick={() => {
                        setFormPage('manager')
                        history.push('/case')
                      }}
                    >
                      <FeatherIcon icon="file-plus" size={14} />
                      &nbsp;{'Add/Update Case'}
                    </a>
                  </li>
                )} */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
