import React, { useEffect } from 'react';
import useState from 'react-usestateref';
import NavbarComponent from '../Navbar';
import Modal from 'react-bootstrap/Modal';
import { Card, FormGroup } from 'react-bootstrap';
import { Col, Row } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import * as ReactBootstrap from 'react-bootstrap';
import './Navbar.css';
import './CQ.module.css';
import FileDownload from 'js-file-download';
import AsyncCreatableSelect from 'react-select/async-creatable';
import makeAnimated from 'react-select/animated';
import { Form, FormControl, Button, Image, FormCheck, textarea } from 'react-bootstrap';
import StepButton from '@mui/material/StepButton';
import FloatingLabel from 'react-bootstrap-floating-label';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { getDroppedOrSelectedFiles } from 'html5-file-selector';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import { useParams } from 'react-router-dom';
import ConfirmAlert from './common/ConfirmAlert';
import useUnsavedChangesWarning from './useUnsavedChangesWarning';
import Axios from 'axios';
import Dropzone from 'react-dropzone-uploader';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import '../../assets/styles/dropzone.css';
import { SettingsOverscanOutlined, Watch } from '@material-ui/icons';
import ReactTooltip from 'react-tooltip';
import { FaInfoCircle } from 'react-icons/fa';
import { Checkmark } from 'react-checkmark';
import { ToastContainer, toast } from 'react-toastify';
import SweetAlert from 'react-bootstrap-sweetalert';
import Paper from '@mui/material/Paper';
import 'react-toastify/dist/ReactToastify.css';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import * as moment from 'moment';
import TextField from '@mui/material/TextField';
import TimeRange from 'react-time-range';
import useGeoLocation from './useGeoLocation';
import { GiDna2 } from 'react-icons/gi';
import { set } from 'date-fns';
import { useHistory } from 'react-router-dom';
const { ExportCSVButton } = CSVExport;

const steps = [
  'Requestor Detail',
  'Name & Address',
  'Licensing',
  'Market & Patron Demographics',
  'Chain & Sales detail, Open/Close Status',
  'Pricing',
  'Sales & Delivery Routing',
  'Service Profile',
  'Miscellaneous',
  'Territories',
];

function FinalForm() {
  let history = useHistory();
  const theme = useTheme();
  const animatedComponents = makeAnimated();
  const maxSteps = steps.length;
  const reqno = useParams();

  var size = 0;
  const [filecount, setfilecount] = useState(0);
  const [isdisabled, setisdisabled, refisdisabled] = useState(true);

  const [activeStep, setActiveStep] = React.useState(0);
  const [newvalue, setoldnewValue] = React.useState('');
  const [skipped, setSkipped] = React.useState(new Set());
  const [sessionid, setsessionid, refsessionid] = useState([]);
  const location = useGeoLocation();
  const [latitude, setlatitude] = useState('');
  const [longitude, setlongitude] = useState('');
  const [licensedisabled, setlicensedisabled] = useState(false);
  const [chaindisabled, setchaindisabled] = useState(false);
  const [warehousecode, setwarehousecode] = useState('');
  const [completed, setCompleted] = React.useState({ 0: true });
  const [isloading, setisloading] = useState(false);
  const [botharesame, setbotharesame] = useState(false);
  const [buttondisabled, setbuttondisabled] = useState(false);
  const [datelimit, setdatelimit] = useState();
  const [alldataadded, setalldataadded] = useState();
  const [searchparam, setsearchparam] = useState('');
  const [dbaduplicate, setdbaduplicate] = useState(false);
  const [reqnocheckval, setreqnocheckval] = useState();
  const [successData, setSuccessData] = useState();
  const [submitdraftstatus, setsubmitdraftstatus] = useState(false);
  const [requeststatus, setrequeststatus] = useState();
  const [licenseexpdisabled, setlicenseexpdisabled] = useState();
  const [datepickerdisabled, setdatepickerdisabled] = useState();
  const [successData2, setSuccessData2] = useState();
  const [successData3, setSuccessData3] = useState();
  const [successData4, setSuccessData4] = useState();
  const [territoriesselectall, setterritoriesselectall] = useState(false);
  const [stepperclassname, setstepperclassname] = useState('col-12');
  const [dbaduplicatemarker, setdbaduplicatemarker] = useState(false);
  const [dynamicfilesizechecker, setdynamicfilesizechecker] = useState();
  const [attachments, setattachments, refattachments] = useState([]);

  //State values
  const [requestno, setrequestno] = useState('');
  const [status, setstatus] = useState('');
  const [reqid, setreqid] = useState(null);
  const [reqphoneno, setreqphoneno] = useState('');
  const [retailerid, setretailerid] = useState('');
  const [actualcity, setactualcity, refactualcity] = useState('');
  const [firstname, setfirstname] = useState('');
  const [lastname, setlastname] = useState('');
  const [emailid, setemailid] = useState('');
  const [phoneno, setphoneno] = useState(null);
  const [empid, setempid] = useState(null);
  const [dbaname, setdbaname] = useState('');
  const [taxid, settaxid] = useState('');
  const [retailerphoneno, setretailerphoneno] = useState(null);
  const [licenseename, setlicenseename] = useState('');
  const [company, setcompany, refcompany] = useState('');
  const [warehouse, setwarehouse, refwarehouse] = useState('');
  const [street, setstreet] = useState('');
  const [city, setcity] = useState('');
  const [state, setstate] = useState('');
  const [zipcode, setzipcode] = useState(null);
  const [county, setcounty] = useState('');
  const [faxno, setfaxno] = useState(null);
  const [buyer, setbuyer] = useState('');
  const [licensestatus, setlicensestatus] = useState('');
  const [licensenumber, setlicensenumber] = useState('');
  const [licenseexpdat, setlicenseexpdat] = useState(null);
  const [ponumreq, setponumreq] = useState(false);
  const [chain, setchain] = useState('');
  const [chainstorenum, setchainstorenum] = useState(null);
  const [chainindependentstatus, setchainindependentstatus] = useState('');
  const [businessopendate, setbusinessopendate] = useState(null);
  const [seasonalopendate, setseasonalopendate] = useState(null);
  const [seasonalclosedate, setseasonalclosedate] = useState(null);
  const [retailerstatus, setretailerstatus] = useState('');
  const [sellingmethod, setsellingmethod] = useState('');
  const [packagetype, setpackagetype] = useState('');
  const [draftstatus, setdraftstatus] = useState('');
  const [performancepaystatus, setperformancepaystatus] = useState('');
  const [displaystatus, setdisplaystatus] = useState('');
  const [allowablepos, setallowablepos] = useState('');
  const [draftconsumptiontype, setdraftconsumptiontype] = useState('');
  const [retailerclass, setretailerclass] = useState('');
  const [civilianmilitary, setcivilianmilitary] = useState('');
  const [industryvolume, setindustryvolume] = useState('');
  const [patronethnicity, setpatronethnicity] = useState('');
  const [patronage, setpatronage] = useState('');
  const [patronoccupation, setpatronoccupation] = useState('');
  const [patronresidence, setpatronresidence] = useState('');
  const [contemporaryadulttype, setcontemporaryadulttype] = useState('');
  const [ownerethnicityorlanguage, setownerethnicityorlanguage] = useState('');
  const [markettype, setmarkettype] = useState('');
  const [onoffpremisetype, setonoffpremisetype] = useState('');
  const [packageconsumptiontype, setpackageconsumptiontype, refpackageconsumptiontype] =
    useState('');
  const [flpgroup, setflpgroup] = useState('');
  const [pricinggroup, setpricinggroup] = useState('');
  const [pricingsubgroup, setpricingsubgroup] = useState('');
  const [deliveryscansheetrequired, setdeliveryscansheetrequired] = useState('');
  const [callday, setcallday] = useState(['']);
  const [salesroutetype, setsalesroutetype] = useState('');
  const [salesrep, setsalesrep] = useState('');
  const [salesroutesequence, setsalesroutesequence] = useState('');
  const [salesroutefrequency, setsalesroutefrequency] = useState('');
  const [supervisor, setsupervisor] = useState('');
  const [saleseffectivedate, setsaleseffectivedate] = useState(null);
  const [deliveryday, setdeliveryday] = useState('');
  const [deliveryroutetype, setdeliveryroutetype] = useState('');
  const [deliveryroute, setdeliveryroute] = useState('');
  const [deliveryroutesequence, setdeliveryroutesequence] = useState('');
  const [deliveryroutefrequency, setdeliveryroutefrequency] = useState('');
  const [deliveryeffectivedate, setdeliveryeffectivedate] = useState(null);
  const [openingtime, setopeningtime] = useState();
  const [closingtime, setclosingtime] = useState();
  const [deliverywindowstart, setdeliverywindowstart] = useState();
  const [deliverywindowend, setdeliverywindowend] = useState();
  const [deliverywindow2start, setdeliverywindow2start] = useState();
  const [deliverywindow2end, setdeliverywindow2end] = useState();
  const [additionalserviceinstruction, setadditionalserviceinstruction] = useState('');
  const [
    whichwarehousewillthisretailerbeservicedoutof,
    setwhichwarehousewillthisretailerbeservicedoutof,
  ] = useState('');
  const [planogrammablestatus, setplanogrammablestatus] = useState('');
  const [arbillingcycle, setarbillingcycle] = useState('');
  const [territories, setterritories] = useState(['']);
  const [rejectionreason, setrejectionreason] = useState('');

  //Dropdown Reac State
  const [companylist, setcompanylist] = useState(['']);
  const [streetlist, setstreetlist] = useState(['']);
  const [warehouselist, setwarehouselist] = useState(['']);
  const [citylist, setcitylist] = useState(['']);
  const [countylist, setcountylist] = useState(['']);
  const [retailerstatuslist, setretailerstatuslist] = useState(['']);
  const [licensestatuslist, setlicensestatuslist] = useState(['']);
  const [chainlist, setchainlist] = useState(['']);
  const [chainflag, setchainflag] = useState(['']);
  const [markettypelist, setmarkettypelist] = useState(['']);
  const [premiselist, setpremiselist] = useState(['']);
  const [packageconsumptiontypelist, setpackageconsumptiontypelist] = useState(['']);
  const [draftconsumptiontypelist, setdraftconsumptiontypelist, refdraftconsumptiontype] = useState(
    [''],
  );
  const [retailerclasslist, setretailerclasslist] = useState(['']);
  const [civilianmilitarylist, setcivilianmilitarylist] = useState(['']);
  const [industryvolumelist, setindustryvolumelist] = useState(['']);
  const [patronethnicitylist, setpatronethnicitylist] = useState(['']);
  const [patronagelist, setpatronagelist] = useState(['']);
  const [patronresidencelist, setpatronresidencelist] = useState(['']);
  const [patronoccupationlist, setpatronoccupationlist] = useState(['']);
  const [contemporaryadulttypelist, setcontemporaryadulttypelist] = useState(['']);
  const [draftstatuslist, setdraftstatuslist] = useState(['']);
  const [ownerethnicityorlanguagelist, setownerethnicityorlanguagelist] = useState(['']);
  const [sellingmethodlist, setsellingmethodlist] = useState(['']);
  const [sellablepackagetypelist, setsellablepackagetypelist] = useState(['']);
  const [performancepaystatuslist, setperformancepaystatuslist] = useState(['']);
  const [displaystatuslist, setdisplaystatuslist] = useState(['']);
  const [allowableposlist, setallowableposlist] = useState(['']);
  const [flpgrouplist, setflpgrouplist] = useState(['']);
  const [pricinggrouplist, setpricinggrouplist] = useState(['']);
  const [deliveryscansheetrequiredlist, setdeliveryscansheetrequiredlist] = useState(['']);
  const [planogrammablelist, setplanogrammablelist] = useState(['']);
  const [arcyclelist, setarcyclelist] = useState(['']);
  const [routetypelist, setroutetypelist] = useState(['']);
  const [salesreplist, setsalesreplist] = useState(['']);
  const [routefrequencylist, setroutefrequencylist] = useState(['']);
  const [supervisorlist, setsupervisorlist] = useState(['']);
  const [territorieslist, setterritorieslist, refterritorieslist] = useState(['']);
  const [deliveryroutelist, setdeliveryroutelist] = useState(['']);
  const [companycode, setcompanycode] = useState('');

  const [timeOptions, setTimeOptions] = useState([
    { label: '00:00', value: '00:00' },
    { label: '00:15', value: '00:15' },
    { label: '00:30', value: '00:30' },
    { label: '00:45', value: '00:45' },
    { label: '01:00', value: '01:00' },
    { label: '01:15', value: '01:15' },
    { label: '01:30', value: '01:30' },
    { label: '01:45', value: '01:45' },
    { label: '02:00', value: '02:00' },
    { label: '02:15', value: '02:15' },
    { label: '02:30', value: '02:30' },
    { label: '02:45', value: '02:45' },
    { label: '03:00', value: '03:00' },
    { label: '03:15', value: '03:15' },
    { label: '03:30', value: '03:30' },
    { label: '03:45', value: '03:45' },
    { label: '04:00', value: '04:00' },
    { label: '04:15', value: '04:15' },
    { label: '04:30', value: '04:30' },
    { label: '04:45', value: '04:45' },
    { label: '05:00', value: '05:00' },
    { label: '05:15', value: '05:15' },
    { label: '05:30', value: '05:30' },
    { label: '05:45', value: '05:45' },
    { label: '06:00', value: '06:00' },
    { label: '06:15', value: '06:15' },
    { label: '06:30', value: '06:30' },
    { label: '06:45', value: '06:45' },
    { label: '07:00', value: '07:00' },
    { label: '07:15', value: '07:15' },
    { label: '07:30', value: '07:30' },
    { label: '07:45', value: '07:45' },
    { label: '08:00', value: '08:00' },
    { label: '08:15', value: '08:15' },
    { label: '08:30', value: '08:30' },
    { label: '08:45', value: '08:45' },
    { label: '09:00', value: '09:00' },
    { label: '09:15', value: '09:15' },
    { label: '09:30', value: '09:30' },
    { label: '09:45', value: '09:45' },
    { label: '10:00', value: '10:00' },
    { label: '10:15', value: '10:15' },
    { label: '10:30', value: '10:30' },
    { label: '10:45', value: '10:45' },
    { label: '11:00', value: '11:00' },
    { label: '11:15', value: '11:15' },
    { label: '11:30', value: '11:30' },
    { label: '11:45', value: '11:45' },
    { label: '12:00', value: '12:00' },
    { label: '12:15', value: '12:15' },
    { label: '12:30', value: '12:30' },
    { label: '12:45', value: '12:45' },
    { label: '13:00', value: '13:00' },
    { label: '13:15', value: '13:15' },
    { label: '13:30', value: '13:30' },
    { label: '13:45', value: '13:45' },
    { label: '14:00', value: '14:00' },
    { label: '14:15', value: '14:15' },
    { label: '14:30', value: '14:30' },
    { label: '14:45', value: '14:45' },
    { label: '15:00', value: '15:00' },
    { label: '15:15', value: '15:15' },
    { label: '15:30', value: '15:30' },
    { label: '15:45', value: '15:45' },
    { label: '16:00', value: '16:00' },
    { label: '16:15', value: '16:15' },
    { label: '16:30', value: '16:30' },
    { label: '16:45', value: '16:45' },
    { label: '17:00', value: '17:00' },
    { label: '17:15', value: '17:15' },
    { label: '17:30', value: '17:30' },
    { label: '17:45', value: '17:45' },
    { label: '18:00', value: '18:00' },
    { label: '18:15', value: '18:15' },
    { label: '18:30', value: '18:30' },
    { label: '18:45', value: '18:45' },
    { label: '19:00', value: '19:00' },
    { label: '19:15', value: '19:15' },
    { label: '19:30', value: '19:30' },
    { label: '19:45', value: '19:45' },
    { label: '20:00', value: '20:00' },
    { label: '20:15', value: '20:15' },
    { label: '20:30', value: '20:30' },
    { label: '20:45', value: '20:45' },
    { label: '21:00', value: '21:00' },
    { label: '21:15', value: '21:15' },
    { label: '21:30', value: '21:30' },
    { label: '21:45', value: '21:45' },
    { label: '22:00', value: '22:00' },
    { label: '22:15', value: '22:15' },
    { label: '22:30', value: '22:30' },
    { label: '22:45', value: '22:45' },
    { label: '23:00', value: '23:00' },
    { label: '23:15', value: '23:15' },
    { label: '23:30', value: '23:30' },
    { label: '23:45', value: '23:45' },
  ]);

  const apikey = 'g68$mtj*VUljCGDSd%C6kwTLXPy87';
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (fileslist?.length > 0) {
      setacceptedfiles(fileslist);
    }
    handleValidateStepper();
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    if (fileslist?.length > 0) {
      setacceptedfiles(fileslist);
    }
    handleValidateStepper();
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const [isMobile, setIsMobile] = useState('horizontal');
  const [filesfinal, setfilesfinal, reffilesfinal] = useState([]);
  const [del, setdel, refdel] = useState(0);
  const [fileslist, setfileslist, reffileslist] = useState([]);
  const [acceptedfiles, setacceptedfiles, refacceptedfiles] = useState([]);

  const handleUnload = (event) => {
    if (refdel.current == 0) {
      Axios.post('/storefileinfo/removefilesfromdb', {
        session: refsessionid.current,
        filenames: reffilesfinal.current,
      }).then((response) => {
        var message = response;
        console.log(message);
      });
    }
    const uploadurl2 = '/delete/api' + '/' + refsessionid.current;
    Axios.post(uploadurl2)
      .then(function (response) {
        var folder = response;
        console.log(folder);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
    Axios.get('/session/api/id')
      .then(function (response) {
        // handle success
        var ses = response?.data;
        setsessionid(ses);
        console.log(ses);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  React.useEffect(() => {
    window.addEventListener('unload', handleUnload);

    // cleanup this component
    return () => {
      window.removeEventListener('unload', handleUnload);
    };
  }, []);

  useEffect(() => {
    console.log('Getting session id');
    Axios.get('/session/api/id')
      .then(function (response) {
        // handle success
        var ses = response?.data;
        setsessionid(ses);
        console.log(ses);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }, []);

  useEffect(() => {
    Axios.post('/activitylogs/api', {
      requestno: reqno.reqno,
      activityname: 'Request Page Visited',
      activitydescription: 'The request page is visited by the user',
      pagename: 'Request Page',
      emailid: localStorage.getItem('username'),
      empid: localStorage.getItem('employeeID'),
    }).then((response) => {
      console.log(response.data);
    });
  }, []);

  //choose the screen size
  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 1220) {
      setstepperclassname('col-9');
      console.log('Class Name:', stepperclassname);
      setIsMobile('vertical');
      console.log('vertical');
    } else {
      setstepperclassname('col-12');
      console.log('Class Name:', stepperclassname);
      setIsMobile('horizontal');
      console.log('horizontal');
    }
  };

  const handleChangeTerritoriesMapping = (licensestatusfinal) => {
    const apikey = 'g68$mtj*VUljCGDSd%C6kwTLXPy87';
    setterritories(['']);
    Axios.get(
      `https://acoeretailmdappprod1.azurewebsites.net/warehousecode/api?apikey=${apikey}&warehouse=${warehouse}&company=${company}`,
    )
      .then(function (response) {
        Axios.post(
          `https://acoeretailmdappprod1.azurewebsites.net/dropdown/territoriesmappinglist?apikey=${apikey}&actualcity=${response.data[0].Location}`,
          { licensestatus: licensestatusfinal },
        )
          .then(function (response) {
            handleValidateStepper();
            console.log('Territories Mapping', response.data[0]);

            if (response?.data?.length > 0) {
              var templist = territorieslist;

              for (var j in response.data[0]) {
                for (var i = 0; i < templist.length; i++) {
                  if (templist[i].label == j) {
                    if (response.data[0][`${j}`] == 'Y') {
                      templist[i]['isChecked'] = true;
                      setterritories((territories) => [...territories, templist[i].value]);
                    } else if (response.data[0][`${j}`] == 'N') {
                      templist[i]['isChecked'] = false;
                    }
                  }
                }
              }

              console.log('Territories list:', templist);
              setterritorieslist(templist);
            } else {
              var templist = territorieslist;

              for (var i = 0; i < templist.length; i++) {
                templist[i].isChecked = false;
              }

              console.log('Territories list:', templist);
              setterritorieslist(templist);
            }
            // if(response?.data?.length <= 21){
            //   setnoofcolumns(4)
            // }
            // else if(response?.data?.length <= 48){
            //   setnoofcolumns(3)
            // }
            // else{
            //   setnoofcolumns(2)
            // }
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // useEffect(() => {
  //   console.log("Coordinate check",location.coordinates.lat)
  //   if(location.coordinates.lat){
  //   setlatitude(location.coordinates.lat)
  //   setlongitude(location.coordinates.lng)
  //   // Axios.get(`https://atlas.microsoft.com/search/address/reverse/json?api-version=1.0&subscription-key=4N794oTImZXDvQ4OPz58pziQiMH5wZNBL2pIFCt_XRo&language=en-US&query=40.64498,-74.01076&number=1`).then(function (response) {
  //   //   // handle success
  //   //   console.log("Location:",response?.data);
  //   //   setstreet(response?.data?.addresses[0]?.address.streetNameAndNumber);
  //   //   setcity(response?.data?.addresses[0]?.address.municipalitySubdivision);
  //   //   setstate(response?.data?.addresses[0]?.address.countrySubdivision);
  //   //   setzipcode(response?.data?.addresses[0]?.address.postalCode);
  //   //   })
  //   //   .catch(function (error) {
  //   //   // handle error
  //   //   console.log(error);
  //   //   })
  //   Axios.get(`https://atlas.microsoft.com/search/address/reverse/json?api-version=1.0&subscription-key=4N794oTImZXDvQ4OPz58pziQiMH5wZNBL2pIFCt_XRo&language=en-US&query=${location.coordinates.lat},${location.coordinates.lat}&number=1`).then(function (response) {
  //     // handle success
  //     console.log("Location:",response?.data);
  //     setstreet(response?.data?.addresses[0]?.address.streetNameAndNumber);
  //     setcity(response?.data?.addresses[0]?.address.municipalitySubdivision);
  //     setstate(response?.data?.addresses[0]?.address.countrySubdivision);
  //     setzipcode(response?.data?.addresses[0]?.address.postalCode);
  //     })
  //     .catch(function (error) {
  //     // handle error
  //     console.log(error);
  //     })
  //   }
  // }, [location]);

  const handleDbaDuplicate = (dbafinal) => {
    Axios.post('/dbanamecheck/api', { dba: dbafinal, reqno: requestno }).then((response) => {
      if (response.data == true) {
        // setdbaduplicate(true);
        setdbaduplicatemarker(true);
      } else {
        // setdbaduplicate(false);
        setdbaduplicatemarker(false);
      }
    });
  };

  const getUploadParams = ({ meta, file }) => {
    console.log(meta);
    const body = new FormData();
    //body.append('sessionid', sessionid);
    body.append('customFile', file);
    const uploadurl = '/files/api/files-1' + '/' + sessionid;
    console.log(uploadurl);
    return { url: uploadurl, body };
  };

  useEffect(() => {
    console.log('getting file upload size limit');

    Axios.post(`/dynamicfilesizechecker/getfilesizes`, {
      requestno: reqno,
    })
      .then(function (response) {
        // handle success
        // var ses = response?.data;

        console.log(response?.data, 'file limit output');

        setdynamicfilesizechecker(parseInt(response?.data));
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }, []);

  const handleChangeStatus = ({ meta, file }, status) => {
    // setDirty();
    console.log(status, meta, file);
    if (status == 'rejected_file_type') {
      alert(
        'UnSupported file type. Either the extension is not supported / your file size is > than 50 MB',
      );
    }
    if (status == 'error_upload') {
      alert('File upload failed, files being uploaded may be open in your local PC');
    }
    if (status == 'done') {
      if (!filesfinal.includes(meta.name)) {
        {
          //logging file info and name to other table
          console.log('starting file information logging to backend', file);
          console.log('file meta info print', meta);
          Axios.post('/storefileinfo/storedetailsfromcustomerstatus', {
            meta: meta,
            file: file,
            reqno: { reqno },
            session: sessionid,
          });
        }
        setfilesfinal((filesfinal) => [...filesfinal, meta.name]);
        setfileslist((fileslist) => [...fileslist, file]);
        size += file.size;
        if (size > 200000000 - dynamicfilesizechecker) {
          setisdisabled(true);
        } else if (size < 200000000 - dynamicfilesizechecker) {
          setisdisabled(false);
        }
        console.log('The total files size after upload is:');
        console.log(size);
      }

      Axios.get(`/count/api/filecount/${sessionid}`).then(function (response) {
        console.log('Hello files');
        setfilecount(response?.data);
      });
    }
    if (status == 'removed') {
      var filename = file.name;
      setfilecount(filecount - 1);
      {
        Axios.post('/storefileinfo/removefilefromdb', {
          meta: meta,
          file: file,
          session: sessionid,
        }).then((response) => {
          //logging info to other table of the file name and type
          var message = response;
          var tempfileslist = fileslist;
          tempfileslist.pop(file);
          setfileslist(tempfileslist);

          var tempfilesfinal = filesfinal;
          tempfilesfinal.pop(meta.name);
          setfilesfinal(tempfilesfinal);
          console.log(message);
        });
      }
      Axios.post(`/remove/api/${sessionid}`, { fname: filename })
        .then((response) => {
          var message = response;
          console.log(`Removed file message:`);
          console.log(message);
        })
        .then(
          Axios.get(`/folder/api/folder/${sessionid}`).then((response) => {
            size = response?.data.filesize;
            console.log('Afer removal total size is:');
            console.log(size);
          }),
        );
      if (size > 200000000 - dynamicfilesizechecker) {
        setisdisabled(true);
      } else if (size < 200000000 - dynamicfilesizechecker) {
        setisdisabled(false);
      }
      Axios.get(`/count/api/filecount/${sessionid}`).then(function (response) {
        console.log('Hello files');
        setfilecount(response?.data);
      });
      console.log(size);
    }
    if (status == 'error_validation') {
      alert('Maximum size is 200MB totally');
    }
  };

  // const handleChangeStatus = ({ meta, file }, status) => {
  //   // setDirty();
  //   console.log(status, meta, file);
  //   console.log("hello jatin")
  //   if(status ==  "rejected_file_type"){
  //     alert("UnSupported file type. Either the extension is not supported / your file size is > than 50 MB")
  //   }
  //   if(status == 'error_upload'){
  //     alert("File upload failed, files being uploaded may be open in your local PC")
  //   }
  //   if(status == 'done'){
  //     {
  //       console.log("starting file information logging to backend",file);
  //       console.log("file meta info print",meta)
  //       Axios.post('/storefileinfo/storedetails',{meta:meta,file:file,session:sessionid});
  //     }
  //     size += file.size;
  //     if(size>200000000){
  //         setisdisabled(true);
  //     }
  //     else if(size<200000000){
  //         setisdisabled(false)
  //     }
  //     console.log("The total files size after upload is:")
  //     console.log(size);
  //     console.log("This is the current number:")
  //     Axios.get(`/count/api/filecount/${sessionid}`).then(function (response) {
  //         console.log("Hello files")
  //     console.log(response?.data)
  //     file_count = response?.data
  //     setfilecount(response?.data)
  //     if(file_count > 0 && refisvalid.current == true){
  //       setisdisabled(false)
  //     }
  //     else{
  //       setisdisabled(true)
  //     }
  // })
  // }
  //   if(status == 'removed'){
  //       var filename = file.name
  //       {Axios.post('/storefileinfo/removefilefromdb',{meta:meta,file:file,session:sessionid}).then(response => {
  //         var message = response ;
  //         console.log(message);
  //       }) ;}
  //       Axios.post(`/remove/api/${sessionid}`,{fname : filename}).then(response => {
  //          var message =response;
  //           console.log(`Removed file message:`)
  //           console.log(message);
  //       }).then(
  //       Axios.get(`/folder/api/folder/${sessionid}`).then(response => {
  //           size =response?.data.filesize;
  //           console.log(size);
  //       }))
  //       if(size>200000000){
  //           setisdisabled(true);
  //       }
  //       else if(size<200000000){
  //           setisdisabled(false)
  //       }
  //       console.log(size);
  //     }
  //   }

  const getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };
  const validatefile = ({ meta, file }) => {
    const valsize = 20000000;
    var totsize = size + file.size;
    if (totsize > valsize) {
      return 'File Upload Error: Maximum total files size = 200 MB';
    } else {
      return false;
    }
  };

  const handleChangeCompany = (value) => {
    setlicensestatus('');

    setcounty('');

    setchainindependentstatus('');

    setmarkettype('');

    setonoffpremisetype('');

    setretailerclass('');

    setcivilianmilitary('');

    setindustryvolume('');

    setpatronethnicity('');

    setpatronage('');

    setpatronoccupation('');

    setpatronresidence('');

    setcontemporaryadulttype('');

    setownerethnicityorlanguage('');

    setperformancepaystatus('');

    setdisplaystatus('');

    setflpgroup('');

    setpricinggroup('');

    setarbillingcycle('');

    setlicenseename('');

    setretailerstatus('');

    setpackageconsumptiontype('');

    setdraftconsumptiontype('');

    setsellingmethod('');

    setpackagetype('');

    setlicensenumber(null);

    setlicenseexpdat('');

    setchain('');

    setchainstorenum(null);

    setcallday('');

    setsalesroutetype('');

    setsalesrep('');

    setsalesroutefrequency('');

    setdeliveryroutetype('');

    setdeliveryroute('');

    setdeliveryroutefrequency('');

    setsaleseffectivedate(null);

    setdeliveryeffectivedate(null);

    setsupervisor('');

    setValue('licensestatusvalid', '');

    setValue('county', '');

    setValue('chainindependentstatusvalid', '');

    setValue('markettype', '');

    setValue('onoffpremisetype', '');

    setValue('retailerclass', '');

    setValue('civilianmilitary', '');

    setValue('industryvolume', '');

    setValue('patronethnicity', '');

    setValue('patronage', '');

    setValue('patronoccupation', '');

    setValue('patronresidence', '');

    setValue('contemporaryadulttype', '');

    setValue('ownerethnicityorlanguage', '');

    setValue('performancepaystatus', '');

    setValue('displaystatus', '');

    setValue('flpgroup', '');

    setValue('pricinggroup', '');

    setValue('arbillingcycle', '');

    setValue('licenseename', '');

    setValue('retailerstatus', '');

    setValue('packageconsumptiontype', '');

    setValue('draftconsumptiontypevalid', '');

    setValue('sellingmethod', '');

    setValue('packagetype', '');

    setValue('licensenumber', '');

    setValue('licenseexpdat', '');

    setValue('chain', '');

    setValue('chainstorenum', '');

    setValue('calldayvalid', '');

    setValue('salesroutetype', '');

    setValue('salesrep', '');

    setValue('salesroutefrequency', '');

    setValue('deliveryroutetype', '');

    setValue('deliveryroute', '');

    setValue('deliveryroutefrequency', '');

    setValue('saleseffectivedate', '');

    setValue('deliveryeffectivedate', '');

    setValue('supervisor', '');

    Axios.get(
      `https://acoeretailmdappprod1.azurewebsites.net/dropdown/registerwarehouselist?apikey=${apikey}&company=${value}`,
    )
      .then(function (response) {
        // handle success
        console.log(response?.data);
        setwarehouselist(response?.data);
        console.log('This is complaint type');
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };
  const handleChangeWarehouse = (changedwarehouse) => {
    // setlicensestatus("");

    // setcounty("");

    // setchainindependentstatus("");

    // setmarkettype("");

    // setonoffpremisetype("");

    // setretailerclass("");

    // setcivilianmilitary("");

    // setindustryvolume("");

    // setpatronethnicity("");

    // setpatronage("");

    // setpatronoccupation("");

    // setpatronresidence("");

    // setcontemporaryadulttype("");

    // setownerethnicityorlanguage("");

    // setperformancepaystatus("");

    // setdisplaystatus("");

    // setflpgroup("");

    // setpricinggroup("");

    // setarbillingcycle("");

    // setlicenseename("");

    // setretailerstatus("");

    // setpackageconsumptiontype("");

    // setdraftconsumptiontype("");

    // setsellingmethod("");

    // setpackagetype("");

    // setlicensenumber(null);

    // setlicenseexpdat("");

    // setchain("");

    // setchainstorenum(null);

    // setcallday("");

    // setsalesroutetype("");

    setsalesrep('');

    // setsalesroutefrequency("");

    // setdeliveryroutetype("");

    // setdeliveryroute("");

    // setdeliveryroutefrequency("");

    // setsaleseffectivedate("");

    // setdeliveryeffectivedate("");

    setsupervisor('');

    // register("licensestatusvalid", { value: "" });

    // register("county", { value: "" });

    // register("chainindependentstatusvalid", { value: "" });

    // register("markettype", { value: "" });

    // register("onoffpremisetype", { value: "" });

    // register("retailerclass", { value: "" });

    // register("civilianmilitary", { value: "" });

    // register("industryvolume", { value: "" });

    // register("patronethnicity", { value: "" });

    // register("patronage", { value: "" });

    // register("patronoccupation", { value: "" });

    // register("patronresidence", { value: "" });

    // register("contemporaryadulttype", { value: "" });

    // register("ownerethnicityorlanguage", { value: "" });

    // register("performancepaystatus", { value: "" });

    // register("displaystatus", { value: "" });

    // register("flpgroup", { value: "" });

    // register("pricinggroup", { value: "" });

    // register("arbillingcycle", { value: "" });

    // register("licenseename", { value: "" });

    // register("retailerstatus", { value: "" });

    // register("packageconsumptiontype", { value: "" });

    // register("draftconsumptiontypevalid", { value: "" });

    // register("sellingmethod", { value: "" });

    // register("packagetype", { value: "" });

    // register("licensenumber", { value: "" });

    // register("licenseexpdat", { value: "" });

    // register("chain", { value: "" });

    // register("chainstorenum", { value: "" });

    // register("calldayvalid", { value: "" });

    // register("salesroutetype", { value: "" });

    register('salesrep', { value: '' });

    // register("salesroutefrequency", { value: "" });

    // register("deliveryroutetype", { value: "" });

    // register("deliveryroute", { value: "" });

    // register("deliveryroutefrequency", { value: "" });

    // register("saleseffectivedate", { value: "" });

    // register("deliveryeffectivedate", { value: "" });

    register('supervisor', { value: '' });

    Axios.get(
      `https://acoeretailmdappprod1.azurewebsites.net/warehousecode/api?apikey=${apikey}&company=${company}&warehouse=${changedwarehouse}`,
    )
      .then(function (response) {
        // handle success
        console.log('Warehouse Code:', response?.data[0]?.Warehouse_ID);
        setwarehousecode(response?.data[0]?.Warehouse_ID);
        setcompanycode(response?.data[0]?.Company_ID);
        console.log('Location Code:', response?.data[0]?.Location);
        setactualcity(response.data[0].Location);
        console.log('This is complaint type');

        console.log('City:', response.data[0].Location);
        //   Axios.get(`https://acoeretailmdappprod1.azurewebsites.net/dropdown/companylist?apikey=${apikey}&actualcity=${response.data[0].Location}`).then(function (response) {
        //     // handle success
        //     console.log(response?.data);
        //     setcompanylist(response?.data);
        //     console.log("This is complaint type")

        //     })
        //     .catch(function (error) {
        //     // handle error
        //     console.log(error);
        //     })
        // Axios.get(`https://acoeretailmdappprod1.azurewebsites.net/dropdown/warehouselist?apikey=${apikey}&actualcity=${response.data[0].Location}`).then(function (response) {
        //   // handle success
        //   console.log(response?.data);
        //   setwarehouselist(response?.data);
        //   console.log("This is complaint type")

        //   })
        //   .catch(function (error) {
        //   // handle error
        //   console.log(error);
        //   })

        //   Axios.get(`https://acoeretailmdappprod1.azurewebsites.net/dropdown/citylist?apikey=${apikey}&actualcity=${response.data[0].Location}`).then(function (response) {
        //     // handle success
        //     console.log(response?.data);
        //     setcitylist(response?.data);
        //     console.log("This is complaint type")

        //     })
        //     .catch(function (error) {
        //     // handle error
        //     console.log(error);
        //     })

        //     Axios.get(`https://acoeretailmdappprod1.azurewebsites.net/dropdown/countylist?apikey=${apikey}&actualcity=${response.data[0].Location}`).then(function (response) {
        //     // handle success
        //     console.log(response?.data);
        //     setcountylist(response?.data);
        //     console.log("This is complaint type")

        //     })
        //     .catch(function (error) {
        //     // handle error
        //     console.log(error);
        //     })

        //     Axios.get(`https://acoeretailmdappprod1.azurewebsites.net/dropdown/retailerstatuslist?apikey=${apikey}&actualcity=${response.data[0].Location}`).then(function (response) {
        //     // handle success
        //     console.log(response?.data);
        //     setretailerstatuslist(response?.data);
        //     console.log("This is complaint type")

        //     })
        //     .catch(function (error) {
        //     // handle error
        //     console.log(error);
        //     })

        //     Axios.get(`https://acoeretailmdappprod1.azurewebsites.net/dropdown/licensestatuslist?apikey=${apikey}&actualcity=${response.data[0].Location}`).then(function (response) {
        //     // handle success
        //     console.log(response?.data);
        //     setlicensestatuslist(response?.data);
        //     console.log("This is complaint type")

        //     })
        //     .catch(function (error) {
        //     // handle error
        //     console.log(error);
        //     })

        //     Axios.get(`https://acoeretailmdappprod1.azurewebsites.net/dropdown/chainlist?apikey=${apikey}&actualcity=${response.data[0].Location}`).then(function (response) {
        //     // handle success
        //     console.log(response?.data);
        //     setchainlist(response?.data);
        //     console.log("This is complaint type")

        //     })
        //     .catch(function (error) {
        //     // handle error
        //     console.log(error);
        //     })

        //     Axios.get(`https://acoeretailmdappprod1.azurewebsites.net/dropdown/chainflag?apikey=${apikey}`).then(function (response) {
        //     // handle success
        //     console.log(response?.data);
        //     setchainflag(response?.data);
        //     console.log("This is complaint type")

        //     })
        //     .catch(function (error) {
        //     // handle error
        //     console.log(error);
        //     })

        //     Axios.get(`https://acoeretailmdappprod1.azurewebsites.net/dropdown/markettypelist?apikey=${apikey}`).then(function (response) {
        //     // handle success
        //     console.log(response?.data);
        //     setmarkettypelist(response?.data);
        //     console.log("This is complaint type")

        //     })
        //     .catch(function (error) {
        //     // handle error
        //     console.log(error);
        //     })

        //     Axios.get(`https://acoeretailmdappprod1.azurewebsites.net/dropdown/premiselist?apikey=${apikey}`).then(function (response) {
        //     // handle success
        //     console.log(response?.data);
        //     setpremiselist(response?.data);
        //     console.log("This is complaint type")

        //     })
        //     .catch(function (error) {
        //     // handle error
        //     console.log(error);
        //     })

        //     Axios.get(`https://acoeretailmdappprod1.azurewebsites.net/dropdown/packageconsumptiontypelist?apikey=${apikey}`).then(function (response) {
        //     // handle success
        //     console.log(response?.data);
        //     setpackageconsumptiontypelist(response?.data);
        //     console.log("This is complaint type")

        //     })
        //     .catch(function (error) {
        //     // handle error
        //     console.log(error);
        //     })

        //     Axios.get(`https://acoeretailmdappprod1.azurewebsites.net/dropdown/draftconsumptiontypelist?apikey=${apikey}`).then(function (response) {
        //       // handle success
        //       console.log(response?.data);
        //       setdraftconsumptiontypelist(response?.data);
        //       console.log("This is complaint type")

        //       })
        //       .catch(function (error) {
        //       // handle error
        //       console.log(error);
        //       })

        //       Axios.get(`https://acoeretailmdappprod1.azurewebsites.net/dropdown/retailerclasslist?apikey=${apikey}`).then(function (response) {
        //       // handle success
        //       console.log(response?.data);
        //       setretailerclasslist(response?.data);
        //       console.log("This is complaint type")

        //       })
        //       .catch(function (error) {
        //       // handle error
        //       console.log(error);
        //       })

        //       Axios.get(`https://acoeretailmdappprod1.azurewebsites.net/dropdown/civilianmilitarylist?apikey=${apikey}`).then(function (response) {
        //       // handle success
        //       console.log(response?.data);
        //       setcivilianmilitarylist(response?.data);
        //       console.log("This is complaint type")

        //       })
        //       .catch(function (error) {
        //       // handle error
        //       console.log(error);
        //       })

        //       Axios.get(`https://acoeretailmdappprod1.azurewebsites.net/dropdown/industryvolumelist?apikey=${apikey}`).then(function (response) {
        //       // handle success
        //       console.log(response?.data);
        //       setindustryvolumelist(response?.data);
        //       console.log("This is complaint type")

        //       })
        //       .catch(function (error) {
        //       // handle error
        //       console.log(error);
        //       })

        //       Axios.get(`https://acoeretailmdappprod1.azurewebsites.net/dropdown/patronethnicitylist?apikey=${apikey}`).then(function (response) {
        //       // handle success
        //       console.log(response?.data);
        //       setpatronethnicitylist(response?.data);
        //       console.log("This is complaint type")

        //       })
        //       .catch(function (error) {
        //       // handle error
        //       console.log(error);
        //       })

        //       Axios.get(`https://acoeretailmdappprod1.azurewebsites.net/dropdown/patronagelist?apikey=${apikey}`).then(function (response) {
        //       // handle success
        //       console.log(response?.data);
        //       setpatronagelist(response?.data);
        //       console.log("This is complaint type")

        //       })
        //       .catch(function (error) {
        //       // handle error
        //       console.log(error);
        //       })

        //       Axios.get(`https://acoeretailmdappprod1.azurewebsites.net/dropdown/patronresidencelist?apikey=${apikey}`).then(function (response) {
        //       // handle success
        //       console.log(response?.data);
        //       setpatronresidencelist(response?.data);
        //       console.log("This is complaint type")

        //       })
        //       .catch(function (error) {
        //       // handle error
        //       console.log(error);
        //       })

        //       Axios.get(`https://acoeretailmdappprod1.azurewebsites.net/dropdown/patronoccupationlist?apikey=${apikey}`).then(function (response) {
        //       // handle success
        //       console.log(response?.data);
        //       setpatronoccupationlist(response?.data);
        //       console.log("This is complaint type")

        //       })
        //       .catch(function (error) {
        //       // handle error
        //       console.log(error);
        //       })

        //       Axios.get(`https://acoeretailmdappprod1.azurewebsites.net/dropdown/contemporaryadulttypelist?apikey=${apikey}`).then(function (response) {
        //       // handle success
        //       console.log(response?.data);
        //       setcontemporaryadulttypelist(response?.data);
        //       console.log("This is complaint type")

        //       })
        //       .catch(function (error) {
        //       // handle error
        //       console.log(error);
        //       })

        //       Axios.get(`https://acoeretailmdappprod1.azurewebsites.net/dropdown/ownerethnicityorlanguagelist?apikey=${apikey}`).then(function (response) {
        //       // handle success
        //       console.log(response?.data);
        //       setownerethnicityorlanguagelist(response?.data);
        //       console.log("This is complaint type")

        //       })
        //       .catch(function (error) {
        //       // handle error
        //       console.log(error);
        //       })

        //       Axios.get(`https://acoeretailmdappprod1.azurewebsites.net/dropdown/performancepaystatuslist?apikey=${apikey}`).then(function (response) {
        //       // handle success
        //       console.log(response?.data);
        //       setperformancepaystatuslist(response?.data);
        //       console.log("This is complaint type")

        //       })
        //       .catch(function (error) {
        //       // handle error
        //       console.log(error);
        //       })

        //       Axios.get(`https://acoeretailmdappprod1.azurewebsites.net/dropdown/displaystatuslist?apikey=${apikey}`).then(function (response) {
        //       // handle success
        //       console.log(response?.data);
        //       setdisplaystatuslist(response?.data);
        //       console.log("This is complaint type")

        //       })
        //       .catch(function (error) {
        //       // handle error
        //       console.log(error);
        //       })

        //       Axios.get(`https://acoeretailmdappprod1.azurewebsites.net/dropdown/allowableposlist?apikey=${apikey}`).then(function (response) {
        //       // handle success
        //       console.log(response?.data);
        //       setallowableposlist(response?.data);
        //       console.log("This is complaint type")

        //       })
        //       .catch(function (error) {
        //       // handle error
        //       console.log(error);
        //       })

        //       Axios.get(`https://acoeretailmdappprod1.azurewebsites.net/dropdown/flpgrouplist?apikey=${apikey}&actualcity=${response.data[0].Location}`).then(function (response) {
        //       // handle success
        //       console.log(response?.data);
        //       setflpgrouplist(response?.data);
        //       console.log("This is complaint type")

        //       })
        //       .catch(function (error) {
        //       // handle error
        //       console.log(error);
        //       })

        //       Axios.get(`https://acoeretailmdappprod1.azurewebsites.net/dropdown/pricinggrouplist?apikey=${apikey}&actualcity=${response.data[0].Location}`).then(function (response) {
        //       // handle success
        //       console.log(response?.data);
        //       setpricinggrouplist(response?.data);
        //       console.log("This is complaint type")

        //       })
        //       .catch(function (error) {
        //       // handle error
        //       console.log(error);
        //       })

        //       Axios.get(`https://acoeretailmdappprod1.azurewebsites.net/dropdown/deliveryroutelist?apikey=${apikey}&actualcity=${response.data[0].Location}`).then(function (response) {
        //       // handle success
        //       console.log(response?.data);
        //       setdeliveryroutelist(response?.data);
        //       console.log("This is complaint type")

        //       })
        //       .catch(function (error) {
        //       // handle error
        //       console.log(error);
        //       })

        //       Axios.get(`https://acoeretailmdappprod1.azurewebsites.net/dropdown/deliveryscansheetrequiredlist?apikey=${apikey}`).then(function (response) {
        //       // handle success
        //       console.log(response?.data);
        //       setdeliveryscansheetrequiredlist(response?.data);
        //       console.log("This is complaint type")
        //       handleValidateStepper();
        //       })
        //       .catch(function (error) {
        //       // handle error
        //       console.log(error);
        //       })

        //       Axios.get(`https://acoeretailmdappprod1.azurewebsites.net/dropdown/planogrammablelist?apikey=${apikey}`).then(function (response) {
        //       // handle success
        //       console.log(response?.data);
        //       setplanogrammablelist(response?.data);
        //       console.log("This is complaint type")

        //       })
        //       .catch(function (error) {
        //       // handle error
        //       console.log(error);
        //       })

        //       Axios.get(`https://acoeretailmdappprod1.azurewebsites.net/dropdown/arcyclelist?apikey=${apikey}&actualcity=${response.data[0].Location}`).then(function (response) {
        //       // handle success
        //       console.log(response?.data);
        //       setarcyclelist(response?.data);
        //       console.log("This is complaint type")

        //       })
        //       .catch(function (error) {
        //       // handle error
        //       console.log(error);
        //       })

        //       Axios.get(`https://acoeretailmdappprod1.azurewebsites.net/dropdown/routetypelist?apikey=${apikey}&actualcity=${response.data[0].Location}`).then(function (response) {
        //       // handle success
        //       console.log(response?.data);
        //       setroutetypelist(response?.data);
        //       console.log("This is complaint type")

        //       })
        //       .catch(function (error) {
        //       // handle error
        //       console.log(error);
        //       })

        //       Axios.get(`https://acoeretailmdappprod1.azurewebsites.net/dropdown/salesreplist?apikey=${apikey}&companycode=${response?.data[0]?.Company_ID}`).then(function (response) {
        //       // handle success
        //       console.log(response?.data);
        //       setsalesreplist(response?.data);
        //       console.log("This is complaint type")

        //       })
        //       .catch(function (error) {
        //       // handle error
        //       console.log(error);
        //       })

        //       Axios.get(`https://acoeretailmdappprod1.azurewebsites.net/dropdown/routefrequencylist?apikey=${apikey}&actualcity=${response.data[0].Location}`).then(function (response) {
        //         // handle success
        //         console.log(response?.data);
        //         setroutefrequencylist(response?.data);
        //         console.log("This is complaint type")

        //         })
        //         .catch(function (error) {
        //         // handle error
        //         console.log(error);
        //         })

        //         Axios.get(`https://acoeretailmdappprod1.azurewebsites.net/dropdown/supervisorlist?apikey=${apikey}&companycode=${response?.data[0]?.Company_ID}`).then(function (response) {
        //         // handle success
        //         console.log(response?.data);
        //         setsupervisorlist(response?.data);
        //         console.log("This is complaint type")

        //         })
        //         .catch(function (error) {
        //         // handle error
        //         console.log(error);
        //         })

        //         Axios.get(`https://acoeretailmdappprod1.azurewebsites.net/dropdown/territorieslist?apikey=${apikey}&actualcity=${response.data[0].Location}&role=${localStorage.getItem('role')}`).then(function (response) {
        //         // handle success
        //         console.log(response?.data);
        //         console.log("This is complaint type")
        //         handleValidateStepper();

        //         var templist = response.data
        //         if(response.data.length > 0){
        //         for(var i=0;i<response?.data.length;i++){
        //           templist[i].isChecked = false

        //         }
        //       }

        //         console.log("Territories list:",templist)
        //         setterritorieslist(templist)

        //         })
        //         .catch(function (error) {
        //         // handle error
        //         console.log(error);
        //         })
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  useEffect(() => {
    Axios.post('/reqnocheck/api', {
      reqnofinal: reqno,
      email: localStorage.getItem('username'),
      role: localStorage.getItem('role'),
      warehouse: localStorage.getItem('warehouse'),
    }).then((response) => {
      console.log(response?.data);
      setreqnocheckval(response?.data);
    });
  }, []);

  const handleTerritories = (e) => {
    var present = false;
    var tempterritories = [...territories];
    var templist = [...territorieslist];
    var removeindex;
    for (var i = 0; i < tempterritories.length; i++) {
      console.log(i, tempterritories[i], e.target.value);
      if (tempterritories[i] == e.target.value) {
        present = true;
        console.log('present');
        tempterritories.splice(i, 1);
      }
    }

    if (present == true) {
      setterritories(tempterritories);
    } else if (present == false) {
      setterritories((territories) => [...territories, e.target.value]);
    }

    for (var j = 0; j < templist.length; j++) {
      if (templist[j].value == e.target.value) {
        templist[j].isChecked = !templist[j].isChecked;
      }
    }

    console.log('Final temp territories', tempterritories);
    console.log('Final temp list', templist);
    setterritorieslist(templist);
    console.log(e.target.value);
  };

  const handleSelectAll = (e) => {
    setterritories([]);
    var templist = [...territorieslist];
    if (territoriesselectall == false) {
      for (var i = 0; i < templist.length; i++) {
        setterritories((territories) => [...territories, templist[i].value]);
        templist[i].isChecked = true;
      }
    } else {
      for (var i = 0; i < templist.length; i++) {
        templist[i].isChecked = false;
      }
    }
    setterritoriesselectall(!territoriesselectall);
    setterritorieslist(templist);
  };

  useEffect(() => {
    Axios.post('/requestdetails/api/details', { reqno: reqno }).then(async (response) => {
      var territoriesfinal = await JSON.parse(response.data[0].territories);

      Axios.get(
        `https://acoeretailmdappprod1.azurewebsites.net/dropdown/sellingmethodlist?apikey=${apikey}&packageconsumptiontype=${response.data[0].packageConsumptionType}&draftconsumptiontype=${response.data[0].draftConsumptionType}`,
      )
        .then(function (response) {
          // handle success
          console.log(response?.data);
          setsellingmethodlist(response?.data);
          console.log('This is complaint type');
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      Axios.get(
        `https://acoeretailmdappprod1.azurewebsites.net/dropdown/sellablepackagetypelist?apikey=${apikey}&packageconsumptiontype=${response.data[0].packageConsumptionType}&draftconsumptiontype=${response.data[0].draftConsumptionType}`,
      )
        .then(function (response) {
          // handle success
          console.log(response?.data);
          setsellablepackagetypelist(response?.data);
          console.log('This is complaint type');
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      Axios.get(
        `https://acoeretailmdappprod1.azurewebsites.net/warehousecode/api?apikey=${apikey}&company=${response.data[0].company}&warehouse=${response.data[0].warehouse}`,
      )
        .then(function (response) {
          // handle success
          console.log('Warehouse Code:', response?.data[0]?.Warehouse_ID);
          setwarehousecode(response?.data[0]?.Warehouse_ID);
          setcompanycode(response?.data[0]?.Company_ID);
          console.log('Location Code:', response?.data[0]?.Location);
          setactualcity(response.data[0].Location);
          console.log('This is complaint type');

          console.log('City:', response.data[0].Location);
          Axios.get(
            `https://acoeretailmdappprod1.azurewebsites.net/dropdown/companylist?apikey=${apikey}&actualcity=${response.data[0].Location}`,
          )
            .then(function (response) {
              // handle success
              console.log(response?.data);
              setcompanylist(response?.data);
              console.log('This is complaint type');
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });
          Axios.get(
            `https://acoeretailmdappprod1.azurewebsites.net/dropdown/warehouselist?apikey=${apikey}&actualcity=${response.data[0].Location}`,
          )
            .then(function (response) {
              // handle success
              console.log(response?.data);
              setwarehouselist(response?.data);
              console.log('This is complaint type');
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });

          Axios.get(
            `https://acoeretailmdappprod1.azurewebsites.net/dropdown/citylist?apikey=${apikey}&actualcity=${response.data[0].Location}`,
          )
            .then(function (response) {
              // handle success
              console.log(response?.data);
              setcitylist(response?.data);
              console.log('This is complaint type');
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });

          Axios.get(
            `https://acoeretailmdappprod1.azurewebsites.net/dropdown/countylist?apikey=${apikey}&actualcity=${response.data[0].Location}`,
          )
            .then(function (response) {
              // handle success
              console.log(response?.data);
              setcountylist(response?.data);
              console.log('This is complaint type');
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });

          Axios.get(
            `https://acoeretailmdappprod1.azurewebsites.net/dropdown/retailerstatuslist?apikey=${apikey}&actualcity=${response.data[0].Location}`,
          )
            .then(function (response) {
              // handle success
              console.log(response?.data);
              setretailerstatuslist(response?.data);
              console.log('This is complaint type');
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });

          Axios.get(
            `https://acoeretailmdappprod1.azurewebsites.net/dropdown/licensestatuslist?apikey=${apikey}&actualcity=${response.data[0].Location}`,
          )
            .then(function (response) {
              // handle success
              console.log(response?.data);
              setlicensestatuslist(response?.data);
              console.log('This is complaint type');
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });

          Axios.get(
            `https://acoeretailmdappprod1.azurewebsites.net/dropdown/chainlist?apikey=${apikey}&actualcity=${response.data[0].Location}`,
          )
            .then(function (response) {
              // handle success
              console.log(response?.data);
              setchainlist(response?.data);
              console.log('This is complaint type');
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });

          Axios.get(
            `https://acoeretailmdappprod1.azurewebsites.net/dropdown/chainflag?apikey=${apikey}`,
          )
            .then(function (response) {
              // handle success
              console.log(response?.data);
              setchainflag(response?.data);
              console.log('This is complaint type');
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });

          Axios.get(
            `https://acoeretailmdappprod1.azurewebsites.net/dropdown/markettypelist?apikey=${apikey}`,
          )
            .then(function (response) {
              // handle success
              console.log(response?.data);
              setmarkettypelist(response?.data);
              console.log('This is complaint type');
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });

          Axios.get(
            `https://acoeretailmdappprod1.azurewebsites.net/dropdown/premiselist?apikey=${apikey}`,
          )
            .then(function (response) {
              // handle success
              console.log(response?.data);
              setpremiselist(response?.data);
              console.log('This is complaint type');
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });

          Axios.get(
            `https://acoeretailmdappprod1.azurewebsites.net/dropdown/packageconsumptiontypelist?apikey=${apikey}`,
          )
            .then(function (response) {
              // handle success
              console.log(response?.data);
              setpackageconsumptiontypelist(response?.data);
              console.log('This is complaint type');
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });

          Axios.get(
            `https://acoeretailmdappprod1.azurewebsites.net/dropdown/draftconsumptiontypelist?apikey=${apikey}`,
          )
            .then(function (response) {
              // handle success
              console.log(response?.data);
              setdraftconsumptiontypelist(response?.data);
              console.log('This is complaint type');
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });

          Axios.get(
            `https://acoeretailmdappprod1.azurewebsites.net/dropdown/retailerclasslist?apikey=${apikey}`,
          )
            .then(function (response) {
              // handle success
              console.log(response?.data);
              setretailerclasslist(response?.data);
              console.log('This is complaint type');
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });

          Axios.get(
            `https://acoeretailmdappprod1.azurewebsites.net/dropdown/civilianmilitarylist?apikey=${apikey}`,
          )
            .then(function (response) {
              // handle success
              console.log(response?.data);
              setcivilianmilitarylist(response?.data);
              console.log('This is complaint type');
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });

          Axios.get(
            `https://acoeretailmdappprod1.azurewebsites.net/dropdown/industryvolumelist?apikey=${apikey}`,
          )
            .then(function (response) {
              // handle success
              console.log(response?.data);
              setindustryvolumelist(response?.data);
              console.log('This is complaint type');
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });

          Axios.get(
            `https://acoeretailmdappprod1.azurewebsites.net/dropdown/patronethnicitylist?apikey=${apikey}`,
          )
            .then(function (response) {
              // handle success
              console.log(response?.data);
              setpatronethnicitylist(response?.data);
              console.log('This is complaint type');
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });

          Axios.get(
            `https://acoeretailmdappprod1.azurewebsites.net/dropdown/patronagelist?apikey=${apikey}`,
          )
            .then(function (response) {
              // handle success
              console.log(response?.data);
              setpatronagelist(response?.data);
              console.log('This is complaint type');
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });

          Axios.get(
            `https://acoeretailmdappprod1.azurewebsites.net/dropdown/patronresidencelist?apikey=${apikey}`,
          )
            .then(function (response) {
              // handle success
              console.log(response?.data);
              setpatronresidencelist(response?.data);
              console.log('This is complaint type');
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });

          Axios.get(
            `https://acoeretailmdappprod1.azurewebsites.net/dropdown/patronoccupationlist?apikey=${apikey}`,
          )
            .then(function (response) {
              // handle success
              console.log(response?.data);
              setpatronoccupationlist(response?.data);
              console.log('This is complaint type');
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });

          Axios.get(
            `https://acoeretailmdappprod1.azurewebsites.net/dropdown/contemporaryadulttypelist?apikey=${apikey}`,
          )
            .then(function (response) {
              // handle success
              console.log(response?.data);
              setcontemporaryadulttypelist(response?.data);
              console.log('This is complaint type');
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });

          Axios.get(
            `https://acoeretailmdappprod1.azurewebsites.net/dropdown/draftstatuslist?apikey=${apikey}`,
          )
            .then(function (response) {
              // handle success
              console.log(response?.data);
              setdraftstatuslist(response?.data);
              console.log('This is complaint type');
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });

          Axios.get(
            `https://acoeretailmdappprod1.azurewebsites.net/dropdown/ownerethnicityorlanguagelist?apikey=${apikey}`,
          )
            .then(function (response) {
              // handle success
              console.log(response?.data);
              setownerethnicityorlanguagelist(response?.data);
              console.log('This is complaint type');
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });

          Axios.get(
            `https://acoeretailmdappprod1.azurewebsites.net/dropdown/performancepaystatuslist?apikey=${apikey}`,
          )
            .then(function (response) {
              // handle success
              console.log(response?.data);
              setperformancepaystatuslist(response?.data);
              console.log('This is complaint type');
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });

          Axios.get(
            `https://acoeretailmdappprod1.azurewebsites.net/dropdown/displaystatuslist?apikey=${apikey}`,
          )
            .then(function (response) {
              // handle success
              console.log(response?.data);
              setdisplaystatuslist(response?.data);
              console.log('This is complaint type');
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });

          Axios.get(
            `https://acoeretailmdappprod1.azurewebsites.net/dropdown/allowableposlist?apikey=${apikey}`,
          )
            .then(function (response) {
              // handle success
              console.log('Allowable POS:', response?.data);
              setallowableposlist(response?.data);
              console.log('This is complaint type');
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });

          Axios.get(
            `https://acoeretailmdappprod1.azurewebsites.net/dropdown/flpgrouplist?apikey=${apikey}&actualcity=${response.data[0].Location}`,
          )
            .then(function (response) {
              // handle success
              console.log(response?.data);
              setflpgrouplist(response?.data);
              console.log('This is complaint type');
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });

          Axios.get(
            `https://acoeretailmdappprod1.azurewebsites.net/dropdown/pricinggrouplist?apikey=${apikey}&actualcity=${response.data[0].Location}`,
          )
            .then(function (response) {
              // handle success
              console.log(response?.data);
              setpricinggrouplist(response?.data);
              console.log('This is complaint type');
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });

          Axios.get(
            `https://acoeretailmdappprod1.azurewebsites.net/dropdown/deliveryroutelist?apikey=${apikey}&actualcity=${response.data[0].Location}`,
          )
            .then(function (response) {
              // handle success
              console.log(response?.data);
              setdeliveryroutelist(response?.data);
              console.log('This is complaint type');
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });

          Axios.get(
            `https://acoeretailmdappprod1.azurewebsites.net/dropdown/deliveryscansheetrequiredlist?apikey=${apikey}`,
          )
            .then(function (response) {
              // handle success
              console.log(response?.data);
              setdeliveryscansheetrequiredlist(response?.data);
              console.log('This is complaint type');
              handleValidateStepper();
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });

          Axios.get(
            `https://acoeretailmdappprod1.azurewebsites.net/dropdown/planogrammablelist?apikey=${apikey}`,
          )
            .then(function (response) {
              // handle success
              console.log(response?.data);
              setplanogrammablelist(response?.data);
              console.log('This is complaint type');
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });

          Axios.get(
            `https://acoeretailmdappprod1.azurewebsites.net/dropdown/arcyclelist?apikey=${apikey}&actualcity=${response.data[0].Location}`,
          )
            .then(function (response) {
              // handle success
              console.log(response?.data);
              setarcyclelist(response?.data);
              console.log('This is complaint type');
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });

          Axios.get(
            `https://acoeretailmdappprod1.azurewebsites.net/dropdown/routetypelist?apikey=${apikey}&actualcity=${response.data[0].Location}`,
          )
            .then(function (response) {
              // handle success
              console.log(response?.data);
              setroutetypelist(response?.data);
              console.log('This is complaint type');
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });

          Axios.get(
            `https://acoeretailmdappprod1.azurewebsites.net/dropdown/salesreplist?apikey=${apikey}&companycode=${response?.data[0]?.Company_ID}`,
          )
            .then(function (response) {
              // handle success
              console.log('Sales Reps:', response?.data);
              setsalesreplist(response?.data);
              console.log('This is complaint type');
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });

          Axios.get(
            `https://acoeretailmdappprod1.azurewebsites.net/dropdown/routefrequencylist?apikey=${apikey}&actualcity=${response.data[0].Location}`,
          )
            .then(function (response) {
              // handle success
              console.log(response?.data);
              setroutefrequencylist(response?.data);
              console.log('This is complaint type');
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });

          Axios.get(
            `https://acoeretailmdappprod1.azurewebsites.net/dropdown/supervisorlist?apikey=${apikey}&companycode=${response?.data[0]?.Company_ID}`,
          )
            .then(function (response) {
              // handle success
              console.log(response?.data);
              setsupervisorlist(response?.data);
              console.log('This is complaint type');
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });

          //   Axios.get(`https://acoeretailmdappprod1.azurewebsites.net/dropdown/territorieslist?apikey=${apikey}&actualcity=${response.data[0].Location}&role=${localStorage.getItem('role')}`).then(function (response) {
          //   // handle success
          //   console.log(response?.data);
          //   console.log("This is complaint type")

          //   var templist = response.data

          //   if(response.data.length > 0){
          //   for(var i=0;i<response?.data.length;i++){
          //     templist[i].isChecked = false

          //   }
          // }

          //   console.log("Territories list:",templist)
          //   setterritorieslist(templist)

          //   })
          //   .catch(function (error) {
          //   // handle error
          //   console.log(error);
          //   })

          Axios.get(
            `https://acoeretailmdappprod1.azurewebsites.net/dropdown/territorieslist?apikey=${apikey}&actualcity=${response.data[0].Location
            }&role=${localStorage.getItem('role')}`,
          )
            .then(function (response) {
              var changedterritorieslist = response.data;
              if (response.data.length > 0) {
                for (var i = 0; i < response?.data.length; i++) {
                  changedterritorieslist[i].isChecked = false;
                }
              }
              var templist = territoriesfinal;
              console.log('Final territories', territoriesfinal);
              console.log('Match Trial', templist);
              console.log('Match Trial', changedterritorieslist);

              for (var j = 0; j < templist.length; j++) {
                for (var i = 0; i < changedterritorieslist.length; i++) {
                  if (templist[j] == changedterritorieslist[i].value) {
                    console.log('match', templist[j], changedterritorieslist[i].value);
                    changedterritorieslist[i].isChecked = true;
                  }
                }
              }
              console.log('territorieslistfinal:', changedterritorieslist);
              setterritorieslist(changedterritorieslist);
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      if (
        response.data[0].status == 'Approved' ||
        response.data[0].chainOrIndependent == 'NON LICENSED'
      ) {
        setdatepickerdisabled();
      } else {
        setdatepickerdisabled({ backgroundColor: 'white' });
      }

      if (
        response.data[0].status != 'Approved' &&
        response.data[0].chainOrIndependent != 'NON LICENSED'
      ) {
        setlicenseexpdisabled({ backgroundColor: 'white' });
      } else {
        setlicenseexpdisabled();
      }

      await console.log(response.data[0]);
      await setrequestno(response.data[0].requestNo);
      await setfirstname(response.data[0].requestorFirstName);
      await setlastname(response.data[0].requestorLastName);
      await setemailid(response.data[0].requestorEmailID);
      await setstatus(response.data[0].status);
      await setempid(response.data[0].employeeID);
      await setreqid(response.data[0].requestorID);
      await setphoneno(response.data[0].requestorPhoneNo);
      await setcompany(response.data[0].company);
      await setwarehouse(response.data[0].warehouse);
      await setretailerid(response.data[0].retailerID);
      await setdbaname(response.data[0].dbaName);
      await setstreet(response.data[0].street);
      await setcity(response.data[0].city);
      await setstate(response.data[0].state);
      await setzipcode(response.data[0].zipCode);
      await setcounty(response.data[0].county);
      await setretailerphoneno(response.data[0].phNumber);
      await setfaxno(response.data[0].faxNumber);
      await setbuyer(response.data[0].buyer);
      await setretailerstatus(response.data[0].retailerStatus);
      await setbusinessopendate(response.data[0].businessOpenDate);
      await setseasonalopendate(response.data[0].seasonalOpenDate);
      await setseasonalclosedate(response.data[0].seasonalCloseDate);
      await setlicensestatus(response.data[0].licenseStatus);
      await setlicenseename(response.data[0].licenseeName);
      await setlicensenumber(response.data[0].license1Numbers);
      await setlicenseexpdat(response.data[0].license1ExpirationDate);
      await settaxid(response.data[0].taxID);
      await setchainindependentstatus(response.data[0].chainOrIndependent);
      await setchain(response.data[0].chain);
      await setchainstorenum(response.data[0].chainStoreNumber);
      await setmarkettype(response.data[0].marketType);
      await setonoffpremisetype(response.data[0].premiseType);
      await setpackageconsumptiontype(response.data[0].packageConsumptionType);
      await setdraftconsumptiontype(response.data[0].draftConsumptionType);
      await setretailerclass(response.data[0].retailerClass);
      await setcivilianmilitary(response.data[0].civilianOrMilitary);
      await setindustryvolume(response.data[0].industry1Volume);
      await setpatronethnicity(response.data[0].patronEthnicity);
      await setpatronage(response.data[0].patronAge);
      await setpatronoccupation(response.data[0].patronOccupation);
      await setpatronresidence(response.data[0].patronResidence);
      await setcontemporaryadulttype(response.data[0].contemporaryAdultType);
      await setownerethnicityorlanguage(response.data[0].ownerEthnicityLanguage);
      await setsellingmethod(response.data[0].sellingMethod);
      await setpackagetype(response.data[0].packageType);
      await setdraftstatus(response.data[0].draftStatus);
      await setponumreq(response.data[0].PONumberRequired);
      await console.log('PO Num Req:', response.data[0].PONumberRequired);
      await setperformancepaystatus(response.data[0].performancePayStatus);
      await setdisplaystatus(response.data[0].displayStatus);
      await setallowablepos(response.data[0].allowablePOS);
      await setflpgroup(response.data[0].flpGroup);
      await setpricinggroup(response.data[0].pricingGroup);
      await setpricingsubgroup(response.data[0].pricingSubGroup);
      await setdeliveryscansheetrequired(response.data[0].deliveryScanSheetRequired);
      await setplanogrammablestatus(response.data[0].planogrammableStatus);
      await setarbillingcycle(response.data[0].arBillingCycle);
      await setcallday(JSON.parse(response.data[0].callDay));
      await setsalesroutetype(response.data[0].salesRouteType);
      await setsalesrep(response.data[0].salesRep);
      await setsalesroutesequence(response.data[0].salesRouteSequence);
      await setsalesroutefrequency(response.data[0].salesRouteFrequency);
      await setsaleseffectivedate(response.data[0].salesEffectiveDate);
      await setdeliveryday(JSON.parse(response.data[0].deliveryDay));
      await setdeliveryroutetype(response.data[0].deliveryRouteType);
      await setdeliveryroute(response.data[0].deliveryRoute);
      await setdeliveryroutesequence(response.data[0].deliveryRouteSequence);
      await setdeliveryroutefrequency(response.data[0].deliveryRouteFrequency);
      await setdeliveryeffectivedate(response.data[0].deliveryEffectiveDate);
      await setsupervisor(response.data[0].supervisor);
      await setopeningtime(response.data[0].openingTime);
      await setclosingtime(response.data[0].closingTime);
      await setdeliverywindowstart(response.data[0].deliveryWindow1Start);
      await setdeliverywindowend(response.data[0].deliveryWindow1End);
      await setdeliverywindow2start(response.data[0].deliveryWindow2Start);
      await setdeliverywindow2end(response.data[0].deliveryWindow2End);
      await setlatitude(response.data[0].latitude);
      await setlongitude(response.data[0].longitude);
      await setadditionalserviceinstruction(response.data[0].additionalServiceInstructions);
      await setterritories(JSON.parse(response.data[0].territories));
      await setrejectionreason(response.data[0].rejectionReason);
      setValue('buyer', response.data[0].buyer);
      setValue('retailerphoneno', response.data[0].phNumber);
      setValue('openingtime', response.data[0].openingTime);
      setValue('closingtime', response.data[0].closingTime);
      setValue('deliverywindowstart', response.data[0].deliveryWindow1Start);
      setValue('deliverywindowend', response.data[0].deliveryWindow1End);
      setValue('deliverywindow2start', response.data[0].deliveryWindow2Start);
      setValue('deliverywindow2end', response.data[0].deliveryWindow2End);
      await register('company', { value: response.data[0].company });
      await register('warehouse', { value: response.data[0].warehouse });
      await register('dbaname', { value: response.data[0].dbaName });
      await register('streetaddress', { value: response.data[0].street });
      await register('city', { value: response.data[0].city });
      await register('state', { value: response.data[0].state });
      await register('zipcode', { value: response.data[0].zipCode });
      await register('chainstorenumvalid', { value: response.data[0].chainStoreNumber });
      await register('licensestatusvalid', { value: response.data[0].licenseStatus });
      await register('county', { value: response.data[0].county });
      await register('chainindependentstatusvalid', { value: response.data[0].chainOrIndependent });
      await register('markettype', { value: response.data[0].marketType });
      await register('onoffpremisetype', { value: response.data[0].premiseType });
      await register('retailerclass', { value: response.data[0].retailerClass });
      await register('civilianmilitary', { value: response.data[0].civilianOrMilitary });
      await register('industryvolume', { value: response.data[0].industry1Volume });
      await register('patronethnicity', { value: response.data[0].patronEthnicity });
      await register('patronage', { value: response.data[0].patronAge });
      await register('patronoccupation', { value: response.data[0].patronOccupation });
      await register('patronresidence', { value: response.data[0].patronResidence });
      await register('contemporaryadulttype', { value: response.data[0].contemporaryAdultType });
      await register('ownerethnicityorlanguage', {
        value: response.data[0].ownerEthnicityLanguage,
      });
      await register('performancepaystatus', { value: response.data[0].performancePayStatus });
      await register('displaystatus', { value: response.data[0].displayStatus });
      await register('flpgroup', { value: response.data[0].flpGroup });
      await register('pricinggroup', { value: response.data[0].pricingGroup });
      await register('arbillingcycle', { value: response.data[0].arBillingCycle });
      await register('licenseename', { value: response.data[0].licenseeName });
      await register('retailerstatus', { value: response.data[0].retailerStatus });
      await register('packageconsumptiontype', { value: response.data[0].packageConsumptionType });
      await register('draftconsumptiontypevalid', { value: response.data[0].draftConsumptionType });
      await register('sellingmethod', { value: response.data[0].sellingMethod });
      await register('packagetype', { value: response.data[0].packageType });
      await setValue('draftstatus', response.data[0].draftStatus);
      await register('licensenumber', { value: response.data[0].license1Numbers });
      await register('licenseexpdat', { value: response.data[0].license1ExpirationDate });
      await register('chain', { value: response.data[0].chain });
      await register('chainstorenum', { value: response.data[0].chainStoreNumber });
      await register('calldayvalid', { value: response.data[0].callDay });
      await register('deliverydayvalid', { value: response.data[0].deliveryDay });
      await register('salesroutetype', { value: response.data[0].salesRouteType });
      await register('salesrep', { value: response.data[0].salesRep });
      await register('salesroutefrequency', { value: response.data[0].salesRouteFrequency });
      await register('deliveryroutetype', { value: response.data[0].deliveryRouteType });
      await register('deliveryroute', { value: response.data[0].deliveryRoute });
      await register('deliveryroutefrequency', { value: response.data[0].deliveryRouteFrequency });
      await setValue('saleseffectivedate', response.data[0].salesEffectiveDate);
      await setValue('allowablepos', response.data[0].allowablePOS);
      await register('deliveryeffectivedate', { value: response.data[0].deliveryEffectiveDate });
      await register('supervisor', { value: response.data[0].supervisor });
      await setValue('planogrammablestatus', response.data[0].planogrammableStatus);
      await handleValidateStepper();
      // await setTimeout(() => {
      //   setalldataadded('done')
      // }, 00);
    });
    // .then( () => {
    //   handleValidateStepper();
    //   setTimeout(() => {
    //     setalldataadded('done')
    //   }, 10000);
    // })
  }, []);

  useEffect(() => {
    handleValidateStepper();
  });

  useEffect(() => {
    const today = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    setdatelimit(tomorrow);

    setretailerstatus('ACTIVE');
    register('retailerstatus', { value: 'ACTIVE' });
  }, []);

  // create an event listener
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    if (window.innerWidth < 1220) {
      setstepperclassname('col-9');
      console.log('Class Name:', stepperclassname);
      setIsMobile('vertical');
      console.log('vertical');
    } else {
      setstepperclassname('col-12');
      console.log('Class Name:', stepperclassname);
      setIsMobile('horizontal');
      console.log('horizontal');
    }
  });

  const handleChange = (newValue) => {
    setoldnewValue(newValue);
    console.log(newValue);
  };

  const handleSaveDraft = () => {
    setdel(1);
    setbuttondisabled(true);
    setisloading(true);
    Axios.post('/savedraft/api/aftersave', {
      requestno: requestno,
      latitude: latitude,
      longitude: longitude,
      firstname: firstname,
      lastname: lastname,
      emailid: emailid,
      phoneno: phoneno,
      warehousecode: warehousecode,
      taxid: taxid,
      empid: empid,
      reqid: reqid,
      dbaname: dbaname,
      licenseename: licenseename,
      company: company,
      refcompany,
      retailerphoneno: retailerphoneno,
      actualcity: actualcity,
      warehouse: warehouse,
      street: street,
      city: city,
      state: state,
      zipcode: zipcode,
      county: county,
      faxno: faxno,
      buyer: buyer,
      licensestatus: licensestatus,
      licensenumber: licensenumber,
      licenseexpdat: licenseexpdat,
      ponumreq: ponumreq,
      chain: chain,
      chainstorenum: chainstorenum,
      chainindependentstatus: chainindependentstatus,
      seasonalclosedate: seasonalclosedate,
      businessopendate,
      businessopendate,
      seasonalopendate: seasonalopendate,
      phoneno: phoneno,
      retailerstatus: retailerstatus,
      sellingmethod: sellingmethod,
      packagetype: packagetype,
      draftstatus: draftstatus,
      performancepaystatus: performancepaystatus,
      displaystatus: displaystatus,
      allowablepos: allowablepos,
      draftconsumptiontype: draftconsumptiontype,
      retailerclass: retailerclass,
      civilianmilitary: civilianmilitary,
      industryvolume: industryvolume,
      patronethnicity: patronethnicity,
      patronage: patronage,
      patronoccupation: patronoccupation,
      patronresidence: patronresidence,
      contemporaryadulttype: contemporaryadulttype,
      ownerethnicityorlanguage: ownerethnicityorlanguage,
      markettype: markettype,
      onoffpremisetype: onoffpremisetype,
      packageconsumptiontype: packageconsumptiontype,
      flpgroup: flpgroup,
      pricinggroup: pricinggroup,
      pricingsubgroup: pricingsubgroup,
      deliveryscansheetrequired: deliveryscansheetrequired,
      callday: callday,
      salesroutetype: salesroutetype,
      salesrep: salesrep,
      salesroutesequence: salesroutesequence,
      salesroutefrequency: salesroutefrequency,
      supervisor: supervisor,
      saleseffectivedate: saleseffectivedate,
      deliveryday: deliveryday,
      deliveryroutetype: deliveryroutetype,
      deliveryroute: deliveryroute,
      deliveryroutesequence: deliveryroutesequence,
      deliveryroutefrequency: deliveryroutefrequency,
      deliveryeffectivedate: deliveryeffectivedate,
      openingtime: openingtime,
      closingtime: closingtime,
      deliverywindowstart: deliverywindowstart,
      deliverywindowend: deliverywindowend,
      deliverywindow2start: deliverywindow2start,
      deliverywindow2end: deliverywindow2end,
      additionalserviceinstruction: additionalserviceinstruction,
      whichwarehousewillthisretailerbeservicedoutof: whichwarehousewillthisretailerbeservicedoutof,
      planogrammablestatus: planogrammablestatus,
      arbillingcycle: arbillingcycle,
      territories: territories,
      saveemailid: localStorage.getItem('username'),
      saveempid: localStorage.getItem('employeeID'),
      dbaduplicatemarker: dbaduplicatemarker,
      filecount: filecount,
      sessionid: sessionid,
    }).then((response) => {
      if (response.data == 'Already Approved') {
        setrequeststatus('Failed');
        setsubmitdraftstatus(
          'This request is already approved/rejected. Close this to refresh your page',
        );
        setSuccessData2(true);
      } else {
        setrequeststatus('Saved');
        setsubmitdraftstatus('Draft Saved');
        setSuccessData(true);
      }
    });
  };

  const handleSalesManagerSave = () => {
    setdel(1);
    setbuttondisabled(true);
    setisloading(true);
    Axios.post('/savedraft/api/salesmanagersave', {
      requestno: requestno,
      latitude: latitude,
      longitude: longitude,
      firstname: firstname,
      status: status,
      lastname: lastname,
      emailid: emailid,
      phoneno: phoneno,
      warehousecode: warehousecode,
      retailerphoneno: retailerphoneno,
      taxid: taxid,
      empid: empid,
      reqid: reqid,
      dbaname: dbaname,
      licenseename: licenseename,
      company: company,
      refcompany,
      actualcity: actualcity,
      warehouse: warehouse,
      street: street,
      city: city,
      state: state,
      zipcode: zipcode,
      county: county,
      faxno: faxno,
      buyer: buyer,
      licensestatus: licensestatus,
      licensenumber: licensenumber,
      licenseexpdat: licenseexpdat,
      ponumreq: ponumreq,
      chain: chain,
      chainstorenum: chainstorenum,
      chainindependentstatus: chainindependentstatus,
      seasonalclosedate: seasonalclosedate,
      businessopendate,
      businessopendate,
      seasonalopendate: seasonalopendate,
      phoneno: phoneno,
      retailerstatus: retailerstatus,
      sellingmethod: sellingmethod,
      packagetype: packagetype,
      draftstatus: draftstatus,
      performancepaystatus: performancepaystatus,
      displaystatus: displaystatus,
      allowablepos: allowablepos,
      draftconsumptiontype: draftconsumptiontype,
      retailerclass: retailerclass,
      civilianmilitary: civilianmilitary,
      industryvolume: industryvolume,
      patronethnicity: patronethnicity,
      patronage: patronage,
      patronoccupation: patronoccupation,
      patronresidence: patronresidence,
      contemporaryadulttype: contemporaryadulttype,
      ownerethnicityorlanguage: ownerethnicityorlanguage,
      markettype: markettype,
      onoffpremisetype: onoffpremisetype,
      packageconsumptiontype: packageconsumptiontype,
      flpgroup: flpgroup,
      pricinggroup: pricinggroup,
      pricingsubgroup: pricingsubgroup,
      deliveryscansheetrequired: deliveryscansheetrequired,
      callday: callday,
      salesroutetype: salesroutetype,
      salesrep: salesrep,
      salesroutesequence: salesroutesequence,
      salesroutefrequency: salesroutefrequency,
      supervisor: supervisor,
      saleseffectivedate: saleseffectivedate,
      deliveryday: deliveryday,
      deliveryroutetype: deliveryroutetype,
      deliveryroute: deliveryroute,
      deliveryroutesequence: deliveryroutesequence,
      deliveryroutefrequency: deliveryroutefrequency,
      deliveryeffectivedate: deliveryeffectivedate,
      openingtime: openingtime,
      closingtime: closingtime,
      deliverywindowstart: deliverywindowstart,
      deliverywindowend: deliverywindowend,
      deliverywindow2start: deliverywindow2start,
      deliverywindow2end: deliverywindow2end,
      additionalserviceinstruction: additionalserviceinstruction,
      whichwarehousewillthisretailerbeservicedoutof: whichwarehousewillthisretailerbeservicedoutof,
      planogrammablestatus: planogrammablestatus,
      arbillingcycle: arbillingcycle,
      territories: territories,
      saveemailid: localStorage.getItem('username'),
      saveempid: localStorage.getItem('employeeID'),
      dbaduplicatemarker: dbaduplicatemarker,
      filecount: filecount,
      sessionid: sessionid,
    }).then((response) => {
      if (response.data == 'Already Approved') {
        setrequeststatus('Failed');
        setsubmitdraftstatus(
          'This request is already approved/rejected. Close this to refresh your page',
        );
        setSuccessData2(true);
      } else {
        setrequeststatus('Saved');
        setsubmitdraftstatus('Request Saved');
        setSuccessData(true);
      }
    });
  };

  const handleApprove = () => {
    setShow9(false);
    setbuttondisabled(true);
    setisloading(true);
    Axios.post('/approval/api', {
      requestno: requestno,
      latitude: latitude,
      warehousecode: warehousecode,
      longitude: longitude,
      firstname: firstname,
      lastname: lastname,
      emailid: emailid,
      phoneno: phoneno,
      taxid: taxid,
      empid: empid,
      reqid: reqid,
      dbaname: dbaname,
      licenseename: licenseename,
      retailerphoneno: retailerphoneno,
      company: company,
      refcompany,
      actualcity: actualcity,
      warehouse: warehouse,
      street: street,
      city: city,
      state: state,
      zipcode: zipcode,
      county: county,
      faxno: faxno,
      buyer: buyer,
      licensestatus: licensestatus,
      licensenumber: licensenumber,
      licenseexpdat: licenseexpdat,
      ponumreq: ponumreq,
      chain: chain,
      chainstorenum: chainstorenum,
      chainindependentstatus: chainindependentstatus,
      seasonalclosedate: seasonalclosedate,
      businessopendate: businessopendate,
      seasonalopendate: seasonalopendate,
      retailerstatus: retailerstatus,
      sellingmethod: sellingmethod,
      packagetype: packagetype,
      draftstatus: draftstatus,
      performancepaystatus: performancepaystatus,
      displaystatus: displaystatus,
      allowablepos: allowablepos,
      draftconsumptiontype: draftconsumptiontype,
      retailerclass: retailerclass,
      civilianmilitary: civilianmilitary,
      industryvolume: industryvolume,
      patronethnicity: patronethnicity,
      patronage: patronage,
      patronoccupation: patronoccupation,
      patronresidence: patronresidence,
      contemporaryadulttype: contemporaryadulttype,
      ownerethnicityorlanguage: ownerethnicityorlanguage,
      markettype: markettype,
      onoffpremisetype: onoffpremisetype,
      packageconsumptiontype: packageconsumptiontype,
      flpgroup: flpgroup,
      pricinggroup: pricinggroup,
      pricingsubgroup: pricingsubgroup,
      deliveryscansheetrequired: deliveryscansheetrequired,
      callday: callday,
      salesroutetype: salesroutetype,
      salesrep: salesrep,
      salesroutesequence: salesroutesequence,
      salesroutefrequency: salesroutefrequency,
      supervisor: supervisor,
      saleseffectivedate: saleseffectivedate,
      deliveryday: deliveryday,
      deliveryroutetype: deliveryroutetype,
      deliveryroute: deliveryroute,
      deliveryroutesequence: deliveryroutesequence,
      deliveryroutefrequency: deliveryroutefrequency,
      deliveryeffectivedate: deliveryeffectivedate,
      openingtime: openingtime,
      closingtime: closingtime,
      deliverywindowstart: deliverywindowstart,
      deliverywindowend: deliverywindowend,
      deliverywindow2start: deliverywindow2start,
      deliverywindow2end: deliverywindow2end,
      additionalserviceinstruction: additionalserviceinstruction,
      whichwarehousewillthisretailerbeservicedoutof: whichwarehousewillthisretailerbeservicedoutof,
      planogrammablestatus: planogrammablestatus,
      arbillingcycle: arbillingcycle,
      territories: territories,
      approverfirstname: localStorage.getItem('firstName'),
      approverlastname: localStorage.getItem('lastName'),
      approverempid: localStorage.getItem('employeeID'),
      approveremailid: localStorage.getItem('username'),
      approvercompany: localStorage.getItem('company'),
      approverwarehouse: localStorage.getItem('warehouse'),
      dbaduplicatemarker: dbaduplicatemarker,
      filecount: filecount,
      sessionid: sessionid,
    }).then((response) => {
      console.log('Approval response', response);
      if (response.data == 'Retailer ID Exhausted') {
        setrequeststatus('Failed');
        setsubmitdraftstatus('Retailer ID Exhausted');
        setSuccessData2(true);
      } else if (response.data == 'JIRA API error') {
        setrequeststatus('Failed');
        setsubmitdraftstatus('JIRA API/SQL Error');
        setSuccessData2(true);
      } else if (response.data == 'Warehousecode undefined') {
        setrequeststatus('Failed');
        setsubmitdraftstatus('Warehousecode Undefined');
        setSuccessData2(true);
      } else if (response.data == 'Already Approved') {
        setrequeststatus('Failed');
        setsubmitdraftstatus(
          'This request is already approved/rejected. Close this to refresh your page',
        );
        setSuccessData2(true);
      } else {
        setretailerid(response.data);
        setrequeststatus('Approved');
        setsubmitdraftstatus(`Request No: ${requestno} is Approved, Retailer ID: `);
        setSuccessData4(true);
      }
    });
  };

  const handleReject = () => {
    setShow7(false);
    setbuttondisabled(true);
    setisloading(true);
    Axios.post('/reject/api', {
      requestno: requestno,
      latitude: latitude,
      longitude: longitude,
      firstname: firstname,
      lastname: lastname,
      emailid: emailid,
      phoneno: phoneno,
      taxid: taxid,
      empid: empid,
      reqid: reqid,
      dbaname: dbaname,
      licenseename: licenseename,
      company: company,
      refcompany,
      actualcity: actualcity,
      warehouse: warehouse,
      street: street,
      city: city,
      state: state,
      zipcode: zipcode,
      county: county,
      faxno: faxno,
      buyer: buyer,
      retailerphoneno: retailerphoneno,
      licensestatus: licensestatus,
      licensenumber: licensenumber,
      licenseexpdat: licenseexpdat,
      ponumreq: ponumreq,
      chain: chain,
      chainstorenum: chainstorenum,
      chainindependentstatus: chainindependentstatus,
      seasonalclosedate: seasonalclosedate,
      businessopendate,
      businessopendate,
      seasonalopendate: seasonalopendate,
      phoneno: phoneno,
      retailerstatus: retailerstatus,
      sellingmethod: sellingmethod,
      packagetype: packagetype,
      draftstatus: draftstatus,
      performancepaystatus: performancepaystatus,
      displaystatus: displaystatus,
      allowablepos: allowablepos,
      draftconsumptiontype: draftconsumptiontype,
      retailerclass: retailerclass,
      civilianmilitary: civilianmilitary,
      industryvolume: industryvolume,
      patronethnicity: patronethnicity,
      patronage: patronage,
      patronoccupation: patronoccupation,
      patronresidence: patronresidence,
      contemporaryadulttype: contemporaryadulttype,
      ownerethnicityorlanguage: ownerethnicityorlanguage,
      markettype: markettype,
      onoffpremisetype: onoffpremisetype,
      packageconsumptiontype: packageconsumptiontype,
      flpgroup: flpgroup,
      pricinggroup: pricinggroup,
      pricingsubgroup: pricingsubgroup,
      deliveryscansheetrequired: deliveryscansheetrequired,
      callday: callday,
      salesroutetype: salesroutetype,
      salesrep: salesrep,
      salesroutesequence: salesroutesequence,
      salesroutefrequency: salesroutefrequency,
      supervisor: supervisor,
      saleseffectivedate: saleseffectivedate,
      deliveryday: deliveryday,
      deliveryroutetype: deliveryroutetype,
      deliveryroute: deliveryroute,
      deliveryroutesequence: deliveryroutesequence,
      deliveryroutefrequency: deliveryroutefrequency,
      deliveryeffectivedate: deliveryeffectivedate,
      openingtime: openingtime,
      closingtime: closingtime,
      deliverywindowstart: deliverywindowstart,
      deliverywindowend: deliverywindowend,
      deliverywindow2start: deliverywindow2start,
      deliverywindow2end: deliverywindow2end,
      additionalserviceinstruction: additionalserviceinstruction,
      whichwarehousewillthisretailerbeservicedoutof: whichwarehousewillthisretailerbeservicedoutof,
      planogrammablestatus: planogrammablestatus,
      arbillingcycle: arbillingcycle,
      territories: territories,
      approverfirstname: localStorage.getItem('firstName'),
      approverlastname: localStorage.getItem('lastName'),
      approverempid: localStorage.getItem('employeeID'),
      approveremailid: localStorage.getItem('username'),
      approvercompany: localStorage.getItem('company'),
      approverwarehouse: localStorage.getItem('warehouse'),
      warehousecode: warehousecode,
      rejectionreason: rejectionreason,
      dbaduplicatemarker: dbaduplicatemarker,
      filecount: filecount,
      sessionid: sessionid,
    }).then((response) => {
      if (response.data == 'Already Approved') {
        setrequeststatus('Failed');
        setsubmitdraftstatus(
          'This request is already approved/rejected. Close this to refresh your page',
        );
        setSuccessData2(true);
      } else {
        setrequeststatus('Rejected');
        setsubmitdraftstatus('Request Rejected');
        setSuccessData(true);
      }
    });
  };

  function customTheme(theme) {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary25: '#e3af32',
      },
    };
  }

  const handleSellingMethod = (draftcontype, packcontype) => {
    setsellingmethod('');
    Axios.get(
      `https://acoeretailmdappprod1.azurewebsites.net/dropdown/sellingmethodlist?apikey=${apikey}&packageconsumptiontype=${packcontype}&draftconsumptiontype=${draftcontype}`,
    )
      .then(function (response) {
        // handle success
        console.log(response?.data);
        setsellingmethodlist(response?.data);
        console.log('This is complaint type');
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  const handlePackageType = (draftcontype, packcontype) => {
    setpackagetype('');
    Axios.get(
      `https://acoeretailmdappprod1.azurewebsites.net/dropdown/sellablepackagetypelist?apikey=${apikey}&packageconsumptiontype=${packcontype}&draftconsumptiontype=${draftcontype}`,
    )
      .then(function (response) {
        // handle success
        console.log(response?.data);
        setsellablepackagetypelist(response?.data);
        console.log('This is complaint type');
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  const handleComplete = (step) => {
    const newCompleted = completed;
    newCompleted[step] = true;
    setCompleted(newCompleted);
  };

  const handleRemoveComplete = (step) => {
    const newCompleted = completed;
    if (newCompleted[step]) {
      delete newCompleted[step];
      setCompleted(newCompleted);
    }
  };

  const handleValidateStepper = () => {
    console.log('Errors:', errors);
    if (
      dbaname != '' &&
      street != '' &&
      watch('city') != '' &&
      city != '' &&
      state != '' &&
      zipcode != '' &&
      county != '' &&
      dbaduplicate == false &&
      retailerphoneno != null &&
      retailerphoneno != '' &&
      buyer != ''
    ) {
      if (
        licenseename != '' &&
        licensestatus != 'NON-LICENSED' &&
        licensestatus != 'NON LICENSED'
      ) {
        handleComplete(1);
        console.log('Failing final 1');
      } else if (
        licenseename != '' &&
        (licensestatus == 'NON-LICENSED' || licensestatus == 'NON LICENSED')
      ) {
        handleComplete(1);
        console.log('Failing final 2');
      } else if (
        licenseename == '' &&
        (licensestatus == 'NON-LICENSED' || licensestatus == 'NON LICENSED')
      ) {
        handleComplete(1);
        console.log('Failing final 3');
      } else if (licenseename == '' && licensestatus == '') {
        handleComplete(1);
        console.log('Failing final 4');
      } else if (
        licenseename == '' &&
        licensestatus != '' &&
        licensestatus != 'NON-LICENSED' &&
        licensestatus != 'NON LICENSED'
      ) {
        handleRemoveComplete(1);
        console.log('Failing final 5');
      } else if (
        licenseename != '' &&
        licensestatus != '' &&
        (licensestatus != 'NON-LICENSED' || licensestatus != 'NON LICENSED')
      ) {
        handleComplete(1);
        console.log('Failing final 6');
      }
    } else {
      handleRemoveComplete(1);
      console.log('Failing final 7');
    }

    if (licensestatus == 'NON-LICENSED' || licensestatus == 'NON LICENSED') {
      handleComplete(2);
      console.log('non-licensed complete');
    } else if (
      licensestatus != 'NON-LICENSED' &&
      licensestatus != 'NON LICENSED' &&
      licensestatus != '' &&
      licensenumber != '' &&
      licenseexpdat != '' &&
      licenseexpdat != null
    ) {
      handleComplete(2);
    } else {
      handleRemoveComplete(2);
    }

    if (
      draftconsumptiontype != '' &&
      retailerclass != '' &&
      civilianmilitary != '' &&
      industryvolume != '' &&
      patronethnicity != '' &&
      patronage != '' &&
      patronoccupation != '' &&
      patronresidence != '' &&
      contemporaryadulttype != '' &&
      ownerethnicityorlanguage != '' &&
      markettype != '' &&
      onoffpremisetype != '' &&
      packageconsumptiontype != ''
    ) {
      handleComplete(3);
    } else {
      handleRemoveComplete(3);
    }

    if (packageconsumptiontype == 'NOT-APPLICABLE' && draftconsumptiontype == 'NOT-APPLICABLE') {
      handleRemoveComplete(3);
    }
    if (packageconsumptiontype == 'NOT APPLICABLE' && draftconsumptiontype == 'NOT APPLICABLE') {
      handleRemoveComplete(3);
    }

    if (
      chainindependentstatus != '' &&
      chain != '' &&
      chainstorenum != '' &&
      chainstorenum != null &&
      retailerstatus != '' &&
      sellingmethod != '' &&
      packagetype != '' &&
      draftstatus != '' &&
      performancepaystatus != '' &&
      displaystatus != '' &&
      allowablepos != ''
    ) {
      handleComplete(4);
    } else if (
      chainindependentstatus == 'Independent' &&
      retailerstatus != '' &&
      sellingmethod != '' &&
      packagetype != '' &&
      draftstatus != '' &&
      performancepaystatus != '' &&
      displaystatus != '' &&
      allowablepos != ''
    ) {
      handleComplete(4);
    } else {
      handleRemoveComplete(4);
    }

    if (localStorage.getItem('role') == 'Sales_Representative') {
      handleComplete(5);
    } else {
      if (
        (flpgroup === '' && (localStorage.getItem('role') === 'Sales_Manager' || localStorage.getItem('warehouse') === 'OKLAHOMA CITY [REGULAR]' || localStorage.getItem('warehouse') === 'TULSA')) ||
        ((company !== 'AUGUST A. BUSCH BOSTON' && warehouse !== 'AUGUST A. BUSCH BOSTON') &&
          (company !== 'ANHEUSER BUSCH SALES OF CANTON' && warehouse !== 'ANHEUSER BUSCH SALES OF CANTON') &&
          pricinggroup === '') || (pricinggroup !== '' && pricingsubgroup === '' && ( localStorage.getItem('warehouse') === 'OKLAHOMA CITY [REGULAR]' || localStorage.getItem('warehouse') === 'TULSA'))
      ) {
        handleRemoveComplete(5);
      }
      else handleComplete(5);
    }
    // || (pricinggroup != '' && company != 'AUGUST A. BUSCH BOSTON' && warehouse != 'AUGUST A. BUSCH BOSTON')
    if (
      callday != '' &&
      // deliveryday != '' &&
      (deliveryday !== '' || (deliveryday === '' && (warehouse === 'OKLAHOMA CITY [REGULAR]' || warehouse === 'TULSA'))) &&
      salesroutefrequency == '' &&
      deliveryroutefrequency == '' &&
      salesrep != '' &&
      supervisor != ''
    ) {
      handleComplete(6);
      console.log('completed step 1');
    } else if (
      callday != '' &&
      // deliveryday != '' &&
      (deliveryday !== '' || (deliveryday === '' && (warehouse === 'OKLAHOMA CITY [REGULAR]' || warehouse === 'TULSA'))) &&
      salesroutetype != '' &&
      salesrep != '' &&
      salesroutefrequency != '' &&
      salesrep != '' &&
      supervisor != ''
    ) {
      if (
        salesroutefrequency != 'EVERY WEEK' &&
        (saleseffectivedate == '' || saleseffectivedate == null)
      ) {
        handleRemoveComplete(6);
        console.log('rejected step 2');
      } else {
        handleComplete(6);
        console.log('completed step 3');
      }
    } else {
      handleRemoveComplete(6);
      console.log('rejected step 4');
    }

    if (
      callday != '' &&
      // deliveryday != '' &&
      (deliveryday !== '' || (deliveryday === '' && (warehouse === 'OKLAHOMA CITY [REGULAR]' || warehouse === 'TULSA'))) &&
      salesroutetype != '' &&
      salesrep != '' &&
      salesroutefrequency != '' &&
      salesrep != '' &&
      supervisor != '' &&
      salesroutefrequency != 'EVERY WEEK' &&
      saleseffectivedate != '' &&
      saleseffectivedate != null
    ) {
      handleComplete(6);
      console.log('completed step 5');
    }

    if (
      (callday != '' || (deliveryday !== '' || (deliveryday === '' && (warehouse === 'OKLAHOMA CITY [REGULAR]' || warehouse === 'TULSA')))) &&
      salesroutefrequency != 'EVERY WEEK' &&
      (saleseffectivedate == '' || saleseffectivedate == null)
    ) {
      handleRemoveComplete(6);
      console.log('rejected step 7');
    }

    if (deliveryroute == '' && (localStorage.getItem('role') == 'Sales_Manager' &&  (localStorage.getItem('warehouse') !== 'OKLAHOMA CITY [REGULAR]' && localStorage.getItem('warehouse') !== 'TULSA'))) {
      handleRemoveComplete(6);
    }

    console.log('Finalopen time', String(openingtime));
    console.log('Finalclose time', String(closingtime));

    // openingtime != closingtime &&
    // String(closingtime) > String(openingtime) &&
    if (
      latitude != '' &&
      longitude != '' &&
      deliverywindowstart != deliverywindowend &&
      String(deliverywindowend) > String(deliverywindowstart) &&
      deliverywindow2start != deliverywindow2end &&
      String(deliverywindow2end) > String(deliverywindow2start)
    ) {
      handleComplete(7);
    }

    if (arbillingcycle == '' && localStorage.getItem('role') == 'Sales_Manager') {
      handleRemoveComplete(8);
    } else {
      handleComplete(8);
    }

    handleComplete(9);
  };

  const returnFunctionStart = (event) => {
    handleValidateStepper();
    console.log(event);
    setopeningtime(event.startTime);
    setValue('openingtime', event.startTime);
    console.log('openingtime:', event.startTime);
  };

  const returnFunctionEnd = (event) => {
    handleValidateStepper();
    console.log(event);
    setclosingtime(event.endTime);
    setValue('closingtime', event.endTime);
    console.log('closingtime:', event.endTime);
  };

  const returnFunctionStart2 = (event) => {
    handleValidateStepper();
    console.log(event);
    setdeliverywindowstart(event.startTime);
    setValue('deliverywindowstart', event.startTime);
  };

  const returnFunctionEnd2 = (event) => {
    handleValidateStepper();
    console.log(event);
    setdeliverywindowend(event.endTime);
    setValue('deliverywindowend', event.endTime);
  };

  const returnFunctionStart3 = (event) => {
    handleValidateStepper();
    console.log(event);
    setdeliverywindow2start(event.startTime);
    setValue('deliverywindow2start', event.startTime);
  };

  const returnFunctionEnd3 = (event) => {
    handleValidateStepper();
    console.log(event);
    setdeliverywindow2end(event.endTime);
    setValue('deliverywindow2end', event.endTime);
  };

  const handleOnChange3 = () => {
    setponumreq(!ponumreq);
  };

  const handleDiscard = () => {
    setShow8(false);
    setbuttondisabled(true);
    setisloading(true);
    Axios.post('/discarddraft/api', {
      requestno: requestno,
      removeremailid: localStorage.getItem('username'),
      removerempid: localStorage.getItem('employeeID'),
    }).then((response) => {
      setrequeststatus('Deleted');
      setsubmitdraftstatus('Draft delete');
      setSuccessData3(true);
    });
  };

  const handleSubmitForm = () => {
    setdel(1);
    setbuttondisabled(true);
    setisloading(true);
    Axios.post('/submitform/api/aftersave', {
      requestno: requestno,
      latitude: latitude,
      longitude: longitude,
      firstname: firstname,
      lastname: lastname,
      emailid: emailid,
      phoneno: phoneno,
      taxid: taxid,
      empid: empid,
      reqid: reqid,
      dbaname: dbaname,
      licenseename: licenseename,
      retailerphoneno: retailerphoneno,
      company: company,
      refcompany,
      actualcity: actualcity,
      warehouse: warehouse,
      street: street,
      city: city,
      state: state,
      zipcode: zipcode,
      county: county,
      faxno: faxno,
      buyer: buyer,
      licensestatus: licensestatus,
      licensenumber: licensenumber,
      licenseexpdat: licenseexpdat,
      ponumreq: ponumreq,
      chain: chain,
      chainstorenum: chainstorenum,
      chainindependentstatus: chainindependentstatus,
      seasonalclosedate: seasonalclosedate,
      businessopendate,
      businessopendate,
      seasonalopendate: seasonalopendate,
      phoneno: phoneno,
      retailerstatus: retailerstatus,
      sellingmethod: sellingmethod,
      packagetype: packagetype,
      draftstatus: draftstatus,
      performancepaystatus: performancepaystatus,
      displaystatus: displaystatus,
      allowablepos: allowablepos,
      draftconsumptiontype: draftconsumptiontype,
      retailerclass: retailerclass,
      civilianmilitary: civilianmilitary,
      industryvolume: industryvolume,
      patronethnicity: patronethnicity,
      patronage: patronage,
      patronoccupation: patronoccupation,
      patronresidence: patronresidence,
      contemporaryadulttype: contemporaryadulttype,
      ownerethnicityorlanguage: ownerethnicityorlanguage,
      markettype: markettype,
      onoffpremisetype: onoffpremisetype,
      packageconsumptiontype: packageconsumptiontype,
      flpgroup: flpgroup,
      pricinggroup: pricinggroup,
      pricingsubgroup: pricingsubgroup,
      deliveryscansheetrequired: deliveryscansheetrequired,
      callday: callday,
      salesroutetype: salesroutetype,
      salesrep: salesrep,
      salesroutesequence: salesroutesequence,
      salesroutefrequency: salesroutefrequency,
      supervisor: supervisor,
      saleseffectivedate: saleseffectivedate,
      deliveryday: deliveryday,
      deliveryroutetype: deliveryroutetype,
      deliveryroute: deliveryroute,
      deliveryroutesequence: deliveryroutesequence,
      deliveryroutefrequency: deliveryroutefrequency,
      deliveryeffectivedate: deliveryeffectivedate,
      openingtime: openingtime,
      closingtime: closingtime,
      deliverywindowstart: deliverywindowstart,
      deliverywindowend: deliverywindowend,
      deliverywindow2start: deliverywindow2start,
      deliverywindow2end: deliverywindow2end,
      additionalserviceinstruction: additionalserviceinstruction,
      whichwarehousewillthisretailerbeservicedoutof: whichwarehousewillthisretailerbeservicedoutof,
      planogrammablestatus: planogrammablestatus,
      arbillingcycle: arbillingcycle,
      territories: territories,
      saveemailid: localStorage.getItem('username'),
      saveempid: localStorage.getItem('employeeID'),
      dbaduplicatemarker: dbaduplicatemarker,
      filecount: filecount,
      sessionid: sessionid,
    }).then((response) => {
      if (response.data == 'Already Approved') {
        setrequeststatus('Failed');
        setsubmitdraftstatus(
          'This request is already approved/rejected. Close this to refresh your page',
        );
        setSuccessData2(true);
      } else {
        setrequeststatus('Submitted');
        setsubmitdraftstatus('Request Submitted');
        setSuccessData(true);
      }
    });
  };

  const handleCompanyChange = (value) => {
    //   new Promise(function(resolve, reject) {
    //     Axios.get(`/userDetails?apikey=${apikey}&email=${localStorage.getItem('username')}`).then(function (response) {
    //       // handle success
    //       console.log(response?.data);
    //       setcompanylist(response?.data);
    //       console.log("This is complaint type")
    //       })
    //       .catch(function (error) {
    //       // handle error
    //       console.log(error);
    //       })
    //     setTimeout(() => resolve(1), 500); // (*)
    // }).then(function(result) {
    // return new Promise((resolve, reject) => {
    //     setTimeout(() => resolve(result * 2), 500);
    //     console.log(refcompany.current)
    //     if(refcompany.current == 'AB Boston'){
    //       setactualcity('Boston')
    //       console.log(refcompany.current)
    //     }
    //     else if(refcompany.current == 'AB Bronx'){
    //       setactualcity('Bronx')
    //     }
    //     else if(refcompany.current == 'AB Canton'){
    //       setactualcity('Canton')
    //     }
    //     else if(refcompany.current == 'AB Lima'){
    //       setactualcity('Lima')
    //     }
    //     else if(refcompany.current == 'AB Los Angeles'){
    //       setactualcity('Los Angeles')
    //     }
    //     else if(refcompany.current == 'AB Oakland'){
    //       setactualcity('Oakland')
    //     }
    //     else if(refcompany.current == 'AB TULSA'){
    //       setactualcity('TULSA')
    //     }
    //     else if(refcompany.current == 'AB Washington'){
    //       setactualcity('Washington')
    //     }
    //     else if(refcompany.current == 'AB Western'){
    //       setactualcity('Western')
    //     }
    // })
    // }).then(function(result) {
    //   return new Promise((resolve, reject) => {
    //       setTimeout(() => resolve(result * 2), 500);
    //       console.log("City",refactualcity.current)
    //     Axios.get(`https://acoeretailmdappprod1.azurewebsites.net/dropdown/warehouselist?apikey=${apikey}&actualcity=${refactualcity.current}`).then(function (response) {
    //       // handle success
    //       console.log(response?.data);
    //       setwarehouselist(response?.data);
    //       console.log("This is complaint type")
    // Test12345
    //       })
    //       .catch(function (error) {
    //       // handle error
    //       console.log(error);
    //       })
    //   })
    //   })
  };

  async function hideAlert() {
    window.location.reload();
  }

  async function hideAlert2() {
    history.push(`/`);
  }

  const handleStep = (step) => () => {
    if (step != 8) {
      if (fileslist?.length > 0) {
        setacceptedfiles(fileslist);
      }
    }
    handleValidateStepper();
    setActiveStep(step);
  };

  const validationSchema = Yup.object().shape({
    company: Yup.string().required('Company is required'),

    warehouse: Yup.string().required('Warehouse is required'),

    licensestatusvalid: Yup.string().required('License Status is required'),

    dbaname: Yup.string()
      // .when('company', {
      //   is: (company) => dbaduplicate == true && dbaname != '',
      //   then: Yup.string()
      //   .min(100, "DBA Name already exists")

      // })
      .required('DBA Name is required'),

    streetaddress: Yup.string().required('Street Address is required'),

    city: Yup.string().required('City is required'),

    state: Yup.string().required('State is required'),

    zipcode: Yup.string().required('Zip code is required').nullable(),

    county: Yup.string().required('County is required'),

    chainindependentstatusvalid: Yup.string().required('Chain/Independent Status is required'),

    markettype: Yup.string().required('Market Type is required'),

    onoffpremisetype: Yup.string().required('On/Off Premise Type is required'),

    city: Yup.string().required('City is required'),

    retailerphoneno: Yup.string().required('Retailer Phone Number is required'),

    buyer: Yup.string().required('Buyer is required'),

    retailerclass: Yup.string().required('Retailer Class is required'),

    civilianmilitary: Yup.string().required('Civilian/Military is required'),

    industryvolume: Yup.string().required('Industry #1 Volume is required'),

    patronethnicity: Yup.string().required('Patron Ethnicity is required'),

    patronage: Yup.string().required('Patron Age is required'),

    patronoccupation: Yup.string().required('Patron Occupation is required'),

    patronresidence: Yup.string().required('Patron Residence is required'),

    contemporaryadulttype: Yup.string().required('Contemporary Adult Type is required'),

    ownerethnicityorlanguage: Yup.string().required('Owner Ethnicity or Language is required'),

    performancepaystatus: Yup.string().required('Performance Pay Status is required'),

    displaystatus: Yup.string().required('Display Status is required'),

    flpgroup: Yup.string().when([], {
      is: () => localStorage.getItem('role') !== 'Sales_Manager' && (localStorage.getItem('warehouse') !== 'OKLAHOMA CITY [REGULAR]' && localStorage.getItem('warehouse') !== 'TULSA'),
      then: Yup.string(),
      otherwise: Yup.string().required('FLP Group is required'),
    }),

    // pricinggroup: Yup.string().when(['company', 'warehouse'], {
    //   is: (company, warehouse) =>
    //     company === 'AUGUST A. BUSCH BOSTON' && warehouse === 'AUGUST A. BUSCH BOSTON',
    //   then: Yup.string().notRequired(),
    //   otherwise: Yup.string().required('Pricing Group is required'),
    // }),
    pricinggroup: Yup.string().when(['company', 'warehouse'], {
      is: (company, warehouse) => {
        // console.log('company:', company);
        // console.log('warehouse:', warehouse);
        const condition =
          (company !== 'AUGUST A. BUSCH BOSTON' && warehouse !== 'AUGUST A. BUSCH BOSTON') &&
          (company !== 'ANHEUSER BUSCH SALES OF CANTON' && warehouse !== 'ANHEUSER BUSCH SALES OF CANTON') && localStorage.getItem('role') !== 'Sales_Representative' || (localStorage.getItem('warehouse') === 'OKLAHOMA CITY [REGULAR]' || localStorage.getItem('warehouse') === 'TULSA');
        // console.log('condition:', condition);
        return condition;
      },
      then: Yup.string().required('Pricing Group is required'),
      otherwise: Yup.string(),
    }),
    pricingsubgroup: Yup.string().when('pricinggroupvalid',{
      is:(pricinggroupvalid) => pricinggroup !== '' && pricingsubgroup === '' &&(localStorage.getItem('warehouse') === 'OKLAHOMA CITY [REGULAR]' || localStorage.getItem('warehouse') === 'TULSA'),
      then: Yup.string().required('Pricing Sub Group is required'),
      otherwise:Yup.string()
    }),
    // localStorage.getItem('role') != 'Sales_Manager' ||

    // existingItem: Yup.string().required('Existing Item is required'),
    // existingItemNumber: Yup.number().when('existingItem', {
    //   is: (existingItem) => existingItem == 'Yes',
    //   then: Yup.number().required('Existing Item Number is required'),
    //   otherwise: Yup.number().notRequired(),
    // }),



    // pricinggroup: Yup.string()
    // .required('Pricing Group is required'),

    // arbillingcycle: Yup.string()
    // .required('AR Billing Cycle is required'),

    licenseename: Yup.string().when('licensestatusvalid', {
      is: (licensestatusvalid) =>
        licensestatus != 'NON-LICENSED' && licensestatus != 'NON LICENSED',
      then: Yup.string().required('Licensee Name is required'),
    }),

    retailerstatus: Yup.string().required('Retailer Status is required'),

    packageconsumptiontype: Yup.string()
      .when('draftconsumptiontypevalid', {
        is: (draftconsumptiontypevalid) => botharesame == true,
        then: Yup.string().min(
          20,
          'Both Package & Draft Consumption Type cannot be Not Applicable',
        ),
      })
      .required('Package Consumption type is required'),

    draftconsumptiontypevalid: Yup.string().required('Draft Consumption type is required'),

    sellingmethod: Yup.string().required('Selling Method is required'),

    packagetype: Yup.string().required('Package Type is required'),

    draftstatus: Yup.string().required('Draft Status is required'),

    licensenumber: Yup.string().when('licensestatusvalid', {
      is: (licensestatusvalid) =>
        licensestatus != 'NON-LICENSED' && licensestatus != 'NON LICENSED',
      then: Yup.string().required('License Number is required'),
    }),

    licenseexpdat: Yup.string()
      .when('licensestatusvalid', {
        is: (licensestatusvalid) =>
          licensestatus != 'NON-LICENSED' && licensestatus != 'NON LICENSED',
        then: Yup.string().required('License Expiration Date is required').nullable(),
      })
      .nullable(),

    chain: Yup.string().when('chainindependentstatusvalid', {
      is: (ttytytyytty) => chainindependentstatus != 'Independent',
      then: Yup.string().required('Chain is required'),
    }),

    chainstorenum: Yup.string()
      .when('chainindependentstatusvalid', {
        is: (fgfgfgfghuy) => chainindependentstatus != 'Independent',
        then: Yup.string().required('Chain Store Number is required').nullable(),
      })
      .nullable(),

    calldayvalid: Yup.array(
      Yup.object({
        label: Yup.string(),
        value: Yup.string(),
      }),
    )
      .required('Call Day is required')
      .min(1, 'Call Day is required')
      .nullable(),

      deliverydayvalid: Yup.array(
        Yup.object({
          label: Yup.string(),
          value: Yup.string(),
        })
      ).when('warehouse', {
        is: (warehouse) => warehouse !== 'OKLAHOMA CITY [REGULAR]' && warehouse !== 'TULSA',
        then: Yup.array()
          .required('Delivery Day is required')
          .min(1, 'Delivery Day is required')
          .nullable(),
        otherwise: Yup.array().nullable(),
      }),

    salesroutetype: Yup.string().when('calldayvalid', {
      is: (calldayvalid) => callday.length > 0,
      then: Yup.string().required('Sales Route Type is required'),
    }),

    salesrep: Yup.string().when('calldayvalid', {
      is: (calldayvalid) => callday.length > 0,
      then: Yup.string().required('Sales Representative is required'),
    }),

    salesroutefrequency: Yup.string().when('calldayvalid', {
      is: (calldayvalid) => callday.length > 0,
      then: Yup.string().required('Sales Route Frequency is required'),
    }),

    deliveryroutetype: Yup.string().when('calldayvalid', {
      is: (calldayvalid) => callday.length > 0,
      then: Yup.string(),
      // .required('Delivery Route Type is required')
    }),

    deliveryroute: Yup.string().when('calldayvalid', {
      is: (calldayvalid) => callday.length > 0 && localStorage.getItem('role') == 'Sales_Manager' && (localStorage.getItem('warehouse') !== 'OKLAHOMA CITY [REGULAR]' && localStorage.getItem('warehouse') !== 'TULSA'),
      then: Yup.string().required('Delivery Route Type is required'),
    }),

    deliveryroutefrequency: Yup.string().when('calldayvalid', {
      is: (calldayvalid) => callday.length > 0,
      then: Yup.string(),
      // .required('Delivery Route Frequency is required')
    }),

    saleseffectivedate: Yup.string()
      .when('salesroutefrequency', {
        is: (salesroutefrequency123) => salesroutefrequency != 'EVERY WEEK',
        then: Yup.string().required('Sales Effective Date is required').nullable(),
      })
      .nullable(),

    deliveryeffectivedate: Yup.string()
      .when('deliveryroutefrequency', {
        is: (deliveryroutefrequency123) => deliveryroutefrequency == 'EVERY WEEK',
        then: Yup.string()
          // .required('Delivery Effective Date is required')
          .nullable(),
      })
      .nullable(),

    openingtime: Yup.string().nullable().required('Opening time required'),

    // closingtime: Yup.string()
    //   .when('dbaname', {
    //     is: (dbaname) => openingtime == closingtime || String(closingtime) < String(openingtime),
    //     then: Yup.string()
    //       .min(100, 'Closing Time needs to be greater than opening time')
    //       .nullable(),
    //   })
    //   .nullable()
    //   .required('Closing time required'),

    closingtime: Yup.string().required('Closing time required'),

    deliverywindowstart: Yup.string().nullable().required('Delivery Window Start required'),

    deliverywindowend: Yup.string()
      .when('dbaname', {
        is: (dbaname) =>
          deliverywindowstart == deliverywindowend ||
          String(deliverywindowend) < String(deliverywindowstart),
        then: Yup.string()
          .min(100, 'Delivery Window End needs to be greater than Delivery Window Start')
          .nullable(),
      })
      .nullable()
      .required('Delivery Window End is required'),

    deliverywindow2start: Yup.string().nullable().required('Delivery Window 2 Start required'),

    deliverywindow2end: Yup.string()
      .when('dbaname', {
        is: (dbaname) =>
          deliverywindow2start == deliverywindow2end ||
          String(deliverywindow2end) < String(deliverywindow2start),
        then: Yup.string()
          .min(100, 'Delivery Window 2 End needs to be greater than Delivery Window 2 Start')
          .nullable(),
      })
      .nullable()
      .required('Delivery Window 2 End required'),

    supervisor: Yup.string().required('Supervisor is required'),


    planogrammablestatus: Yup.string().nullable().required('Planogrammable Status required'),

    allowablepos: Yup.string().required('Allowable POS is required'),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, watch, setValue, handleSubmit, reset, formState } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const { errors } = formState;

  const handleShow7 = () => setShow7(true);

  const handleClose7 = () => {
    setShow7(false);
  };
  const [show7, setShow7] = useState(false);

  const handleShow8 = () => setShow8(true);

  const handleClose8 = () => {
    setShow8(false);
  };
  const [show8, setShow8] = useState(false);

  const handleShow9 = () => setShow9(true);

  const handleClose9 = () => {
    setShow9(false);
  };
  const [show9, setShow9] = useState(false);

  const handleStreetAddress = (val, callback) => {
    setsearchparam(val);
    console.log(val);

    if (val.length >= 3)
      Axios.get(
        `https://atlas.microsoft.com/search/address/json?/typeahead=true&subscription-key=4N794oTImZXDvQ4OPz58pziQiMH5wZNBL2pIFCt_XRo&api-version=1.0&countrySet=US&language=en-US&query=${val}`,
      )
        .then(function (response) {
          // handle success
          console.log('Location:', response?.data);
          var arr = [];
          for (var i = 0; i < response.data.results.length; i++) {
            // var streetnum = ''
            // var streetname = ''
            // var labelfinal = ''
            // var municipality = ''
            // var countrysubdiv = ''
            // var postal = ''
            // if(response.data.results[i].address.streetNumber != undefined){
            //   labelfinal += `${response.data.results[i].address.streetNumber}, `
            // console.log(`${response.data.results[i].address.streetNumber}, `)
            // }
            // if(response.data.results[i].address.streeetName != undefined){
            //   labelfinal += `${response.data.results[i].address.streetName}`
            // }
            // if(response.data.results[i].address.municipality != undefined){
            //   municipality = `${response.data.results[i].address.municipaliCORty}`
            // }
            // if(response.data.results[i].addresscountrySubdivision != undefined){
            //   countrysubdiv = `${response.data.results[i].address.countrySubdivision}`
            // }
            // if(response.data.results[i].address.postalCode != undefined){
            //   postal = `${response.data.results[i].address.postalCode.substring(0, 5)}`
            // }
            //   console.log("LabelFinal:",labelfinal)
            if (
              response.data.results[i].type == 'Street' &&
              response.data.results[i].address.postalCode &&
              response.data.results[i].address.municipality &&
              response.data.results[i].address.countrySubdivision
            ) {
              arr.push({
                label: `${response.data.results[i].address.freeformAddress}`,
                value: `${response.data.results[i].address.freeformAddress}`,
                street:
                  (response.data.results[i].address.streetNumber
                    ? response.data.results[i].address.streetNumber + ' '
                    : '') + (response.data.results[i].address.streetName || ''),
                city: `${response.data.results[i].address.municipality}`,
                state: `${response.data.results[i].address.countrySubdivision}`,
                postcode: `${response.data.results[i].address.postalCode}`,
                latitude: `${response.data.results[i].position.lat}`,
                longitude: `${response.data.results[i].position.lng}`,
              });
              console.log(arr);
              // console.log(labelfinal)
              console.log(streetlist);
            }
          }
          console.log(arr);
          return callback(arr);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
  };

  function Downloadfile(e) {
    console.log(e.target.outerText);
    Axios({
      url: '/download2/files',
      method: 'POST',
      responseType: 'arraybuffer', // Important
      data: {
        reqno: requestno,
        filename: e.target.outerText,
      },
    }).then((response) => {
      console.log(response);
      // const type = response?.headers['content-type']
      // console.log(type)
      // const blob = new Blob([response?.data], { type: type, encoding: 'UTF-8' })
      // console.log(blob)
      // const link = document.createElement('a')
      // link.href = window.URL.createObjectURL(blob)
      // link.download = 'Testfile.pptx'
      // link.click()
      FileDownload(response?.data, `${e.target.outerText}`);
    });
  }

  useEffect(() => {
    Axios.post(`/download/files`, { reqno: reqno }).then(function (response) {
      console.log('Attachments:', response?.data);
      setattachments(response?.data);
    });
  }, []);

  const calldayoptions = [
    { value: 'Sunday', label: 'Sunday' },
    { value: 'Monday', label: 'Monday' },
    { value: 'Tuesday', label: 'Tuesday' },
    { value: 'Wednesday', label: 'Wednesday' },
    { value: 'Thursday', label: 'Thursday' },
    { value: 'Friday', label: 'Friday' },
    { value: 'Saturday', label: 'Saturday' },
  ];

  const draftconsumptiontypeoptiondisabled = (option) => {
    if (packageconsumptiontype == 'NOT APPLICABLE' || packageconsumptiontype == 'NOT-APPLICABLE') {
      if (option.value == 'NOT APPLICABLE' || option.value == 'NOT-APPLICABLE') {
        return true;
      }
    }
  };

  const packageconsumptiontypeoptiondisabled = (option) => {
    if (draftconsumptiontype == 'NOT APPLICABLE' || draftconsumptiontype == 'NOT-APPLICABLE') {
      if (option.value == 'NOT APPLICABLE' || option.value == 'NOT-APPLICABLE') {
        return true;
      }
    }
  };

  if (
    localStorage.getItem('role')?.length == 0 ||
    localStorage.getItem('role') == 'Employee' ||
    reqnocheckval == false
  )
    return (
      <div>
        <NavbarComponent />
        <div>You are not authorized to view this Request</div>
      </div>
    );

  return (
    <div>
      <NavbarComponent />
      <Container style={{ paddingLeft: '8vw', paddingRight: '8vw' }}>
        <div>
          <h1 className="page-title complaints-title page-header">
            {requestno} - {status}
          </h1>
        </div>
        <Form onSubmit={handleSubmit(handleSubmitForm)}>
          <>
            {status === 'Draft' && localStorage.getItem('role') === 'Sales_Representative' && (
              <Button
                style={{ float: 'left' }}
                disabled={
                  dbaname == '' || buttondisabled || dbaduplicate == true || status == 'Approved'
                }
                onClick={handleSaveDraft}
              >
                Save Draft
              </Button>
            )}
            {status === 'Submitted' && localStorage.getItem('role') === 'Sales_Manager' && (
              <Button
                style={{ float: 'left' }}
                disabled={dbaname == '' || buttondisabled || status == 'Approved'}
                onClick={handleSalesManagerSave}
              >
                Save
              </Button>
            )}
            {(status === 'Draft' || status === 'Submitted' || status === 'Rejected') &&
              (localStorage.getItem('role') === 'Sales_Representative' ||
                localStorage.getItem('role') === 'Sales_Manager') && (
                <Button
                  className="text-center btn btn-success"
                  type="submit"
                  disabled={buttondisabled || status == 'Approved'}
                >
                  Submit Form{' '}
                </Button>
              )}{' '}
            &nbsp;
            {status === 'Submitted' && localStorage.getItem('role') === 'Sales_Manager' && (
              <Button
                className="text-center btn btn-success"
                disabled={
                  buttondisabled ||
                  status == 'Approved' ||
                  flpgroup == '' ||
                  ((company !== 'AUGUST A. BUSCH BOSTON' && warehouse !== 'AUGUST A. BUSCH BOSTON') &&
                    (company !== 'ANHEUSER BUSCH SALES OF CANTON' && warehouse !== 'ANHEUSER BUSCH SALES OF CANTON') &&
                    pricinggroup === '') ||
                  arbillingcycle == '' ||
                  ((company !== 'TULSA' && warehouse !== 'TULSA') &&
                    (company !== 'OKLAHOMA CITY [REGULAR]' && warehouse !== 'OKLAHOMA CITY [REGULAR]') &&
                    deliveryroute === '') ||
                  (pricinggroup !== '' && pricingsubgroup === '' &&(localStorage.getItem('warehouse') === 'OKLAHOMA CITY [REGULAR]' || localStorage.getItem('warehouse') === 'TULSA'))
                }
                onClick={(e) => {
                  setShow9(true);
                }}
              >
                Save & Approve
              </Button>
            )}
            {status === 'Submitted' && localStorage.getItem('role') === 'Sales_Manager' && (
              <Button
                className="btn btn-danger"
                disabled={buttondisabled || status == 'Approved'}
                onClick={handleShow7}
                style={{ float: 'right' }}
              >
                Reject
              </Button>
            )}
            {status === 'Draft' &&
              (localStorage.getItem('role') === 'Sales_Representative' ||
                localStorage.getItem('role') === 'Sales_Manager') && (
                <Button
                  className="btn btn-danger"
                  disabled={buttondisabled}
                  onClick={handleShow8}
                  style={{ float: 'right' }}
                >
                  Delete
                </Button>
              )}
            {isloading === false &&
              (localStorage.getItem('role') === 'Sales_Representative' ||
                localStorage.getItem('role') === 'Sales_Manager') && (
                <div className="text-center">Note: Please fill all mandatory fields to submit</div>
              )}
            {isloading === true && (
              <div>
                <ReactBootstrap.Spinner className="text-center" animation="border" />
              </div>
            )}
            <hr />
            {/* <div className="row d-flex justify-content-center mt-3 mb-5 pb-5">
                <div className="col-6">
                    <div class="card">
                        <div class="card-header text-left font-weight-bold d-flex">
                            <div className="inline-block mr-auto pt-1">
                            {location.successful === true && (
                    <>      
                    User has granted location access                             
                    </>                                        
                    )}
                    {location.isnotyetgranted === true && (
                    <>      
                    User has not yet granted access to location                               
                    </>                                        
                    )}
                    {location.errorpresent === true && (
                    <>      
                    location.error?.message                            
                    </>                                        
                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <Modal show={show7} onHide={handleClose7} backdrop="static" keyboard={false}>
              <Modal.Header closeButton>
                <Modal.Title>Reject Request</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label className="form-label">Rejection Reason</label>
                        <textarea
                          id="add-comment-follow-up"
                          value={rejectionreason}
                          disabled={
                            localStorage.getItem('role') == 'Sales_Representative' ||
                            localStorage.getItem('role') == 'MDM_Team' ||
                            status == 'Approved'
                          }
                          onChange={(e) => setrejectionreason(e.target.value)}
                          className="form-control"
                          rows="3"
                          placeholder="Enter comment"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose7}>
                  Cancel
                </Button>
                <Button variant="danger" onClick={handleReject}>
                  Reject
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={show8} onHide={handleClose8} backdrop="static" keyboard={false}>
              <Modal.Header closeButton>
                <Modal.Title>Delete Draft</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">Are you sure you want to delete this draft?</div>
                    </div>
                  </div>
                </form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose8}>
                  Cancel
                </Button>
                <Button variant="danger" onClick={handleDiscard}>
                  Discard
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={show9} onHide={handleClose9} backdrop="static" keyboard={false}>
              <Modal.Header closeButton>
                <Modal.Title>Approve Request</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        Are you sure you want to approve this request? This action is irreversible.
                      </div>
                    </div>
                  </div>
                </form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose9}>
                  Cancel
                </Button>
                <Button variant="success" onClick={handleApprove}>
                  Approve
                </Button>
              </Modal.Footer>
            </Modal>
            <Box sx={{ width: '100%' }}>
              {isMobile === 'vertical' && (
                <Paper
                  square
                  elevation={0}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: 50,
                    pl: 2,
                    bgcolor: 'background.default',
                  }}
                >
                  <Typography>{steps[activeStep]}</Typography>
                </Paper>
              )}
              {isMobile === 'horizontal' && (
                <Stepper nonLinear activeStep={activeStep} orientation={isMobile}>
                  {steps.map((label, index) => (
                    <Step
                      key={label}
                      sx={{
                        '& .MuiStepLabel-root .Mui-completed': {
                          color: 'success.light', // circle color (COMPLETED)
                        },
                        '&  .MuiStepIcon-root': {
                          color: 'error.main', // circle color (COMPLETED)
                        },
                        '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel': {
                          color: 'success.light', // Just text label (COMPLETED)
                        },
                        '& .MuiStepLabel-root .Mui-active': {
                          color: 'primary.main', // circle color (ACTIVE)
                        },
                        '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                          fill: 'white', // circle's number (ACTIVE)
                        },
                        '& .Mui-completed': {
                          fill: 'success.light', // circle's number (ACTIVE)
                        },
                      }}
                      completed={completed[index]}
                    >
                      <StepButton color="inherit" onClick={handleStep(index)}>
                        {label}
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
              )}

              <React.Fragment>
                <div class="row justify-content-between text-left">
                  {isMobile === 'vertical' && (
                    <div class="col-3">
                      <Stepper nonLinear activeStep={activeStep} orientation={isMobile}>
                        {steps.map((label, index) => (
                          <Step
                            key={label}
                            sx={{
                              '& .MuiStepLabel-root .Mui-completed': {
                                color: 'success.light', // circle color (COMPLETED)
                              },
                              '&  .MuiStepIcon-root': {
                                color: 'error.main', // circle color (COMPLETED)
                              },
                              '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel': {
                                color: 'success.light', // Just text label (COMPLETED)
                              },
                              '& .MuiStepLabel-root .Mui-active': {
                                color: 'primary.main', // circle color (ACTIVE)
                              },
                              '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                                fill: 'white', // circle's number (ACTIVE)
                              },
                              '& .Mui-completed': {
                                fill: 'success.light', // circle's number (ACTIVE)
                              },
                            }}
                            completed={completed[index]}
                          >
                            <StepButton color="inherit" onClick={handleStep(index)}>
                              {label}
                            </StepButton>
                          </Step>
                        ))}
                      </Stepper>
                    </div>
                  )}
                  <div class={stepperclassname}>
                    {activeStep === 0 && (
                      <>
                        <div class="row justify-content-between text-left">
                          <div class="form-group col-sm-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              First Name
                              <span class="text-danger"> *</span>
                            </label>
                            <input
                              type="text"
                              id="email"
                              name="email"
                              placeholder=""
                              onblur="validate(3)"
                              value={firstname}
                              disabled={true}
                            />{' '}
                          </div>
                          <div class="form-group col-sm-6 flex-column d-flex">
                            <label class="form-control-label px-3">
                              Last Name<span class="text-danger"> *</span>
                            </label>
                            <input value={lastname} disabled={true} />
                          </div>
                        </div>
                        <div class="row justify-content-between text-left">
                          <div class="form-group col-sm-8 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Email Address
                              <span class="text-danger"> *</span>
                            </label>
                            <input
                              type="text"
                              id="email"
                              name="email"
                              placeholder=""
                              onblur="validate(3)"
                              value={emailid}
                              disabled={true}
                            />{' '}
                          </div>
                          <div class="form-group col-sm-4 col-md-6 flex-column d-flex">
                            <label class="form-control-label px-3">
                              Employee ID<span class="text-danger"> *</span>
                            </label>
                            <input value={empid} disabled={true} />
                          </div>
                        </div>
                        <div class="row justify-content-between text-left">
                          <div class="form-group col-sm-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Company
                              <span class="text-danger"> *</span>
                            </label>
                            <Select
                              class="form-control"
                              name="email"
                              placeholder=""
                              onblur="validate(3)"
                              isDisabled={true}
                              theme={customTheme}
                              value={{ label: `${company}`, value: `${company}` }}
                              options={companylist}
                              onChange={(value) => {
                                setcompany(value.value);
                                handleChangeCompany(value.value);
                              }}
                            >
                              <option value="">Select</option>
                              {companylist?.map((companylist) => (
                                <option value={companylist.Company_Description}>
                                  {companylist.Company_Description}
                                </option>
                              ))}
                            </Select>{' '}
                          </div>
                          <div class="form-group col-sm-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Warehouse
                              <span class="text-danger"> *</span>
                            </label>
                            <Select
                              class="form-control"
                              name="email"
                              isDisabled={localStorage.getItem('role') != 'Sales_Manager'}
                              placeholder=""
                              onblur="validate(3)"
                              theme={customTheme}
                              value={{ label: `${warehouse}`, value: `${warehouse}` }}
                              options={warehouselist}
                              onChange={(value) => {
                                setwarehouse(value.value);
                                console.log(warehouse);
                                handleChangeWarehouse(value.value);
                                register('warehouse', { value: value.value });
                                console.log('Watch:', watch('warehouse'));
                              }}
                            >
                              <option value="">Select</option>
                              {warehouselist?.map((warehouselist) => (
                                <option value={warehouselist.Warehouse_Description}>
                                  {warehouselist.Warehouse_Description}
                                </option>
                              ))}
                            </Select>
                            {errors?.warehouse && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.warehouse?.message}
                              </div>
                            )}
                          </div>
                        </div>
                        <div class="row justify-content-between text-left">
                          <div class="form-group col-sm-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">Requestor Phone Number</label>
                            <input
                              type="text"
                              id="email"
                              name="email"
                              placeholder=""
                              onblur="validate(3)"
                              maxlength="12"
                              value={phoneno}
                              pattern="[0-9]*"
                              disabled={status == 'Approved'}
                              onChange={(e) => {
                                setphoneno(e.target.value.replace(/\D/g, ''));
                                handleValidateStepper();
                              }}
                            />{' '}
                          </div>
                        </div>
                      </>
                    )}
                    {activeStep === 1 && (
                      <>
                        <div
                          class="row justify-content-between text-left"
                          style={{ paddingTop: '10px' }}
                        >
                          <div class="form-group col-sm-4 col-md-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">Retailer ID</label>
                            <input disabled={true} value={retailerid} />{' '}
                          </div>
                          <div class="form-group col-sm-8 flex-column d-flex">
                            <label class="form-control-label px-3">
                              DBA Name<span class="text-danger"> *</span>
                            </label>
                            <input
                              value={dbaname}
                              {...register('dbaname')}
                              disabled={status == 'Approved'}
                              maxlength="25"
                              onChange={(e) => {
                                setdbaname(e.target.value);
                                handleDbaDuplicate(e.target.value);
                                handleValidateStepper();
                              }}
                            />
                            {errors?.dbaname === undefined &&
                              dbaduplicate === true &&
                              dbaname != '' && (
                                <div style={{ color: 'red', marginTop: '.3rem' }}>
                                  DBA Name already exists
                                </div>
                              )}
                            <div style={{ color: 'red', marginTop: '.3rem' }}>
                              {errors?.dbaname?.message}
                            </div>
                          </div>
                        </div>
                        <div class="row justify-content-between text-left">
                          <div class="form-group col-sm-12 flex-column d-flex">
                            <label class="form-control-label px-3">
                              Street Address with Suite/Unit #<span class="text-danger"> *</span>
                            </label>
                            {status == 'Draft' && (
                              <AsyncCreatableSelect
                                placeholder=""
                                isDisabled={status == 'Approved'}
                                noOptionsMessage={() =>
                                  'Type atleast 3 characters to see the options'
                                }
                                inputProps={{ maxLength: 25 }}
                                value={{ label: `${street}`, value: `${street}` }}
                                onChange={(val) => {
                                  console.log(val);
                                  if (val.street != undefined && val.street != 'undefined') {
                                    setstreet(val.street);
                                    setValue('streetaddress', val.street);
                                  } else {
                                    setstreet(val.value);
                                    setValue('streetaddress', val.value);
                                  }
                                  if (val.city != undefined && val.city != 'undefined') {
                                    setcity(val.city);
                                    setValue('city', val.city);
                                    console.log(val.city);
                                  } else {
                                    setcity('');
                                    setValue('city', '');
                                  }
                                  if (val.postcode != undefined && val.postcode != 'undefined') {
                                    setzipcode(val.postcode.substring(0, 6));
                                    setValue('zipcode', val.postcode.substring(0, 6));
                                  } else {
                                    setzipcode(null);
                                    setValue('zipcode', null);
                                  }
                                  if (val.state != undefined && val.state != 'undefined') {
                                    setstate(val.state);
                                    setValue('state', val.state);
                                  } else {
                                    setstate('');
                                    setValue('state', '');
                                  }
                                  if (val.latitude != undefined && val.latitude != 'undefined') {
                                    setlatitude(val.latitude);
                                  }
                                  if (val.longitude != undefined && val.longitude != 'undefined') {
                                    setlongitude(val.longitude);
                                  }
                                }}
                                loadOptions={handleStreetAddress}
                                theme={customTheme}
                              />
                            )}
                            {status != 'Draft' && (
                              <input
                                disabled={status == 'Approved'}
                                value={street}
                                maxlength="25"
                                onChange={(e) => {
                                  setstreet(e.target.value);
                                  setValue('streetaddress', e.target.value);
                                }}
                              />
                            )}
                            <div style={{ color: 'red', marginTop: '.3rem' }}>
                              {errors?.streetaddress?.message}
                            </div>
                          </div>
                        </div>
                        <div class="row justify-content-between text-left">
                          <div class="form-group col-sm-8 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Licensee Name
                              {licensestatus != 'NON-LICENSED' &&
                                licensestatus != 'NON LICENSED' &&
                                licensestatus != '' && <span class="text-danger"> *</span>}
                            </label>
                            <input
                              maxlength="25"
                              {...register('licenseename')}
                              disabled={status == 'Approved'}
                              value={licenseename}
                              onChange={(e) => {
                                setlicenseename(e.target.value);
                                handleValidateStepper();
                              }}
                            />
                            <div style={{ color: 'red', marginTop: '.3rem' }}>
                              {errors?.licenseename?.message}
                            </div>{' '}
                          </div>
                          <div class="form-group col-sm-4 col-md-6 flex-column d-flex">
                            <label class="form-control-label px-3">
                              City<span class="text-danger"> *</span>
                            </label>
                            <input
                              {...register('city')}
                              disabled={status == 'Approved'}
                              value={city}
                              onChange={(e) => {
                                setcity(e.target.value);
                                handleValidateStepper();
                              }}
                            />
                            <div style={{ color: 'red', marginTop: '.3rem' }}>
                              {errors?.city?.message}
                            </div>
                          </div>
                        </div>
                        <div class="row justify-content-between text-left">
                          <div class="form-group col-sm-4 col-md-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              State
                              <span class="text-danger"> *</span>
                            </label>
                            <input
                              type="text"
                              {...register('state')}
                              disabled={status == 'Approved'}
                              value={state}
                              onChange={(e) => {
                                setstate(e.target.value);
                                handleValidateStepper();
                              }}
                            />
                            {errors?.state && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.state?.message}
                              </div>
                            )}{' '}
                          </div>
                          <div class="form-group col-sm-4 col-md-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Zip Code
                              <span class="text-danger"> *</span>
                            </label>
                            <input
                              type="text"
                              maxlength="5"
                              {...register('zipcode')}
                              disabled={status == 'Approved'}
                              value={zipcode}
                              onChange={(e) => {
                                setzipcode(e.target.value.replace(/\D/g, ''));
                                handleValidateStepper();
                              }}
                            />
                            {errors?.zipcode && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.zipcode?.message}
                              </div>
                            )}{' '}
                          </div>
                          <div class="form-group col-sm-4 col-md-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              County
                              <span class="text-danger"> *</span>
                            </label>
                            <React.Fragment style={{ paddingTop: '3rem' }}>
                              <Select
                                class="form-control"
                                name="email"
                                placeholder=""
                                isDisabled={status == 'Approved'}
                                onblur="validate(3)"
                                theme={customTheme}
                                value={{ label: `${county}`, value: `${county}` }}
                                options={countylist}
                                onChange={(value) => {
                                  setcounty(value.value);
                                  setValue('county', value.value);
                                  handleValidateStepper();
                                }}
                              >
                                <option value="">Select</option>
                                {countylist?.map((countylist) => (
                                  <option value={countylist.County_Name}>
                                    {countylist.County_Name}
                                  </option>
                                ))}
                              </Select>
                              {errors?.county && (
                                <div style={{ color: 'red', marginTop: '.3rem' }}>
                                  {errors?.county?.message}
                                </div>
                              )}{' '}
                            </React.Fragment>
                          </div>
                        </div>
                        <div class="row justify-content-between text-left">
                          <div class="form-group col-sm-4 col-md-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">Fax Number</label>
                            <input
                              type="text"
                              {...register('faxno')}
                              disabled={status == 'Approved'}
                              value={faxno}
                              maxlength="12"
                              pattern="[0-9]*"
                              onChange={(e) => {
                                setfaxno(e.target.value.replace(/\D/g, ''));
                                handleValidateStepper();
                              }}
                            />{' '}
                          </div>
                          <div class="form-group col-sm-4 col-md-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Retailer Phone Number
                              <span class="text-danger"> *</span>
                            </label>
                            <input
                              type="text"
                              disabled={status == 'Approved'}
                              value={retailerphoneno}
                              maxlength="12"
                              pattern="[0-9]*"
                              onChange={(e) => {
                                console.log('retailer ph no: ',e.target.value.replace(/\D/g, ''))
                                setretailerphoneno(e.target.value.replace(/\D/g, ''));
                                setValue('retailerphoneno', e.target.value.replace(/\D/g, ''));
                                handleValidateStepper();
                              }}
                            />
                            {errors?.retailerphoneno && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.retailerphoneno?.message}
                              </div>
                            )}{' '}
                          </div>
                          <div class="form-group col-sm-4 col-md-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Buyer
                              <span class="text-danger"> *</span>
                            </label>
                            <input
                              type="text"
                              disabled={status == 'Approved'}
                              maxlength="30"
                              value={buyer}
                              onChange={(e) => {
                                setbuyer(e.target.value);
                                setValue('buyer', e.target.value);
                                handleValidateStepper();
                              }}
                            />
                            {errors?.buyer && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.buyer?.message}
                              </div>
                            )}{' '}
                          </div>
                        </div>
                      </>
                    )}
                    {activeStep === 2 && (
                      <>
                        <div class="row justify-content-between text-left">
                          <div class="form-group col-sm-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              License Status
                              <span class="text-danger"> *</span>
                            </label>
                            <Select
                              class="form-control"
                              isDisabled={status == 'Approved'}
                              name="email"
                              placeholder=""
                              onblur="validate(3)"
                              theme={customTheme}
                              value={{ label: `${licensestatus}`, value: `${licensestatus}` }}
                              options={licensestatuslist}
                              onChange={(value) => {
                                setlicensestatus(value.value);
                                setValue('licensestatusvalid', value.value);
                                handleChangeTerritoriesMapping(value.value);
                                console.log(value.value);
                                console.log('Watch:', watch('licensestatusvalid'));
                                setlicensenumber('');
                                setlicenseexpdat(null);
                                setValue('licenseexpdat', null);
                                handleValidateStepper();
                                if (
                                  value.value == 'NON-LICENSED' ||
                                  value.value == 'NON LICENSED'
                                ) {
                                  setlicensedisabled(true);
                                  setlicenseexpdisabled();
                                } else {
                                  setlicensedisabled(false);
                                  setlicenseexpdisabled({ backgroundColor: 'white' });
                                }
                              }}
                            >
                              <option value="">Select</option>
                              {licensestatuslist?.map((licensestatuslist) => (
                                <option value={licensestatuslist.License_Status_Description}>
                                  {licensestatuslist.License_Status_Description}
                                </option>
                              ))}
                            </Select>
                            {errors?.licensestatusvalid && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.licensestatusvalid?.message}
                              </div>
                            )}{' '}
                          </div>
                          <div class="form-group col-sm-6 flex-column d-flex">
                            <label class="form-control-label px-3">
                              License #1 Number
                              {licensestatus != 'NON-LICENSED' &&
                                licensestatus != 'NON LICENSED' && (
                                  <span class="text-danger"> *</span>
                                )}
                            </label>
                            <input
                              value={licensenumber}
                              {...register('licensenumber')}
                              maxlength="20"
                              disabled={licensedisabled || status == 'Approved'}
                              onChange={(e) => {
                                setlicensenumber(e.target.value);
                                handleValidateStepper();
                              }}
                            />
                            {errors?.licensenumber && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.licensenumber?.message}
                              </div>
                            )}
                          </div>
                        </div>
                        <div class="row justify-content-between text-left">
                          <div class="form-group col-sm-6 flex-column d-flex">
                            <label class="form-control-label px-3">
                              Tax ID
                              {/* licensestatus != 'NON-LICENSED' && licensestatus != 'NON LICENSED' && (<span class="text-danger"> *</span>)*/}
                            </label>
                            <input
                              value={taxid}
                              maxlength="20"
                              disabled={licensedisabled || status == 'Approved'}
                              onChange={(e) => {
                                settaxid(e.target.value);
                                handleValidateStepper();
                              }}
                            />
                          </div>
                          <div class="form-group col-sm-6 flex-column d-flex">
                            <label class="form-control-label px-3">
                              License #1 Expiration Date
                              {licensestatus != 'NON-LICENSED' &&
                                licensestatus != 'NON LICENSED' && (
                                  <span class="text-danger"> *</span>
                                )}
                            </label>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                inputFormat="MMM dd yyyy"
                                minDate={datelimit}
                                value={licenseexpdat}
                                onChange={(newValue) => {
                                  setlicenseexpdat(newValue);
                                  setValue('licenseexpdat', newValue);
                                }}
                                disabled={licensedisabled || status == 'Approved'}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    sx={licenseexpdisabled}
                                    size="small"
                                    inputProps={{
                                      ...params.inputProps,
                                      placeholder: 'mmm dd yyyy',
                                      readOnly: true,
                                    }}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                            {errors?.licenseexpdat && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.licenseexpdat?.message}
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {activeStep === 3 && (
                      <>
                        <div class="row justify-content-between text-left">
                          <div class="form-group col-sm-4 col-md-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Draft Consumption Type
                              <span class="text-danger"> *</span>
                            </label>
                            <Select
                              class="form-control"
                              name="email"
                              placeholder=""
                              onblur="validate(3)"
                              isDisabled={status == 'Approved'}
                              isOptionDisabled={draftconsumptiontypeoptiondisabled}
                              theme={customTheme}
                              value={{
                                label: `${draftconsumptiontype}`,
                                value: `${draftconsumptiontype}`,
                              }}
                              options={draftconsumptiontypelist}
                              onChange={(value) => {
                                setdraftconsumptiontype(value.value);
                                setValue('sellingmethod', '');
                                setValue('packagetype', '');
                                handleSellingMethod(value.value, packageconsumptiontype);
                                handlePackageType(value.value, packageconsumptiontype);
                                setValue('draftconsumptiontypevalid', value.value);
                                handleValidateStepper();
                                if (
                                  value.value == 'NOT APPLICABLE' &&
                                  packageconsumptiontype == 'NOT APPLICABLE'
                                ) {
                                  setbotharesame(true);
                                } else {
                                  setbotharesame(false);
                                }
                              }}
                            >
                              <option value="">Select</option>
                              {draftconsumptiontypelist?.map((draftconsumptiontypelist) => (
                                <option
                                  value={
                                    draftconsumptiontypelist.Draft_Consumption_Type_Description
                                  }
                                >
                                  {draftconsumptiontypelist.Draft_Consumption_Type_Description}
                                </option>
                              ))}
                            </Select>
                            {errors?.draftconsumptiontypevalid && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.draftconsumptiontypevalid?.message}
                              </div>
                            )}{' '}
                          </div>
                          <div class="form-group col-sm-4 col-md-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Package Consumption Type
                              <span class="text-danger"> *</span>
                            </label>
                            <Select
                              class="form-control"
                              name="email"
                              placeholder=""
                              isDisabled={status == 'Approved'}
                              onblur="validate(3)"
                              isOptionDisabled={packageconsumptiontypeoptiondisabled}
                              theme={customTheme}
                              value={{
                                label: `${packageconsumptiontype}`,
                                value: `${packageconsumptiontype}`,
                              }}
                              options={packageconsumptiontypelist}
                              onChange={(value) => {
                                setpackageconsumptiontype(value.value);
                                register('sellingmethod', { value: '' });
                                setValue('packagetype', '');
                                handleValidateStepper();
                                handleSellingMethod(draftconsumptiontype, value.value);
                                handlePackageType(draftconsumptiontype, value.value);
                                setValue('packageconsumptiontype', value.value);
                                if (
                                  value.value == 'NOT APPLICABLE' &&
                                  draftconsumptiontype == 'NOT APPLICABLE'
                                ) {
                                  setbotharesame(true);
                                } else {
                                  setbotharesame(false);
                                }
                              }}
                            >
                              <option value="">Select</option>
                              {packageconsumptiontypelist?.map((packageconsumptiontypelist) => (
                                <option
                                  value={
                                    packageconsumptiontypelist.Package_Consumption_Type_Description
                                  }
                                >
                                  {packageconsumptiontypelist.Package_Consumption_Type_Description}
                                </option>
                              ))}
                            </Select>
                            {errors?.packageconsumptiontype && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.packageconsumptiontype?.message}
                              </div>
                            )}{' '}
                          </div>

                          <div class="form-group col-sm-4 col-md-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Civilian/Military
                              <span class="text-danger"> *</span>
                            </label>
                            <Select
                              class="form-control"
                              name="email"
                              isDisabled={status == 'Approved'}
                              placeholder=""
                              onblur="validate(3)"
                              theme={customTheme}
                              value={{ label: `${civilianmilitary}`, value: `${civilianmilitary}` }}
                              options={civilianmilitarylist}
                              onChange={(value) => {
                                setcivilianmilitary(value.value);
                                handleValidateStepper();
                                setValue('civilianmilitary', value.value);
                              }}
                            >
                              <option value="">Select</option>
                              {civilianmilitarylist?.map((civilianmilitarylist) => (
                                <option value={civilianmilitarylist.Civilian_or_Military}>
                                  {civilianmilitarylist.Civilian_or_Military}
                                </option>
                              ))}
                            </Select>
                            {errors?.civilianmilitary && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.civilianmilitary?.message}
                              </div>
                            )}{' '}
                          </div>
                        </div>
                        <div class="row justify-content-between text-left">
                          <div class="form-group col-sm-4 col-md-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Retailer Class
                              <span class="text-danger"> *</span>
                            </label>
                            <Select
                              class="form-control"
                              name="email"
                              isDisabled={status == 'Approved'}
                              placeholder=""
                              onblur="validate(3)"
                              theme={customTheme}
                              value={{ label: `${retailerclass}`, value: `${retailerclass}` }}
                              options={retailerclasslist}
                              onChange={(value) => {
                                setretailerclass(value.value);
                                handleValidateStepper();
                                setValue('retailerclass', value.value);
                              }}
                            >
                              <option value="">Select</option>
                              {retailerclasslist?.map((retailerclasslist) => (
                                <option value={retailerclasslist.retailerClass}>
                                  {retailerclasslist.retailerClass}
                                </option>
                              ))}
                            </Select>
                            {errors?.retailerclass && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.retailerclass?.message}
                              </div>
                            )}{' '}
                          </div>

                          <div class="form-group col-sm-4 col-md-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Patron Ethnicity
                              <span class="text-danger"> *</span>
                            </label>
                            <Select
                              class="form-control"
                              name="email"
                              isDisabled={status == 'Approved'}
                              placeholder=""
                              onblur="validate(3)"
                              theme={customTheme}
                              value={{ label: `${patronethnicity}`, value: `${patronethnicity}` }}
                              options={patronethnicitylist}
                              onChange={(value) => {
                                setpatronethnicity(value.value);
                                handleValidateStepper();
                                setValue('patronethnicity', value.value);
                              }}
                            >
                              <option value="">Select</option>
                              {patronethnicitylist?.map((patronethnicitylist) => (
                                <option value={patronethnicitylist.Patron_Ethnicity_Description}>
                                  {patronethnicitylist.Patron_Ethnicity_Description}
                                </option>
                              ))}
                            </Select>
                            {errors?.patronethnicity && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.patronethnicity?.message}
                              </div>
                            )}{' '}
                          </div>
                          <div class="form-group col-sm-4 col-md-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Patron Age
                              <span class="text-danger"> *</span>
                            </label>
                            <Select
                              class="form-control"
                              name="email"
                              isDisabled={status == 'Approved'}
                              placeholder=""
                              onblur="validate(3)"
                              theme={customTheme}
                              value={{ label: `${patronage}`, value: `${patronage}` }}
                              options={patronagelist}
                              onChange={(value) => {
                                setpatronage(value.value);
                                handleValidateStepper();
                                setValue('patronage', value.value);
                              }}
                            >
                              <option value="">Select</option>
                              {patronagelist?.map((patronagelist) => (
                                <option value={patronagelist.Patron_Age_Description}>
                                  {patronagelist.Patron_Age_Description}
                                </option>
                              ))}
                            </Select>
                            {errors?.patronage && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.patronage?.message}
                              </div>
                            )}{' '}
                          </div>
                        </div>
                        <div class="row justify-content-between text-left">
                          <div class="form-group col-sm-4 col-md-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Patron Occupation
                              <span class="text-danger"> *</span>
                            </label>
                            <Select
                              class="form-control"
                              isDisabled={status == 'Approved'}
                              name="email"
                              placeholder=""
                              onblur="validate(3)"
                              theme={customTheme}
                              value={{ label: `${patronoccupation}`, value: `${patronoccupation}` }}
                              options={patronoccupationlist}
                              onChange={(value) => {
                                setpatronoccupation(value.value);
                                handleValidateStepper();
                                setValue('patronoccupation', value.value);
                              }}
                            >
                              <option value="">Select</option>
                              {patronoccupationlist?.map((patronoccupationlist) => (
                                <option value={patronoccupationlist.Occupation_Description}>
                                  {patronoccupationlist.Occupation_Description}
                                </option>
                              ))}
                            </Select>
                            {errors?.patronoccupation && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.patronoccupation?.message}
                              </div>
                            )}{' '}
                          </div>
                          <div class="form-group col-sm-4 col-md-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Patron Residence
                              <span class="text-danger"> *</span>
                            </label>
                            <Select
                              class="form-control"
                              isDisabled={status == 'Approved'}
                              name="email"
                              placeholder=""
                              onblur="validate(3)"
                              theme={customTheme}
                              value={{ label: `${patronresidence}`, value: `${patronresidence}` }}
                              options={patronresidencelist}
                              onChange={(value) => {
                                setpatronresidence(value.value);
                                handleValidateStepper();
                                setValue('patronresidence', value.value);
                              }}
                            >
                              <option value="">Select</option>
                              {patronresidencelist.map((patronresidencelist) => (
                                <option value={patronresidencelist.Residence_Description}>
                                  {patronresidencelist.Residence_Description}
                                </option>
                              ))}
                            </Select>
                            {errors?.patronresidence && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.patronresidence?.message}
                              </div>
                            )}{' '}
                          </div>
                          <div class="form-group col-sm-4 col-md-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Contemporary Adult Type
                              <span class="text-danger"> *</span>
                            </label>
                            <Select
                              class="form-control"
                              isDisabled={status == 'Approved'}
                              name="email"
                              placeholder=""
                              onblur="validate(3)"
                              theme={customTheme}
                              value={{
                                label: `${contemporaryadulttype}`,
                                value: `${contemporaryadulttype}`,
                              }}
                              options={contemporaryadulttypelist}
                              onChange={(value) => {
                                setcontemporaryadulttype(value.value);
                                handleValidateStepper();
                                setValue('contemporaryadulttype', value.value);
                              }}
                            >
                              <option value="">Select</option>
                              {contemporaryadulttypelist?.map((contemporaryadulttypelist) => (
                                <option
                                  value={
                                    contemporaryadulttypelist.Contemporary_Adult_Type_Description
                                  }
                                >
                                  {contemporaryadulttypelist.Contemporary_Adult_Type_Description}
                                </option>
                              ))}
                            </Select>
                            {errors?.contemporaryadulttype && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.contemporaryadulttype?.message}
                              </div>
                            )}{' '}
                          </div>
                        </div>
                        <div class="row justify-content-between text-left">
                          <div class="form-group col-sm-4 col-md-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Owner Ethnicity or Language
                              <span class="text-danger"> *</span>
                            </label>
                            <Select
                              class="form-control"
                              isDisabled={status == 'Approved'}
                              name="email"
                              placeholder=""
                              onblur="validate(3)"
                              theme={customTheme}
                              value={{
                                label: `${ownerethnicityorlanguage}`,
                                value: `${ownerethnicityorlanguage}`,
                              }}
                              options={ownerethnicityorlanguagelist}
                              onChange={(value) => {
                                setownerethnicityorlanguage(value.value);
                                handleValidateStepper();
                                setValue('ownerethnicityorlanguage', value.value);
                              }}
                            >
                              <option value="">Select</option>
                              {ownerethnicityorlanguagelist?.map((ownerethnicityorlanguagelist) => (
                                <option
                                  value={ownerethnicityorlanguagelist.Owner_Ethnicity_Description}
                                >
                                  {ownerethnicityorlanguagelist.Owner_Ethnicity_Description}
                                </option>
                              ))}
                            </Select>
                            {errors?.ownerethnicityorlanguage && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.ownerethnicityorlanguage?.message}
                              </div>
                            )}{' '}
                          </div>

                          <div class="form-group col-sm-4 col-md-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Market Type
                              <span class="text-danger"> *</span>
                            </label>
                            <Select
                              class="form-control"
                              isDisabled={status == 'Approved'}
                              name="email"
                              placeholder=""
                              onblur="validate(3)"
                              theme={customTheme}
                              value={{ label: `${markettype}`, value: `${markettype}` }}
                              options={markettypelist}
                              onChange={(value) => {
                                setmarkettype(value.value);
                                handleValidateStepper();
                                setValue('markettype', value.value);
                              }}
                            >
                              <option value="">Select</option>
                              {markettypelist?.map((markettypelist) => (
                                <option value={markettypelist.Market_Type_Description}>
                                  {markettypelist.Market_Type_Description}
                                </option>
                              ))}
                            </Select>
                            {errors?.markettype && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.markettype?.message}
                              </div>
                            )}{' '}
                          </div>

                          <div class="form-group col-sm-4 col-md-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              On/Off Premise Type
                              <span class="text-danger"> *</span>
                            </label>
                            <Select
                              class="form-control"
                              name="email"
                              isDisabled={status == 'Approved'}
                              placeholder=""
                              onblur="validate(3)"
                              theme={customTheme}
                              value={{ label: `${onoffpremisetype}`, value: `${onoffpremisetype}` }}
                              options={premiselist}
                              onChange={(value) => {
                                setonoffpremisetype(value.value);
                                handleValidateStepper();
                                setValue('onoffpremisetype', value.value);
                              }}
                            >
                              <option value="">Select</option>
                              {premiselist?.map((premiselist) => (
                                <option value={premiselist.premise}>{premiselist.premise}</option>
                              ))}
                            </Select>
                            {errors?.onoffpremisetype && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.onoffpremisetype?.message}
                              </div>
                            )}{' '}
                          </div>
                        </div>

                        <div class="row justify-content-between text-left">
                          <div class="form-group col-sm-4 col-md-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Industry #1 Volume
                              <span class="text-danger"> *</span>
                            </label>
                            <Select
                              class="form-control"
                              name="email"
                              isDisabled={status == 'Approved'}
                              placeholder=""
                              onblur="validate(3)"
                              theme={customTheme}
                              value={{ label: `${industryvolume}`, value: `${industryvolume}` }}
                              options={industryvolumelist}
                              onChange={(value) => {
                                setindustryvolume(value.value);
                                handleValidateStepper();
                                setValue('industryvolume', value.value);
                              }}
                            >
                              <option value="">Select</option>
                              {industryvolumelist?.map((industryvolumelist) => (
                                <option
                                  value={industryvolumelist['Industry_#1_Volume_Description']}
                                >
                                  {industryvolumelist['Industry_#1_Volume_Description']}
                                </option>
                              ))}
                            </Select>
                            {errors?.industryvolume && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.industryvolume?.message}
                              </div>
                            )}{' '}
                          </div>
                        </div>
                      </>
                    )}

                    {activeStep === 4 && (
                      <>
                        <div className="page-header ml-4">
                          <h5 className="page-title">Chain detail & open/close status</h5>
                        </div>
                        <hr />
                        <div class="row justify-content-between text-left">
                          <div class="form-group col-sm-4 col-md-6 flex-column d-flex">
                            <label class="form-control-label px-3">Business Open Date</label>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                inputFormat="MMM dd yyyy"
                                disabled={status == 'Approved'}
                                value={businessopendate}
                                onChange={(newValue) => {
                                  setbusinessopendate(newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    sx={datepickerdisabled}
                                    size="small"
                                    inputProps={{
                                      ...params.inputProps,
                                      placeholder: 'mmm dd yyyy',
                                      readOnly: true,
                                    }}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </div>
                          <div class="form-group col-sm-4 col-md-6 flex-column d-flex">
                            <label class="form-control-label px-3">Seasonal Open Date</label>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                inputFormat="MMM dd"
                                minDate={datelimit}
                                disabled={status == 'Approved'}
                                value={seasonalopendate}
                                onChange={(newValue) => {
                                  setseasonalopendate(newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    sx={datepickerdisabled}
                                    size="small"
                                    inputProps={{
                                      ...params.inputProps,
                                      placeholder: 'mmm dd',
                                      readOnly: true,
                                    }}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </div>
                          <div class="form-group col-sm-4 col-md-6 flex-column d-flex">
                            <label class="form-control-label px-3">Seasonal Close Date</label>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                inputFormat="MMM dd"
                                minDate={datelimit}
                                value={seasonalclosedate}
                                disabled={status == 'Approved'}
                                onChange={(newValue) => {
                                  setseasonalclosedate(newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    sx={datepickerdisabled}
                                    size="small"
                                    inputProps={{
                                      ...params.inputProps,
                                      placeholder: 'mmm dd',
                                      readOnly: true,
                                    }}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </div>
                        </div>
                        <div class="row justify-content-between text-left">
                          <div class="form-group col-sm-6 flex-column d-flex">
                            <label class="form-control-label px-3">
                              Chain/Independent Status<span class="text-danger"> *</span>
                            </label>
                            <Select
                              class="form-control"
                              isDisabled={status == 'Approved'}
                              name="email"
                              placeholder=""
                              disabled={status == 'Approved'}
                              onblur="validate(3)"
                              theme={customTheme}
                              value={{
                                label: `${chainindependentstatus}`,
                                value: `${chainindependentstatus}`,
                              }}
                              options={chainflag}
                              onChange={(value) => {
                                setchainindependentstatus(value.value);
                                setchain('');
                                setchainstorenum(null);
                                handleValidateStepper();
                                setValue('chain', '');
                                setValue('chainstorenum', '');
                                setValue('chainindependentstatusvalid', value.value);
                                if (value.value == 'Independent') {
                                  setchaindisabled(true);
                                } else {
                                  setchaindisabled(false);
                                }
                              }}
                            >
                              <option value="">Select</option>
                              {chainflag?.map((chainflag) => (
                                <option value={chainflag.chainFlag}>{chainflag.chainFlag}</option>
                              ))}
                            </Select>
                            {errors?.chainindependentstatusvalid && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.chainindependentstatusvalid?.message}
                              </div>
                            )}
                          </div>
                          <div class="form-group col-sm-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Chain
                              {chainindependentstatus != 'Independent' &&
                                chainindependentstatus != '' && <span class="text-danger"> *</span>}
                            </label>
                            <Select
                              class="form-control"
                              name="email"
                              placeholder=""
                              isDisabled={status == 'Approved' || chaindisabled}
                              onblur="validate(3)"
                              theme={customTheme}
                              value={{ label: `${chain}`, value: `${chain}` }}
                              options={chainlist}
                              onChange={(value) => {
                                setchain(value.value);
                                handleValidateStepper();
                                setValue('chain', value.value);
                              }}
                            >
                              <option value="">Select</option>
                              {chainlist?.map((chainlist) => (
                                <option value={chainlist.Chain_Name}>{chainlist.Chain_Name}</option>
                              ))}
                            </Select>
                            {errors?.chain && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.chain?.message}
                              </div>
                            )}{' '}
                          </div>
                        </div>
                        <div class="row justify-content-between text-left">
                          <div class="form-group col-sm-6 flex-column d-flex">
                            <label class="form-control-label px-3">
                              Chain Store Number
                              {chainindependentstatus != 'Independent' &&
                                chainindependentstatus != '' && <span class="text-danger"> *</span>}
                            </label>
                            <input
                              value={chainstorenum}
                              maxlength="10"
                              disabled={chaindisabled || status == 'Approved'}
                              {...register('chainstorenum')}
                              pattern="[0-9]*"
                              onChange={(e) => {
                                setchainstorenum(e.target.value.replace(/\D/g, ''));
                                handleValidateStepper();
                              }}
                            />
                            {errors?.chainstorenum && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.chainstorenum?.message}
                              </div>
                            )}
                          </div>
                        </div>
                        <div class="row justify-content-between text-left">
                          <div class="form-group col-sm-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Retailer Status
                              <span class="text-danger"> *</span>
                            </label>
                            <Select
                              class="form-control"
                              name="email"
                              isDisabled={status == 'Approved'}
                              placeholder=""
                              onblur="validate(3)"
                              theme={customTheme}
                              value={{ label: `${retailerstatus}`, value: `${retailerstatus}` }}
                              options={retailerstatuslist}
                              onChange={(value) => {
                                setretailerstatus(value.value);
                                handleValidateStepper();
                                setValue('retailerstatus', value.value);
                              }}
                            >
                              <option value="">Select</option>
                              {retailerstatuslist?.map((retailerstatuslist) => (
                                <option value={retailerstatuslist.Retailer_Status_Description}>
                                  {retailerstatuslist.Retailer_Status_Description}
                                </option>
                              ))}
                            </Select>
                            {errors?.retailerstatus && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.retailerstatus?.message}
                              </div>
                            )}{' '}
                          </div>
                        </div>
                        <div className="page-header ml-4">
                          <h5 className="page-title">Sales Detail</h5>
                        </div>
                        <hr />
                        <div class="row justify-content-between text-left">
                          <div class="form-group col-sm-4 col-md-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Selling Method
                              <span class="text-danger"> *</span>
                            </label>
                            <Select
                              class="form-control"
                              isDisabled={status == 'Approved'}
                              name="email"
                              placeholder=""
                              onblur="validate(3)"
                              theme={customTheme}
                              value={{ label: `${sellingmethod}`, value: `${sellingmethod}` }}
                              options={sellingmethodlist}
                              onChange={(value) => {
                                setsellingmethod(value.value);
                                handleValidateStepper();
                                setValue('sellingmethod', value.value);
                                console.log(sellingmethod);
                                console.log('Watch:', watch('sellingmethod'));
                                console.log(sellingmethodlist);
                              }}
                            >
                              <option value="">Select</option>
                              {sellingmethodlist?.map((sellingmethodlist) => (
                                <option value={sellingmethodlist.Selling_Method_Description}>
                                  {sellingmethodlist.Selling_Method_Description}
                                </option>
                              ))}
                            </Select>
                            {errors?.sellingmethod && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.sellingmethod?.message}
                              </div>
                            )}{' '}
                          </div>
                          <div class="form-group col-sm-4 col-md-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Package Type
                              <span class="text-danger"> *</span>
                            </label>
                            <Select
                              class="form-control"
                              name="email"
                              isDisabled={status == 'Approved'}
                              placeholder=""
                              onblur="validate(3)"
                              theme={customTheme}
                              value={{ label: `${packagetype}`, value: `${packagetype}` }}
                              options={sellablepackagetypelist}
                              onChange={(value) => {
                                setpackagetype(value.value);
                                handleValidateStepper();
                                setValue('packagetype', value.value);
                                console.log('Package Type:', value.value);
                              }}
                            >
                              <option value="">Select</option>
                              {sellablepackagetypelist?.map((sellablepackagetypelist) => (
                                <option value={sellablepackagetypelist.packageType}>
                                  {sellablepackagetypelist.packageType}
                                </option>
                              ))}
                            </Select>
                            {errors?.packagetype && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.packagetype?.message}
                              </div>
                            )}{' '}
                          </div>

                          <div class="form-group col-sm-4 col-md-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Draft Status
                              <span class="text-danger"> *</span>
                            </label>
                            <Select
                              class="form-control"
                              name="email"
                              placeholder=""
                              onblur="validate(3)"
                              theme={customTheme}
                              value={{ label: `${draftstatus}`, value: `${draftstatus}` }}
                              options={draftstatuslist}
                              onChange={(value) => {
                                setdraftstatus(value.value);
                                handleValidateStepper();
                                setValue('draftstatus', value.value);
                              }}
                            ></Select>
                            {errors?.draftstatus && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.draftstatus?.message}
                              </div>
                            )}{' '}
                          </div>

                          <div class="form-group col-sm-4 col-md-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Performance Pay status
                              <span class="text-danger"> *</span>
                            </label>
                            <Select
                              class="form-control"
                              name="email"
                              isDisabled={status == 'Approved'}
                              placeholder=""
                              onblur="validate(3)"
                              theme={customTheme}
                              value={{
                                label: `${performancepaystatus}`,
                                value: `${performancepaystatus}`,
                              }}
                              options={performancepaystatuslist}
                              onChange={(value) => {
                                setperformancepaystatus(value.value);
                                handleValidateStepper();
                                setValue('performancepaystatus', value.value);
                              }}
                            >
                              <option value="">Select</option>
                              {performancepaystatuslist?.map((performancepaystatuslist) => (
                                <option
                                  value={
                                    performancepaystatuslist.Performance_Pay_Status_Description
                                  }
                                >
                                  {performancepaystatuslist.Performance_Pay_Status_Description}
                                </option>
                              ))}
                            </Select>
                            {errors?.performancepaystatus && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.performancepaystatus?.message}
                              </div>
                            )}{' '}
                          </div>
                        </div>
                        <div class="row justify-content-between text-left">
                          <div class="form-group col-sm-4 col-md-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Display Status
                              <span class="text-danger"> *</span>
                            </label>
                            <Select
                              class="form-control"
                              name="email"
                              placeholder=""
                              isDisabled={status == 'Approved'}
                              onblur="validate(3)"
                              theme={customTheme}
                              value={{ label: `${displaystatus}`, value: `${displaystatus}` }}
                              options={displaystatuslist}
                              onChange={(value) => {
                                setdisplaystatus(value.value);
                                handleValidateStepper();
                                setValue('displaystatus', value.value);
                              }}
                            >
                              <option value="">Select</option>
                              {displaystatuslist?.map((displaystatuslist) => (
                                <option
                                  value={displaystatuslist.Retailer_Display_Status_Description}
                                >
                                  {displaystatuslist.Retailer_Display_Status_Description}
                                </option>
                              ))}
                            </Select>
                            {errors?.displaystatus && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.displaystatus?.message}
                              </div>
                            )}{' '}
                          </div>
                          <div class="form-group col-sm-4 col-md-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Allowable POS
                              <span class="text-danger"> *</span>
                            </label>
                            <Select
                              class="form-control"
                              name="email"
                              placeholder=""
                              isDisabled={status == 'Approved'}
                              onblur="validate(3)"
                              theme={customTheme}
                              value={{ label: `${allowablepos}`, value: `${allowablepos}` }}
                              options={allowableposlist}
                              onChange={(value) => {
                                setallowablepos(value.value);
                                handleValidateStepper();
                                setValue('allowablepos', value.value);
                              }}
                            >
                              <option value="">Select</option>
                              {allowableposlist?.map((allowableposlist) => (
                                <option value={allowablepos.Allowable_POS_Description}>
                                  {allowablepos.Allowable_POS_Description}
                                </option>
                              ))}
                            </Select>
                            {errors?.allowablepos && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.allowablepos?.message}
                              </div>
                            )}{' '}
                            <div>
                              <Button
                                type="button"
                                onClick={() => {
                                  setallowablepos('');
                                  setValue('allowablepos', '');
                                }}
                              >
                                Clear
                              </Button>
                            </div>
                          </div>
                          <div class="form-group col-sm-4 col-md-6 flex-column d-flex">
                            <label class="form-control-label px-3">PO Number Required?</label>
                            <input
                              type="checkbox"
                              disabled={status == 'Approved'}
                              checked={ponumreq}
                              onChange={handleOnChange3}
                            />
                          </div>
                        </div>
                      </>
                    )}

                    {activeStep === 5 && (
                      <>
                        <div
                          class="row justify-content-between text-left"
                          style={{ paddingTop: '10px' }}
                        >
                          <div class="form-group col-sm-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              FLP Group
                              {(localStorage.getItem('role') !== 'Sales_Representative' || (localStorage.getItem('warehouse') === 'OKLAHOMA CITY [REGULAR]' || localStorage.getItem('warehouse') === 'TULSA')) && (
                                <span class="text-danger"> *</span>
                              )}
                            </label>
                            <Select
                              class="form-control"
                              name="email"
                              placeholder=""
                              isDisabled={
                                status == 'Approved' ||
                                (localStorage.getItem('role') !== 'Sales_Manager' &&  (localStorage.getItem('warehouse') !== 'TULSA' && localStorage.getItem('warehouse') !== 'OKLAHOMA CITY [REGULAR]'))
                              }
                              onblur="validate(3)"
                              theme={customTheme}
                              value={{ label: `${flpgroup}`, value: `${flpgroup}` }}
                              options={flpgrouplist}
                              onChange={(value) => {
                                setflpgroup(value.value);
                                setValue('flpgroup', value.value);
                              }}
                            >
                              <option value="">Select</option>
                              {flpgrouplist?.map((flpgrouplist) => (
                                <option value={flpgrouplist.FLP_Group_Description}>
                                  {flpgrouplist.FLP_Group_Description}
                                </option>
                              ))}
                            </Select>
                            {errors?.flpgroup && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.flpgroup?.message}
                              </div>
                            )}{' '}
                          </div>
                          <div class="form-group col-sm-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Pricing Group
                              {((localStorage.getItem('warehouse') === 'OKLAHOMA CITY [REGULAR]' || localStorage.getItem('warehouse') === 'TULSA') || (localStorage.getItem('role') !== 'Sales_Representative' &&
                                (company !== 'AUGUST A. BUSCH BOSTON' && warehouse !== 'AUGUST A. BUSCH BOSTON' &&
                                  company !== 'ANHEUSER BUSCH SALES OF CANTON' && warehouse !== 'ANHEUSER BUSCH SALES OF CANTON'))) ? (
                                <span className="text-danger"> *</span>
                              ) : null}
                            </label>
                            <Select
                              class="form-control"
                              name="email"
                              placeholder=""
                              isDisabled={
                                status == 'Approved' ||
                                (localStorage.getItem('role') !== 'Sales_Manager' &&  (localStorage.getItem('warehouse') !== 'TULSA' && localStorage.getItem('warehouse') !== 'OKLAHOMA CITY [REGULAR]'))
                              }
                              onblur="validate(3)"
                              theme={customTheme}
                              value={{ label: `${pricinggroup}`, value: `${pricinggroup}` }}
                              options={pricinggrouplist}
                              onChange={(value) => {
                                setpricinggroup(value.value);
                                handleValidateStepper();
                                setValue('pricinggroup', value.value);
                              }}
                            >
                              <option value="">Select</option>
                              {pricinggrouplist?.map((pricinggrouplist) => (
                                <option value={pricinggrouplist.Pricing_Group_Description}>
                                  {pricinggrouplist.Pricing_Group_Description}
                                </option>
                              ))}
                            </Select>
                            {errors?.pricinggroup && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.pricinggroup?.message}
                              </div>
                            )}{' '}
                          </div>
                        </div>
                        <div
                          class="row justify-content-between text-left"
                          style={{ paddingTop: '10px' }}
                        >
                          <div class="form-group col-sm-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">Pricing Sub Group
                            { pricinggroup !=='' && ( localStorage.getItem('warehouse') === 'OKLAHOMA CITY [REGULAR]' || localStorage.getItem('warehouse') === 'TULSA') &&
                               <span className="text-danger"> *</span>
                            }
                            </label>
                            <Select
                              class="form-control"
                              name="email"
                              isDisabled={
                                status == 'Approved' ||
                                (localStorage.getItem('role') !== 'Sales_Manager' &&  (localStorage.getItem('warehouse') !== 'TULSA' && localStorage.getItem('warehouse') !== 'OKLAHOMA CITY [REGULAR]'))
                              }
                              placeholder=""
                              onblur="validate(3)"
                              theme={customTheme}
                              value={{ label: `${pricingsubgroup}`, value: `${pricingsubgroup}` }}
                              options={pricinggrouplist}
                              onChange={(value) => {
                                setpricingsubgroup(value.value);
                                handleValidateStepper();
                              }}
                            >
                              <option value="">Select</option>
                              {pricinggrouplist?.map((pricinggrouplist) => (
                                <option value={pricinggrouplist.Pricing_Group_Description}>
                                  {pricinggrouplist.Pricing_Group_Description}
                                </option>
                              ))}
                            </Select>{errors?.pricingsubgroup && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.pricingsubgroup?.message}
                              </div>
                            )}{' '}
                            <div>
                              <Button
                                type="button"
                                onClick={() => {
                                  setpricingsubgroup('');
                                  setValue('allowablepos', '');
                                }}
                              >
                                Clear
                              </Button>
                            </div>
                          </div>
                          <div class="form-group col-sm-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Delivery Scan Sheet Required?
                            </label>
                            <Select
                              class="form-control"
                              name="email"
                              placeholder=""
                              isDisabled={status == 'Approved'}
                              onblur="validate(3)"
                              theme={customTheme}
                              value={{
                                label: `${deliveryscansheetrequired}`,
                                value: `${deliveryscansheetrequired}`,
                              }}
                              options={deliveryscansheetrequiredlist}
                              onChange={(value) => {
                                setdeliveryscansheetrequired(value.value);
                                handleValidateStepper();
                              }}
                            >
                              <option value="">Select</option>
                              {deliveryscansheetrequiredlist?.map(
                                (deliveryscansheetrequiredlist) => (
                                  <option
                                    value={
                                      deliveryscansheetrequiredlist.Delivery_Scan_Sheet_Required
                                    }
                                  >
                                    {deliveryscansheetrequiredlist.Delivery_Scan_Sheet_Required}
                                  </option>
                                ),
                              )}
                            </Select>{' '}
                          </div>
                        </div>
                      </>
                    )}

                    {activeStep === 6 && (
                      <>
                        <div
                          class="row justify-content-between text-left"
                          style={{ paddingTop: '10px' }}
                        >
                          <div className="page-header ml-4">
                            <h5 className="page-title">Sales Routing</h5>
                          </div>
                          <hr />
                          <div class="form-group col-sm-3 col-md-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Call Day<span class="text-danger"> *</span>
                            </label>
                            <Select
                              isMulti
                              closeMenuOnSelect={false}
                              isClearable={false}
                              isDisabled={status == 'Approved'}
                              class="form-control"
                              name="email"
                              placeholder=""
                              onblur="validate(3)"
                              theme={customTheme}
                              components={animatedComponents}
                              value={callday}
                              options={calldayoptions}
                              onChange={(value) => {
                                setcallday(value);
                                handleValidateStepper();
                                setValue('calldayvalid', value);
                                console.log('calldayvalid', watch('calldayvalid'));
                              }}
                            ></Select>
                            <div style={{ color: 'red', marginTop: '.3rem' }}>
                              {errors?.calldayvalid?.message}
                            </div>{' '}
                          </div>
                          <div class="form-group col-sm-3 col-md-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Route Type
                              {callday != '' && <span class="text-danger"> *</span>}
                            </label>
                            <Select
                              class="form-control"
                              name="email"
                              placeholder=""
                              onblur="validate(3)"
                              isDisabled={status == 'Approved'}
                              theme={customTheme}
                              value={{ label: `${salesroutetype}`, value: `${salesroutetype}` }}
                              options={routetypelist}
                              onChange={(value) => {
                                setsalesroutetype(value.value);
                                handleValidateStepper();
                                setValue('salesroutetype', value.value);
                              }}
                            >
                              <option value="">Select</option>
                              {routetypelist?.map((routetypelist) => (
                                <option value={routetypelist.Route_Type_Description}>
                                  {routetypelist.Route_Type_Description}
                                </option>
                              ))}
                            </Select>
                            {errors?.salesroutetype && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.salesroutetype?.message}
                              </div>
                            )}{' '}
                          </div>
                          <div class="form-group col-sm-3 col-md-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Sales Rep
                              {callday != '' && <span class="text-danger"> *</span>}
                            </label>
                            <Select
                              class="form-control"
                              name="email"
                              placeholder=""
                              isDisabled={status == 'Approved'}
                              onblur="validate(3)"
                              theme={customTheme}
                              value={{ label: `${salesrep}`, value: `${salesrep}` }}
                              options={salesreplist}
                              onChange={(value) => {
                                setsalesrep(value.value);
                                handleValidateStepper();
                                setValue('salesrep', value.value);
                              }}
                            >
                              <option value="">Select</option>
                              {salesreplist?.map((salesreplist) => (
                                <option value={salesreplist.Sales_Rep_Name}>
                                  {salesreplist.Sales_Rep_Name}
                                </option>
                              ))}
                            </Select>
                            {errors?.salesrep && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.salesrep?.message}
                              </div>
                            )}{' '}
                          </div>
                          {/* <div class="form-group col-sm-3 col-md-6 flex-column d-flex">
                    {' '}
                    <label class="form-control-label px-3">
                    Route Sequence
                    </label>
                    <input value={salesroutesequence}
                    maxlength="3"
                    disabled={status == "Approved"}
                    pattern="[0-9]*"
                    onChange={(e) =>
                      {setsalesroutesequence(e.target.value.replace(/\D/g, ""));handleValidateStepper();}
                    }/>{' '}
                  </div> */}
                        </div>
                        <div
                          class="row justify-content-between text-left"
                          style={{ paddingTop: '10px' }}
                        >
                          <div class="form-group col-sm-3 col-md-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Route Frequency
                              {callday != '' && <span class="text-danger"> *</span>}
                            </label>
                            <Select
                              class="form-control"
                              name="email"
                              placeholder=""
                              isDisabled={status == 'Approved'}
                              onblur="validate(3)"
                              theme={customTheme}
                              value={{
                                label: `${salesroutefrequency}`,
                                value: `${salesroutefrequency}`,
                              }}
                              options={routefrequencylist}
                              onChange={(value) => {
                                setsalesroutefrequency(value.value);
                                handleValidateStepper();
                                setValue('salesroutefrequency', value.value);
                              }}
                            >
                              <option value="">Select</option>
                              {routefrequencylist?.map((routefrequencylist) => (
                                <option value={routefrequencylist.Route_Frequency_Description}>
                                  {routefrequencylist.Route_Frequency_Description}
                                </option>
                              ))}
                            </Select>
                            {errors?.salesroutefrequency && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.salesroutefrequency?.message}
                              </div>
                            )}{' '}
                          </div>
                          <div class="form-group col-sm-3 col-md-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Supervisor
                              {salesrep != '' && <span class="text-danger"> *</span>}
                            </label>
                            <Select
                              class="form-control"
                              name="email"
                              placeholder=""
                              isDisabled={status == 'Approved'}
                              onblur="validate(3)"
                              theme={customTheme}
                              value={{ label: `${supervisor}`, value: `${supervisor}` }}
                              options={supervisorlist}
                              onChange={(value) => {
                                setsupervisor(value.value);
                                handleValidateStepper();
                                setValue('supervisor', value.value);
                              }}
                            >
                              <option value="">Select</option>
                              {supervisorlist?.map((supervisorlist) => (
                                <option value={supervisorlist.Supervisor_Name}>
                                  {supervisorlist.Supervisor_Name}
                                </option>
                              ))}
                            </Select>
                            {errors?.supervisor && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.supervisor?.message}
                              </div>
                            )}{' '}
                          </div>
                          <div class="form-group col-sm-3 col-md-6 flex-column d-flex">
                            <label class="form-control-label px-3">
                              Effective Date
                              {salesroutefrequency != 'EVERY WEEK' && (
                                <span class="text-danger"> *</span>
                              )}
                            </label>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                inputFormat="MMM dd yyyy"
                                minDate={datelimit}
                                disabled={status == 'Approved'}
                                value={saleseffectivedate}
                                onChange={(newValue) => {
                                  setsaleseffectivedate(newValue);
                                  setValue('saleseffectivedate', newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    sx={datepickerdisabled}
                                    size="small"
                                    inputProps={{
                                      ...params.inputProps,
                                      placeholder: 'mmm dd yyyy',
                                      readOnly: true,
                                    }}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                            {errors?.saleseffectivedate && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.saleseffectivedate?.message}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="page-header ml-4">
                          <h5 className="page-title">Delivery Routing</h5>
                        </div>
                        <hr />
                        <div
                          class="row justify-content-between text-left"
                          style={{ paddingTop: '10px' }}
                        >
                          <div class="form-group col-sm-3 col-md-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              {/* Delivery Day<span class="text-danger"> *</span> */}
                              Delivery Day
                              {(localStorage.getItem('warehouse') !== 'OKLAHOMA CITY [REGULAR]' && localStorage.getItem('warehouse') !== 'TULSA') && (
                                <span class="text-danger"> *</span>
                              )}
                            </label>
                            <Select
                              isMulti
                              closeMenuOnSelect={false}
                              isDisabled={status == 'Approved'}
                              isClearable={false}
                              class="form-control"
                              name="email"
                              placeholder=""
                              onblur="validate(3)"
                              theme={customTheme}
                              components={animatedComponents}
                              value={deliveryday}
                              options={calldayoptions}
                              onChange={(value) => {
                                setdeliveryday(value);
                                handleValidateStepper();
                                setValue('deliverydayvalid', value);
                                console.log('deliverydayvalid', watch('deliverydayvalid'));
                              }}
                            ></Select>
                            <div style={{ color: 'red', marginTop: '.3rem' }}>
                              {errors?.deliverydayvalid?.message}
                            </div>{' '}
                          </div>
                          <div class="form-group col-sm-3 col-md-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">Route Type</label>
                            <Select
                              class="form-control"
                              name="email"
                              isDisabled={status == 'Approved'}
                              placeholder=""
                              onblur="validate(3)"
                              theme={customTheme}
                              value={{
                                label: `${deliveryroutetype}`,
                                value: `${deliveryroutetype}`,
                              }}
                              options={routetypelist}
                              onChange={(value) => {
                                setdeliveryroutetype(value.value);
                                handleValidateStepper();
                                setValue('deliveryroutetype', value.value);
                              }}
                            >
                              <option value="">Select</option>
                              {routetypelist?.map((routetypelist) => (
                                <option value={routetypelist.Route_Type_Description}>
                                  {routetypelist.Route_Type_Description}
                                </option>
                              ))}
                            </Select>
                            <div>
                              <Button
                                type="button"
                                onClick={() => {
                                  setdeliveryroutetype('');
                                  setValue('deliveryroutetype', '');
                                }}
                              >
                                Clear
                              </Button>
                            </div>
                            {errors?.deliveryroutetype && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.deliveryroutetype?.message}
                              </div>
                            )}{' '}
                          </div>
                          <div class="form-group col-sm-3 col-md-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Delivery Route
                              {localStorage.getItem('role') == 'Sales_Manager' && (localStorage.getItem('warehouse') !== 'OKLAHOMA CITY [REGULAR]' && localStorage.getItem('warehouse') !== 'TULSA') && (
                                <span class="text-danger"> *</span>
                              )}
                            </label>
                            <Select
                              class="form-control"
                              name="email"
                              placeholder=""
                              isDisabled={status == 'Approved'}
                              onblur="validate(3)"
                              theme={customTheme}
                              value={{ label: `${deliveryroute}`, value: `${deliveryroute}` }}
                              options={deliveryroutelist}
                              onChange={(value) => {
                                setdeliveryroute(value.value);
                                handleValidateStepper();
                                setValue('deliveryroute', value.value);
                              }}
                            >
                              <option value="">Select</option>
                              {deliveryroutelist?.map((planogrammabdeliveryroutelistlelist) => (
                                <option value={deliveryroutelist.Driver_ID}>
                                  {deliveryroutelist.Driver_ID}
                                </option>
                              ))}
                            </Select>
                            {errors?.deliveryroute && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.deliveryroute?.message}
                              </div>
                            )}{' '}
                          </div>
                          {/* <div class="form-group col-sm-3 col-md-6 flex-column d-flex">
                    {' '}
                    <label class="form-control-label px-3">
                    Route Sequence
                    </label>
                    <input value={deliveryroutesequence}
                    maxlength="3"
                    disabled={status == "Approved"}
                    pattern="[0-9]*"
                    onChange={(e) =>
                      {setdeliveryroutesequence(e.target.value.replace(/\D/g, ""));handleValidateStepper();}
                    } />{' '}
                  </div> */}
                        </div>
                        <div
                          class="row justify-content-between text-left"
                          style={{ paddingTop: '10px' }}
                        >
                          <div class="form-group col-sm-3 col-md-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">Route Frequency</label>
                            <Select
                              class="form-control"
                              name="email"
                              placeholder=""
                              isDisabled={status == 'Approved'}
                              onblur="validate(3)"
                              theme={customTheme}
                              value={{
                                label: `${deliveryroutefrequency}`,
                                value: `${deliveryroutefrequency}`,
                              }}
                              options={routefrequencylist}
                              onChange={(value) => {
                                setdeliveryroutefrequency(value.value);
                                handleValidateStepper();
                                setValue('deliveryroutefrequency', value.value);
                                handleValidateStepper();
                              }}
                            >
                              <option value="">Select</option>
                              {routefrequencylist?.map((routefrequencylist) => (
                                <option value={routefrequencylist.Route_Frequency_Description}>
                                  {routefrequencylist.Route_Frequency_Description}
                                </option>
                              ))}
                            </Select>
                            <div>
                              <Button
                                type="button"
                                onClick={() => {
                                  setdeliveryroutefrequency('');
                                  setValue('deliveryroutefrequency', '');
                                }}
                              >
                                Clear
                              </Button>
                            </div>
                            {errors?.deliveryroutefrequency && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.deliveryroutefrequency?.message}
                              </div>
                            )}{' '}
                          </div>

                          <div class="form-group col-sm-3 col-md-6 flex-column d-flex">
                            <label class="form-control-label px-3">Effective Date</label>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                inputFormat="MMM dd yyyy"
                                minDate={datelimit}
                                disabled={status == 'Approved'}
                                value={deliveryeffectivedate}
                                onChange={(newValue) => {
                                  setdeliveryeffectivedate(newValue);
                                  setValue('deliveryeffectivedate', newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    sx={datepickerdisabled}
                                    size="small"
                                    inputProps={{
                                      ...params.inputProps,
                                      placeholder: 'mmm dd yyyy',
                                      readOnly: true,
                                    }}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                            {errors?.deliveryeffectivedate && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.deliveryeffectivedate?.message}
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}

                    {activeStep === 7 && (
                      <>
                        <div
                          class="row justify-content-between text-left"
                          style={{ paddingTop: '10px' }}
                        >
                          <div class="form-group col-sm-4 col-md-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Opening Time<span class="text-danger"> *</span>
                            </label>
                            <Select
                              class="form-control"
                              name="Opening Time"
                              placeholder="Opening Time"
                              theme={customTheme}
                              value={{ label: `${openingtime}`, value: `${openingtime}` }}
                              options={timeOptions}
                              onChange={(value) => {
                                setopeningtime(value.value);
                                handleValidateStepper();
                                setValue('openingtime', value.value);
                              }}
                            ></Select>
                            {errors?.openingtime && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.openingtime?.message}
                              </div>
                            )}
                          </div>
                          <div class="form-group col-sm-4 col-md-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Closing Time<span class="text-danger"> *</span>
                            </label>
                            <Select
                              class="form-control"
                              name="Closing Time"
                              placeholder="Closing Time"
                              theme={customTheme}
                              value={{ label: `${closingtime}`, value: `${closingtime}` }}
                              options={timeOptions}
                              onChange={(value) => {
                                setclosingtime(value.value);
                                handleValidateStepper();
                                setValue('closingtime', value.value);
                              }}
                            ></Select>
                            {errors?.closingtime && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.closingtime?.message}
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          class="row justify-content-between text-left"
                          style={{ paddingTop: '10px' }}
                        >
                          <div class="form-group col-sm-4 col-md-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Delivery Window Start #1<span class="text-danger"> *</span>
                            </label>
                            <Select
                              class="form-control"
                              name="Delivery Window Start #1"
                              placeholder="Delivery Window Start #1"
                              theme={customTheme}
                              value={{
                                label: `${deliverywindowstart}`,
                                value: `${deliverywindowstart}`,
                              }}
                              options={timeOptions}
                              onChange={(value) => {
                                setdeliverywindowstart(value.value);
                                handleValidateStepper();
                                setValue('deliverywindowstart', value.value);
                              }}
                            ></Select>
                            {errors?.deliverywindowstart && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.deliverywindowstart?.message}
                              </div>
                            )}
                          </div>
                          <div class="form-group col-sm-4 col-md-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Delivery Window End #1<span class="text-danger"> *</span>
                            </label>
                            <Select
                              class="form-control"
                              name="Delivery Window End #1"
                              placeholder="Delivery Window End #1"
                              theme={customTheme}
                              value={{
                                label: `${deliverywindowend}`,
                                value: `${deliverywindowend}`,
                              }}
                              options={timeOptions}
                              onChange={(value) => {
                                setdeliverywindowend(value.value);
                                handleValidateStepper();
                                setValue('deliverywindowend', value.value);
                              }}
                            ></Select>
                            {errors?.deliverywindowend && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.deliverywindowend?.message}
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          class="row justify-content-between text-left"
                          style={{ paddingTop: '10px' }}
                        >
                          <div class="form-group col-sm-4 col-md-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Delivery Window Start #2<span class="text-danger"> *</span>
                            </label>
                            <Select
                              class="form-control"
                              name="Delivery Window Start #2"
                              placeholder="Delivery Window Start #2"
                              theme={customTheme}
                              value={{
                                label: `${deliverywindow2start}`,
                                value: `${deliverywindow2start}`,
                              }}
                              options={timeOptions}
                              onChange={(value) => {
                                setdeliverywindow2start(value.value);
                                handleValidateStepper();
                                setValue('deliverywindow2start', value.value);
                              }}
                            ></Select>
                            {errors?.deliverywindow2start && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.deliverywindow2start?.message}
                              </div>
                            )}
                          </div>
                          <div class="form-group col-sm-4 col-md-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Delivery Window End #2<span class="text-danger"> *</span>
                            </label>
                            <Select
                              class="form-control"
                              name="Delivery Window End #2"
                              placeholder="Delivery Window End #2"
                              theme={customTheme}
                              value={{
                                label: `${deliverywindow2end}`,
                                value: `${deliverywindow2end}`,
                              }}
                              options={timeOptions}
                              onChange={(value) => {
                                setdeliverywindow2end(value.value);
                                handleValidateStepper();
                                setValue('deliverywindow2end', value.value);
                              }}
                            ></Select>
                            {errors?.deliverywindow2end && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.deliverywindow2end?.message}
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          class="row justify-content-between text-left"
                          style={{ paddingTop: '10px' }}
                        >
                          <div class="form-group col-sm-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Latitude
                              <span class="text-danger"> *</span>
                            </label>
                            <input
                              value={latitude}
                              disabled={true}
                              onChange={(e) => {
                                setlatitude(e.target.value);
                                handleValidateStepper();
                              }}
                            />{' '}
                          </div>
                          <div class="form-group col-sm-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Longitude
                              <span class="text-danger"> *</span>
                            </label>
                            <input
                              value={longitude}
                              disabled={true}
                              onChange={(e) => {
                                setlongitude(e.target.value);
                                handleValidateStepper();
                              }}
                            />{' '}
                          </div>
                        </div>
                        <div
                          class="row justify-content-between text-left"
                          style={{ paddingTop: '10px' }}
                        >
                          <div class="form-group col-sm-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Additional Service Instructions
                            </label>
                            <textarea
                              id="add-comment-follow-up"
                              disabled={status == 'Approved'}
                              className="form-control"
                              maxlength="40"
                              rows="3"
                              placeholder="Enter comment"
                              value={additionalserviceinstruction}
                              onChange={(e) => {
                                setadditionalserviceinstruction(e.target.value);
                                handleValidateStepper();
                              }}
                            ></textarea>{' '}
                          </div>
                        </div>
                      </>
                    )}

                    {activeStep === 8 && (
                      <>
                        <div>
                          <div class="row justify-content-between text-left">
                            <label class="form-control-label px-3">Attachments:</label>
                          </div>
                          {attachments?.length > 0 && (
                            <div class="territoriescontainer" style={{ paddingTop: '10px' }}>
                              {refattachments.current?.map((val) => (
                                <div class="item">
                                  <a
                                    onClick={Downloadfile}
                                    name={val.fileName}
                                    style={{
                                      textDecoration: 'underline',
                                      color: '#0d6efd',
                                      fontWeight: '700',
                                    }}
                                    value={val.fileName}
                                  >
                                    {val.fileName}
                                  </a>
                                </div>
                              ))}
                            </div>
                          )}
                          <div
                            class="row justify-content-between text-left"
                            style={{ paddingTop: '10px' }}
                          >
                            <Dropzone
                              accept="image/png,image/jpeg,application/pdf,video/mp4,video/mpeg,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,application/zip,application/x-zip-compressed,application/vnd.openxmlformats-officedocument.presentationml.presentation,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.ppt,.pptx,.msg"
                              getUploadParams={getUploadParams}
                              onChangeStatus={handleChangeStatus}
                              getFilesFromEvent={getFilesFromEvent}
                              initialFiles={acceptedfiles}
                              disabled={status == 'Approved'}
                              maxSizeBytes={5000000}
                              styles={{
                                dropzone: { height: 250 },
                                dropzoneActive: {
                                  borderColor: 'green',
                                  backgroundColor: '#baffb3',
                                },
                                dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
                                inputLabel: (files, extra) =>
                                  extra.reject ? { color: 'red' } : {},
                              }}
                            />
                          </div>
                        </div>
                        <div
                          class="row justify-content-between text-left"
                          style={{ paddingTop: '10px' }}
                        >
                          <div class="form-group col-sm-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              A/R Billing Cycle
                              {localStorage.getItem('role') == 'Sales_Manager' && (
                                <span class="text-danger"> *</span>
                              )}
                            </label>
                            <Select
                              class="form-control"
                              isDisabled={
                                status == 'Approved' ||
                                localStorage.getItem('role') != 'Sales_Manager'
                              }
                              name="email"
                              placeholder=""
                              onblur="validate(3)"
                              theme={customTheme}
                              value={{ label: `${arbillingcycle}`, value: `${arbillingcycle}` }}
                              options={arcyclelist}
                              onChange={(value) => {
                                setarbillingcycle(value.value);
                                handleValidateStepper();
                                setValue('arbillingcycle', value.value);
                              }}
                            >
                              <option value="">Select</option>
                              {arcyclelist.map((arcyclelist) => (
                                <option value={arcyclelist['A/R_Cycle_Description']}>
                                  {arcyclelist['A/R_Cycle_Description']}
                                </option>
                              ))}
                            </Select>
                            {errors?.arbillingcycle && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.arbillingcycle?.message}
                              </div>
                            )}{' '}
                          </div>
                          <div class="form-group col-sm-6 flex-column d-flex">
                            {' '}
                            <label class="form-control-label px-3">
                              Planogrammable Status
                              <span class="text-danger"> *</span>
                            </label>
                            <Select
                              class="form-control"
                              name="email"
                              isDisabled={status == 'Approved'}
                              placeholder=""
                              onblur="validate(3)"
                              theme={customTheme}
                              value={{
                                label: `${planogrammablestatus}`,
                                value: `${planogrammablestatus}`,
                              }}
                              options={planogrammablelist}
                              onChange={(value) => {
                                setplanogrammablestatus(value.value);
                                handleValidateStepper();
                                setValue('planogrammablestatus', value.value);
                              }}
                            >
                              <option value="">Select</option>
                              {planogrammablelist?.map((planogrammablelist) => (
                                <option value={planogrammablelist.Planogrammable_Status}>
                                  {planogrammablelist.Planogrammable_Status}
                                </option>
                              ))}
                            </Select>{' '}
                            {errors?.planogrammablestatus && (
                              <div style={{ color: 'red', marginTop: '.3rem' }}>
                                {errors?.planogrammablestatus?.message}
                              </div>
                            )}{' '}
                          </div>
                        </div>
                        <div
                          class="row justify-content-between text-left"
                          style={{ paddingTop: '10px' }}
                        >
                          {/* <div class="form-group col-sm-6 flex-column d-flex">
                    {' '}
                    <label class="form-control-label px-3">
                    Territories
                    </label>
                    <Select
                      class="form-control"
                      name="email"
                      placeholder=""
                      isDisabled={status == "Approved"}
                      onblur="validate(3)"
                      theme={customTheme}
                      value={{label: `${territories}`,value: `${territories}`}}
                      options={territorieslist}
                      onChange={value => {setterritories(value.value); handleValidateStepper();}}
                    >
                      <option value="">Select</option>
                      {territorieslist?.map((territorieslist) => 
                <option value={territorieslist.Territory_Description}>{territorieslist.Territory_Description}</option>
                )}
                    </Select>{' '}
                  </div> */}
                          {company === 'EUGENE' && (
                            <div class="form-group col-sm-6 flex-column d-flex">
                              {' '}
                              <label class="form-control-label px-3">
                                Which warehouse will this retailer be serviced out of?
                              </label>
                              <Select
                                class="form-control"
                                name="email"
                                placeholder=""
                                isDisabled={status == 'Approved'}
                                onblur="validate(3)"
                                theme={customTheme}
                                value={{
                                  label: `${whichwarehousewillthisretailerbeservicedoutof}`,
                                  value: `${whichwarehousewillthisretailerbeservicedoutof}`,
                                }}
                                options={warehouselist}
                                onChange={(value) => {
                                  setwhichwarehousewillthisretailerbeservicedoutof(value.value);
                                  console.log(warehouse);
                                }}
                              >
                                <option value="">Select</option>
                                {warehouselist?.map((warehouselist) => (
                                  <option value={warehouselist.Warehouse_Description}>
                                    {warehouselist.Warehouse_Description}
                                  </option>
                                ))}
                              </Select>
                            </div>
                          )}
                        </div>
                        {status === 'Rejected' && (
                          <div
                            class="row justify-content-between text-left"
                            style={{ paddingTop: '10px' }}
                          >
                            <div class="form-group col-sm-6 flex-column d-flex">
                              {' '}
                              <label class="form-control-label px-3">Rejection Reason</label>
                              <textarea
                                id="add-comment-follow-up"
                                disabled={status == 'Approved'}
                                className="form-control"
                                maxlength="40"
                                rows="3"
                                placeholder="Enter comment"
                                value={rejectionreason}
                                onChange={(e) => {
                                  setrejectionreason(e.target.value);
                                  handleValidateStepper();
                                }}
                              ></textarea>{' '}
                            </div>
                          </div>
                        )}
                      </>
                    )}

                    {activeStep === 9 && (
                      <>
                        {territorieslist.length > 1 && (
                          <div>
                            <label class="form-control-label px-3">Select All Territories</label>{' '}
                            <input
                              onChange={(e) => {
                                handleSelectAll(e);
                              }}
                              checked={territoriesselectall}
                              type="checkbox"
                            ></input>
                          </div>
                        )}
                        <div class="territoriescontainer" style={{ paddingTop: '10px' }}>
                          {refterritorieslist.current?.map((territorieslist) => (
                            <div class="item">
                              <div class="row justify-content-between text-left">
                                <div class="form-group col-sm-3 flex-column d-flex">
                                  <input
                                    value={territorieslist.value}
                                    onChange={(e) => {
                                      handleTerritories(e);
                                    }}
                                    checked={territorieslist.isChecked}
                                    type="checkbox"
                                  ></input>
                                </div>
                                <div class="form-group col-sm-9 flex-column d-flex">
                                  {territorieslist.value}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                  {isMobile === 'vertical' && (
                    <MobileStepper
                      variant="text"
                      steps={maxSteps}
                      position="static"
                      activeStep={activeStep}
                      nextButton={
                        <Button
                          size="small"
                          onClick={handleNext}
                          disabled={activeStep === maxSteps - 1}
                        >
                          Next
                          {theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft />
                          ) : (
                            <KeyboardArrowRight />
                          )}
                        </Button>
                      }
                      backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                          {theme.direction === 'rtl' ? (
                            <KeyboardArrowRight />
                          ) : (
                            <KeyboardArrowLeft />
                          )}
                          Back
                        </Button>
                      }
                    />
                  )}
                  {isMobile === 'horizontal' && (
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        Back
                      </Button>
                      <Box sx={{ flex: '1 1 auto' }} />

                      <Button onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                        {'Next'}
                      </Button>
                    </Box>
                  )}
                </div>
              </React.Fragment>
            </Box>
          </>
          {successData && (
            <ConfirmAlert
              confirm={hideAlert}
              hideAlert={hideAlert}
              cancelButton={false}
              confirmBtnText="OK"
              type={successData === true ? 'success' : 'error'}
              title={successData === true ? `${requeststatus}` : 'Error'}
              body={
                successData === true
                  ? `${submitdraftstatus} - Request No: ${requestno}`
                  : `${successData}`
              }
            />
          )}
          {successData4 && (
            <ConfirmAlert
              confirm={hideAlert}
              hideAlert={hideAlert}
              cancelButton={false}
              confirmBtnText="OK"
              type={successData4 === true ? 'success' : 'error'}
              title={successData4 === true ? `${requeststatus}` : 'Error'}
              body={successData4 === true ? `${submitdraftstatus}${retailerid}` : `${successData4}`}
            />
          )}
          {successData2 == true && (
            <ConfirmAlert
              confirm={hideAlert}
              hideAlert={hideAlert}
              cancelButton={false}
              confirmBtnText="OK"
              type={'error'}
              title={'Error'}
              body={`${submitdraftstatus}`}
            />
          )}
          {successData3 && (
            <ConfirmAlert
              confirm={hideAlert2}
              hideAlert={hideAlert2}
              cancelButton={false}
              confirmBtnText="OK"
              type={successData3 === true ? 'success' : 'error'}
              title={successData3 === true ? `${requeststatus}` : 'Error'}
              body={
                successData3 === true ? `${submitdraftstatus} - ${requestno}` : `${successData3}`
              }
            />
          )}
        </Form>
      </Container>
    </div>
  );
}

export default FinalForm;
