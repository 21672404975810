import React from 'react';
import Allcomplaints from './cq-portal/All Complaints/Complaints';
import Navbar from './Navbar';
import '../assets/styles/App.css';
// import { useHistory } from "react-router-dom";
import { Redirect } from 'react-router-dom';
/**
 * Renders information about the user obtained from MS Graph
 * @param props
 */

import { useIsAuthenticated } from '@azure/msal-react';

const Home = (props) => {
  // const history = useHistory();

  // const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  if (!isAuthenticated && !props.loggedInViaInput) {
    return (
      <>
        <Redirect to={{ pathname: '/login' }} />;
      </>
    );
  }
  return (
    (isAuthenticated || props.loggedInViaInput) && (
      <>
        <Navbar />
        <Allcomplaints />
      </>
    )
  );
};

export default Home;
