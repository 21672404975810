import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from '../authConfig';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { Login, Home, Footer, Navbar } from './';

import Axios from 'axios';
//CQPortalComponents
import AddComplaint2 from './cq-portal/RetailerForm';
// import Currencyconverter from "./cq-portal/currencyconverter";
// import Table3 from "./cq-portal/table3"
import '../assets/styles/App.css';
import ReactDOM from 'react-dom';
// import Followup from "./cq-portal/Followup";
// import StarComponent from "./cq-portal/StarComponent"
import Signup from './rbac/Signup';
import NavbarComponent from './Navbar.js';
import RetailerForm from './cq-portal/RetailerForm';
import FinalForm from './cq-portal/FinalForm';
import ContactUs from './ContactUs';

ReactDOM.render(
  <Router>
    <Switch>
    <Route path="/contact-us" component={ContactUs} />  
    </Switch>
  </Router>,
  document.getElementById('root')
);


const Pages = () => {
  const isAuthenticated = useIsAuthenticated();
  const [isLoggedInViaInput, setisLoggedInViaInput] = useState(false);
  const changeLoggedInViaInput = (bool) => {
    setisLoggedInViaInput(bool);
  };
  const [username, setusername] = useState();
  const [password, setpassword] = useState();
  const [visitlog, setvisitlog] = useState(0);

  // useEffect(() => {
  //   if(visitlog != 1 && isAuthenticated){
  //   Axios.post("/loginlogs/api",
  //   {requestno: null,
  //     activityname: "Request Page Visited",
  //     activitydescription: "The request page is visited by the user",
  //     pagename: "Request Page",
  //       emailid: localStorage.getItem("username"),
  //       empid: localStorage.getItem("employeeID")
  //   }).then(response => {
  //     console.log(response.data)
  //     setvisitlog(1)
  //   })
  // }
  // }, [])

  return (
    <Switch>
      <Route path="/register">
        <NavbarComponent />
        <Signup
          isLoggedInViaInput={isLoggedInViaInput}
          setisLoggedInViaInput={setisLoggedInViaInput}
        />
      </Route>
      <Route
        path="/add-retailer"
        render={(props) => {
          if (
            localStorage.getItem('username')?.length > 0 &&
            (localStorage.getItem('role') === 'Sales_Manager' ||
              localStorage.getItem('role') === 'Employee' ||
              localStorage.getItem('role') === 'MDM_Team')
          )
            return (
              <p>
                <h1>You are not authorized to view this</h1>
              </p>
            );
          else if (localStorage.getItem('username')?.length > 0) return <AddComplaint2 />;
          else return <Redirect to={{ pathname: '/login' }} />;
        }}
      >
        <RetailerForm
          isLoggedInViaInput={isLoggedInViaInput}
          setisLoggedInViaInput={setisLoggedInViaInput}
        />
      </Route>
      <Route
        exact
        path="/"
        render={(props) => {
          return <Home {...props} loggedInViaInput={isLoggedInViaInput} />;
        }}
      />

      <Route
        path="/login"
        render={(props) => {
          return (
            <Login
              {...props}
              username={username}
              setusername={setusername}
              password={password}
              setpassword={setpassword}
              loggedInViaInputFn={changeLoggedInViaInput}
              loggedInViaInput={isLoggedInViaInput}
              setisLoggedInViaInput={setisLoggedInViaInput}
            />
          );
        }}
      />

      <Route
        exact
        path="/request/num=:reqno"
        render={(props) => {
          if (localStorage.getItem('username')?.length > 0) {
            if (localStorage.getItem('role') === 'Employee') {
              return <h1>You are not authorized to view this page</h1>;
            }
            return <FinalForm />;
          } else return <Redirect to={{ pathname: '/login' }} />;
        }}
      />

        <Route exact path="/contact-us"  >
        <Navbar/>
        <ContactUs></ContactUs>
        </Route>

      <Route
        path="*"
        render={(props) => {
          return (
            <h1>
              Error 404
              <br />
              Page Not Found
            </h1>
          );
        }}
      />
    </Switch>
  );
};
function App() {
  const msalInstance = new PublicClientApplication(msalConfig);

  return (
    <div className="App">
      <header className="App-header">
        <Router>
          <MsalProvider instance={msalInstance}>
            {navigator.onLine && <Pages />}
            {/* {!navigator.onLine && <NoInternet />} */}
          </MsalProvider>
        </Router>
      </header>
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default App;
