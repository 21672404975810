import React, { useEffect, useState } from 'react';
import './styles/Signup.css';
import Axios from 'axios';
import ConfirmAlert from './common/ConfirmAlert';
import MultipleValueTextInput from 'react-multivalue-text-input';

// var aesjs = require('aes-js');
const Signup = () => {
  const apikey = 'g68$mtj*VUljCGDSd%C6kwTLXPy87'
  const [soldTo, setSoldTo] = useState([]);

  const [soldToList, setSoldToList] = useState([]);
  const [soldToError, setSoldToError] = useState([]);
  var temp;
  const [successData, setSuccessData] = useState();
  const [companylist,setcompanylist] = useState([''])
  const [warehouselist,setwarehouselist] = useState([''])
  // console.log(temp, 'temp');
  // console.log(soldToList, 'soldtolist');

  const [emailError, setEmailError] = useState();
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [firstname, setfirstname] = useState('');
  const [lastname, setlastname] = useState('');
  const [company, setcompany] = useState('');
  const [warehouse, setwarehouse] = useState('');
  const [empid, setempid] = useState('');
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [role, setRole] = useState();
  var pwd;
  // var key= [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
  var hashed;

  var userId, roleId;
  const isValidEmail = (value) => {
    var validRegex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (value.match(validRegex)) {
      setEmailError('Valid Email');
    } else setEmailError('Invalid Email');
  };

  useEffect(() => {
    Axios.get(`https://acoeretailmdappprod1.azurewebsites.net/dropdown/registercompanylist?apikey=${apikey}`).then(function (response) {
      // handle success
      console.log(response?.data);
      setcompanylist(response?.data);
      console.log("This is complaint type")
      
      })
      .catch(function (error) {
      // handle error
      console.log(error);
      })

  }, [])

  useEffect(() => {
    setwarehouse('')
    Axios.get(`https://acoeretailmdappprod1.azurewebsites.net/dropdown/registerwarehouselist?apikey=${apikey}&company=${company}`).then(function (response) {
      // handle success
      console.log(response?.data);
      setwarehouselist(response?.data);
      console.log("This is complaint type")
      
      })
      .catch(function (error) {
      // handle error
      console.log(error);
      })
  }, [company])
  

  const onSubmit = (e) => {
    e.preventDefault();
    // db main request jayegi create account ki
    //random passowrd generate hoga

    // var textBytes = aesjs.utils.utf8.toBytes(hashed);
    // var aesCtr = new aesjs.ModeOfOperation.ctr(key, new aesjs.Counter(5));
    // var encryptedBytes = aesCtr.encrypt(textBytes);
    // var encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);

    Axios
      .post('/createUser', { email: email, firstname: firstname, lastname:lastname, company: company, warehouse: warehouse, empid: empid , role: role })
      .then((response) => {
        setSuccessData(response?.data?.rowsAffected[0] == 1 ? true : false);
        return Axios.post('/sendEmail', { email: email });
      })
      .then((response) => {
        console.log(response);
      });
  };
  async function hideAlert() {
    window.location.reload();
  }
  const isDuplicateEmail = (value) => {
    Axios.get(`/isDuplicate?email=${value}`).then((response) => {
      if (response?.data?.recordset?.length >= 1) {
        setIsDuplicate(true);
      } else setIsDuplicate(false);
    });
  };

  if (
    localStorage.getItem('username')?.length == 0 ||
    (localStorage.getItem('role')?.length > 0 && localStorage.getItem('role') !== 'MDM_Team')
  ) {
    return (
      <>
        <p>You are not Authorized to view this page</p>
      </>
    );
  }
  return (
    <>
      <div class="container-fluid px-1 py-5 mx-auto">
        <div class="row d-flex justify-content-center">
          <div class="col-xl-7 col-lg-8 col-md-9 col-11 text-center">
            <h3>Register a User</h3>
            {/* <p class="blue-text">Just answer a few questions<br/> so that we can personalize the right experience for you.</p> */}
            <div class="card" style={{ padding: '30px 40px', borderRadius: '5px' }}>
              {/* <h5 class="text-center mb-4">Powering world-class companies</h5> */}
              <form class="form-card" onsubmit="event.preventDefault()">
              <div class="row justify-content-between text-left">
                  <div class="form-group col-sm-6 flex-column d-flex">
                    {' '}
                    <label class="form-control-label px-3">
                      First Name
                      <span class="text-danger"> *</span>
                    </label>
                    <input value = {firstname} onChange={e => setfirstname(e.target.value)} />{' '}
                  </div>
                  <div class="form-group col-sm-6 flex-column d-flex">
                    <label class="form-control-label px-3">
                      Last Name<span class="text-danger"> *</span>
                    </label>
                    <input value = {lastname} onChange={e => setlastname(e.target.value)} />
                  </div>
                </div>
                <div class="row justify-content-between text-left">
                  <div class="form-group col-sm-6 flex-column d-flex">
                    {' '}
                    <label class="form-control-label px-3">
                      Email
                      <span class="text-danger"> *</span>
                      {!isDuplicate && emailError === 'Invalid Email' ? (
                        <font style={{ fontSize: '12px', color: 'red ' }}>{emailError}</font>
                      ) : null}
                      {!isDuplicate && emailError === 'Valid Email' ? (
                        <font style={{ fontSize: '12px', color: 'green' }}>{emailError}</font>
                      ) : null}
                      {isDuplicate ? (
                        <font style={{ fontSize: '12px', color: 'red ' }}>
                          Email already registered
                        </font>
                      ) : null}
                    </label>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      placeholder=""
                      onChange={(e) => {
                        setEmail(e.target.value);
                        isValidEmail(e.target.value);
                        isDuplicateEmail(e.target.value);
                      }}
                      onblur="validate(3)"
                    />{' '}
                  </div>
                  <div class="form-group col-sm-6 flex-column d-flex">
                    <label class="form-control-label px-3">
                      Employee Id<span class="text-danger"> *</span>
                    </label>
                    <input value = {empid} onChange={e => setempid(e.target.value)} />
                  </div>
                </div>

                <div class="row justify-content-between text-left">
                  <div class="form-group col-sm-6 flex-column d-flex">
                    {' '}
                    <label class="form-control-label px-3">
                      Company
                      <span class="text-danger"> *</span>
                    </label>
                    <select
                      class="form-control"
                      name="email"
                      placeholder=""
                      value={company}
                      onChange={(e) => {
                        setcompany(e.target.value);
                      }}
                      onblur="validate(3)"
                    >
                      <option value="">Select</option>
                      {companylist?.map((companylist) => 
                <option value={companylist.value}>{companylist.value}</option>
                )}
                    </select>{' '}
                  </div>
                  <div class="form-group col-sm-6 flex-column d-flex">
                    {' '}
                    <label class="form-control-label px-3">
                      Warehouse
                      <span class="text-danger"> *</span>
                    </label>
                    <select
                      class="form-control"
                      name="email"
                      placeholder=""
                      onChange={(e) => {
                        setwarehouse(e.target.value);
                      }}
                      onblur="validate(3)"
                    >
                      <option value="">Select</option>
                      {warehouselist?.map((warehouselist) => 
                <option value={warehouselist.value}>{warehouselist.value}</option>
                )}
                    </select>{' '}
                  </div>
                  </div>
                
                <div class="row justify-content-between text-left">
                  <div class="form-group col-sm-6 flex-column d-flex">
                    {' '}
                    <label class="form-control-label px-3">
                      Role
                      <span class="text-danger"> *</span>
                    </label>
                    <select
                      class="form-control"
                      name="email"
                      placeholder=""
                      onChange={(e) => {
                        setRole(e.target.value);
                      }}
                      onblur="validate(3)"
                    >
                      <option value="">Select</option>
                      <option value="Sales_Representative">Sales Representative</option>
                      <option value="Sales_Manager">Sales Manager</option>
                      <option value="MDM_Team">MDM Team</option>
                    </select>{' '}
                  </div>
                </div>

                <div class="row justify-content-end">
                  <div class="form-group col-sm-6">
                    <button
                      type="submit"
                      class="button btn-block btn-primary"
                      onClick={onSubmit}
                      disabled={
                        isDuplicate == true ||
                        emailError == 'Invalid Email' ||
                        !role?.length > 0||
                        !warehouse?.length > 0||
                        !company?.length > 0||
                        !firstname?.length > 0||
                        !lastname?.length > 0
                      }
                    >
                      Create Account
                    </button>{' '}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {successData && (
        <ConfirmAlert
          confirm={hideAlert}
          hideAlert={hideAlert}
          cancelButton={false}
          confirmBtnText="Ok"
          type={successData === true ? 'success' : 'warning'}
          title={successData === true ? 'Created' : 'Failed'}
          body={
            successData === true
              ? 'User Created Successfully\n Please check your mail for password'
              : `${successData}`
          }
        />
      )}
    </>
  );
};

export default Signup;
