import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { Card, FormGroup } from 'react-bootstrap';
import '../assets/styles/Login.css';
import ABILogo from '../assets/images/abi_logo_black.png';
import appLogo from '../assets/images/Hive_Logo_Set/SVG/Full Logo/Hive_Logo_Black.svg';
import DSCOELogo from '../assets/images/dscoe_logo.png';
import { Button } from 'react-bootstrap';

import { loginRequest } from '../authConfig';

import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
function useFormInputs(initialInput) {
  const [value, setValue] = useState(initialInput);
  function handleChange(e) {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange,
  };
}
const Login = (props) => {
  const history = useHistory();
  const { instance, inProgress } = useMsal();

  const isAuthenticated = useIsAuthenticated();

  const [error, setError] = useState('');
  const [iserror, setisError] = useState(false);
  const [emailError, setEmailError] = useState();
  useEffect(() => {
    console.log(`AUTH LOG = ${isAuthenticated}`);
    if (isAuthenticated && InteractionStatus.None) history.push('/');
  }, [inProgress]);
  var roleAssigned;

  useEffect(() => {
    if (localStorage.getItem('username')) props.loggedInViaInputFn(true);
  }, []);
  console.log(`AUTH LOG = ${isAuthenticated}`);
  console.log('change', props.loggedInViaInput);

  const onSubmit = () => {
    const isValid = validationcheck();
    if (isValid) {
      axios
        .get(`/fetchSaltKey?email=${props.username}`)
        .then((response) => {
          return axios.get(
            `/checkRecord?email=${props.username}&password=${props.password}&saltKey=${response.data.recordset[0]?.saltKey}`,
          );
        })
        .then((response) => {
          if (response?.data?.rowsAffected[0] == 0) {
            setisError(true);
            setError('Username / Password not correct');
          } else {
            localStorage.setItem('username', props.username);
            props.loggedInViaInputFn(true);
            axios.get(`/fetchRole?email=${localStorage.getItem('username')}`).then((response) => {
              if (response?.data?.recordset?.length > 0) {
                roleAssigned = response?.data?.recordset[0]?.roleName;
                localStorage.setItem('role', response?.data?.recordset[0]?.roleName);
              } else {
                roleAssigned = 'Employee';
                localStorage.setItem('role', 'Employee');
              }
              if (response?.data?.recordset?.length > 0) {
                localStorage.setItem('firstName', response?.data?.recordset[0]?.firstName);
                localStorage.setItem('lastName', response?.data?.recordset[0]?.lastName);
                localStorage.setItem('employeeID', response?.data?.recordset[0]?.employeeID);
                localStorage.setItem('company', response?.data?.recordset[0]?.company);
                localStorage.setItem('warehouse', response?.data?.recordset[0]?.warehouse);
                localStorage.setItem('userID', response?.data?.recordset[0]?.userID);
              } else {
                localStorage.setItem('firstName', '');
                localStorage.setItem('lastName', '');
                localStorage.setItem('employeeID', '');
                localStorage.setItem('company', '');
                localStorage.setItem('warehouse', '');
                localStorage.setItem('userID', '');
              }
            });
          }

          props.loggedInViaInputFn(response?.data?.rowsAffected[0] == 1 ? true : false);
        });
    }
  };
  // console.log(props.loggedInViaInputFn,'login check')
  const validationcheck = () => {
    let username_error = '';
    let password_error = '';

    if (!props.username) {
      username_error = 'Please enter username';

      setError(username_error);
      setisError(true);
    }

    if (!props.password) {
      password_error = 'Please enter password';

      if ({ error } === '') {
        setError(password_error);
        setisError(true);
      }
    }

    if (username_error || password_error) {
      return false;
    } else {
      return true;
    }
  };
  if (isAuthenticated || props.loggedInViaInput) {
    return <Redirect to={{ pathname: '/' }} />;
  }

  const isValidEmail = (value) => {
    var validRegex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (value.match(validRegex)) setEmailError('Valid Email');
    else setEmailError('Invalid Email');
  };
  return (
    !isAuthenticated &&
    !props.loggedInViaInput &&
    !localStorage.getItem('username'?.length) > 0 && (
      <div className="container">
        <div className="row" style={{ paddingTop: '10vw' }}>
          <div className="col col-login mx-auto">
            <Card>
              <Card.Body className="p-6">
                <div className="text-center mb-6">
                  <img src={appLogo} height="200px" width="179px" alt="App Logo" />
                </div>

                <div className="card-title text-center">
                  <p>
                    {`Please use your `}
                    <strong className="golden-text">{`AB InBev ID`}</strong>
                    {` to login`}
                  </p>
                </div>

                <div className="form-footer">
                  <Button
                    className="btn btn-primary btn-block"
                    onClick={() => instance.loginRedirect(loginRequest)}
                  >
                    {`Login`}
                  </Button>
                </div>
              </Card.Body>
              <Card.Footer>
                <div className="text-center">
                  <img
                    src={ABILogo}
                    className="h-5"
                    alt="AB InBev Logo"
                    style={{ paddingRight: '10px' }}
                  />
                  <img
                    src={DSCOELogo}
                    className="h-9"
                    alt="DS CoE Logo"
                    style={{
                      borderLeft: '1px solid #c9c9c9',
                      paddingLeft: '18px',
                    }}
                  />
                </div>
              </Card.Footer>
            </Card>
          </div>
        </div>
      </div>
    )
  );
};

export default Login;
