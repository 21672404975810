import { useState, useEffect } from 'react'
import axios from 'axios'

import { urls } from '../utils/apiConfigs'
import { graphConfig } from '../utils/authConfig'

export default {
  getMedicalReps: async function () {
    try {
      const response = await axios.get(urls.getMedicalRepsURL)
      return response.data
    } catch (error) {
      throw error
    }
  },

  getStates: async function () {
    try {
      const response = await axios.post(urls.getStatesURL)
      return response.data
    } catch (error) {
      throw error
    }
  },

  getCities: async function (stateCode) {
    try {
      const response = await axios.post(urls.getCitiesURL, {
        stateCode: stateCode,
      })
      return response.data
    } catch (error) {
      throw error
    }
  },

  getMSGraphPhoto: async function (accessToken) {
    try {
      const headers = new Headers()
      const bearer = `Bearer ${accessToken}`

      headers.append('Authorization', bearer)

      const options = {
        method: 'GET',
        headers: headers,
      }

      console.log('TRYING TO GET PROFILE PHOTO')

      return fetch(graphConfig.graphProfilePhotoEndpoint, options)
        .then((response) => response.blob())
        .catch((error) => console.log(error))
    } catch (error) {
      throw error
    }
  },
  

}
