import React, { useEffect, useState, useContext, useMemo } from 'react';
import {
  TextInput,
  Textarea,
  SimpleGrid,
  Group,
  Title,
  Button,
  FileInput,
  Autocomplete,
  Tooltip,
  ActionIcon,
  Text,
  Loader,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { Card, Modal, Container, Row } from 'react-bootstrap';
import {
  IconCheck,
  IconTrendingUp2,
  IconUpload,
  IconInfoCircle,
  IconX,
  IconPlaystationX,
  IconFileUpload,
} from '@tabler/icons-react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';


var SYS_ID;
var INC_NUM;

const ContactUs = () => {
  const sys_id1 = 'https://dscoeinternalbrandingbackend-prod.azurewebsites.net/api/mySysID/';
  const sys_id2 = 'https://dscoeinternalbrandingbackend-prod.azurewebsites.net/api/uploadFile/';
  const sys_id3 = 'https://dscoeinternalbrandingbackend-prod.azurewebsites.net/api/incidentSysId/';
  const sys_id4 = 'https://dscoeinternalbrandingbackend-prod.azurewebsites.net/api/pushIncident';
  const sys_id5 = 'https://dscoeinternalbrandingbackend-prod.azurewebsites.net/api/createIncident/';

  const { instance, accounts } = useMsal();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [files, setFiles] = useState([]);
  const [validate, setValidate] = useState(true);
  // const handleValidate = () => setValidate(false);
  const [showProgress, setShowProgress] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState();
  function handleSelect(options1) {
    setSelectedOptions(options1);
  }
  const [errors, setErrors] = useState({
    shortDescription: false,
    issueDescription: false,
    location:false,
  });

  const form = useForm({
    initialValues: {
      shortDescription: '',
      issueDescription: '',
      location: '',
    },
    validate: {
      shortDescription: (value) => value.trim().length === 0,
      issueDescription: (value) => value.trim().length === 0,
      location: (value) => value.trim().length === 0,
    },
  });

  const options1 = [
    {
      label: 'AB ARLINGTON',
      value: 'AB ARLINGTON',
    },
    {
      label: 'AB WASHINGTON',
      value: 'AB WASHINGTON',
    },
    {
      label: 'ANHEUSER BUSCH SALES BAY AREA',
      value: 'ANHEUSER BUSCH SALES BAY AREA',
    },
    {
      label: 'ANHEUSER BUSCH SALES OF CANTON',
      value: 'ANHEUSER BUSCH SALES OF CANTON',
    },
    {
      label: 'ANHEUSER BUSCH-BEACH CITIES',
      value: 'ANHEUSER BUSCH-BEACH CITIES',
    },
    {
      label: 'ANHEUSER BUSCH-CENTRAL LA',
      value: 'ANHEUSER BUSCH-CENTRAL LA',
    },
    {
      label: 'ANHEUSER BUSCH-POMONA',
      value: 'ANHEUSER BUSCH-POMONA',
    },
    {
      label: 'ANHEUSER BUSCH-SAN DIEGO',
      value: 'ANHEUSER BUSCH-SAN DIEGO',
    },
    {
      label: 'ANHEUSER BUSCH-SYLMAR',
      value: 'ANHEUSER BUSCH-SYLMAR',
    },
    {
      label: 'AUGUST A. BUSCH BOSTON',
      value: 'AUGUST A. BUSCH BOSTON',
    },
    {
      label: 'EUENE',
      value: 'EUENE',
    },
    {
      label: 'EUGENE',
      value: 'EUGENE',
    },
    {
      label: 'MEDFORD',
      value: 'MEDFORD',
    },
    {
      label: 'New York',
      value: 'New York',
    },
    {
      label: 'OKLAHOMA CITY [REGULAR]',
      value: 'OKLAHOMA CITY [REGULAR]',
    },
    {
      label: 'PORTLAND',
      value: 'PORTLAND',
    },
    {
      label: 'TULSA',
      value: 'TULSA',
    },
    {
      label: 'WESTERN',
      value: 'WESTERN',
    },
    {
      label: 'ALL LOCATIONS',
      value: 'ALL LOCATIONS',
    },
  ];

  const getSysID = async (user) => {
    // console.log('inside getSysID fxn:', localStorage.getItem('snow_api_access_token'), user);
    const config = {
      headers: { Authorization: `${localStorage.getItem('snow_api_access_token')}` },
    };

    axios
      .get(`${sys_id1}${localStorage.getItem('username')}`, config)
      .then((res) => {
        // console.log(res);
        SYS_ID = res.data.result[0].sys_id;
        // console.log('Inside get sys ID', SYS_ID);
      })
      .catch((e) => {
        // console.log(e);
      });
  };

  useEffect(() => {
    getSysID();
  }, []);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setFiles((prevSelectedFiles) => [...prevSelectedFiles, ...files.map((file) => file.name)]);
    setValidate(false);
  };

  const removeAttachment = (fileName) => {
    setFiles((prevSelectedFiles) => {
      const prevFilesArray = Array.isArray(prevSelectedFiles) ? prevSelectedFiles : [];
      const updatedFiles = prevFilesArray.filter((file) => file !== fileName);

      if (updatedFiles.length === 0) {
        setValidate(true);
      }
      return updatedFiles;
    });
  };

  const onSubmit = (e) => {
    console.log('E in on submit', e)
    setShowProgress(true);
    e.preventDefault();

    const value = form.values;

    // if (value.location === '' || value.shortDescription === '' || value.issueDescription === '') {
    //   setErrors({
    //     shortDescription: value.shortDescription === '' ? true : false,
    //     issueDescription: value.issueDescription === '' ? true : false,
    //     location: value.location === '' ? true : false,
    //   });
    //   setShowProgress(false);
    //   return;
    // }

    let data = {};

    data['sys_id'] = SYS_ID;
    data['username'] = localStorage.getItem('username');
    data['issueDescription'] = value.issueDescription;
    data['issueType'] = 'Technical';
    data['shortDescription'] = 'Project:Hive - WOD Retailer ||' + value.shortDescription;
    data['location'] = value.location;
    data['projectName'] = 'Hive - WOD Retailer';
    data['zone'] = value.location;
    data['priority'] = '3';
    data['typeOfSolution'] = 'PWA';
    data['issueType'] = 'u_performance';
    data['pointOfContact'] = localStorage.getItem('username');
    data['assignmentGroup'] = '47259ddc97fda91071adb9b0f053af61';
    data['typeOfSolution'] = 'PWA';
    data['businessService'] = '62a65f2fdbf92090c014fe03f3961915';

    const uploadFile = async (data) => {
      // console.log('Inside Upload File');
      const formData = new FormData();
      formData.append('attachment', files[0]);
      formData.append('sys_id', data['IncidentSysId']);
      // console.log('Inside uploadFile', files[0], data['IncidentSysId']);

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('snow_api_access_token')}`,
        },
      };
      axios.post(sys_id2, formData, config).then((res) => {
        // console.log('File Uploaded', res);
      });
    };

    const getIncidentSysID = async (incidentNumber) => {
      // console.log('Inside Get Incident Sys ID');
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('snow_api_access_token')}` },
      };
      axios.get(`${sys_id3}${incidentNumber}`, config).then((res) => {
        // console.log(res, res.data.result[0].sys_id);

        data['IncidentSysId'] = res.data.result[0].sys_id;
        // console.log(`Incident Sys ID Fetched : ${data['IncidentSysId']}`);
        uploadFile(data);
      });
    };

    const pushSQL = async (data) => {
      // console.log('inside pushSQL');
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('snow_api_access_token')}` },
      };
      axios.post(sys_id4, data, config).then((res) => {
        // console.log(res);
      });
      setShowProgress(false);
    };

    const ContactUs = async (data) => {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('snow_api_access_token')}` },
      };
      axios
        .post(sys_id5, data, config)
        .then((res) => {
          // console.log('Inside Raise Ticket');
          // console.log(res);
          data['IncidentNumber'] = res.data.result.number;
          INC_NUM = data['IncidentNumber'];
          // console.log(INC_NUM);
          if (files[0]?.name?.length > 0) getIncidentSysID(INC_NUM);
          pushSQL(data);
          setTimeout(() => {
            handleShow();
          }, 2000);
        })
        .catch((e) => {
          // console.log(e);
          alert('Upload Error');
        });
    };

    ContactUs(data);
  };

  return (
    <div className="container mb-4 mt-2" style={{
      width: window.innerWidth < 768 ? '90vw' : '70vw'
    }}>
      <form onSubmit={onSubmit}>
        <div xs={12} sm={11} md={11} lg={9} style={{ margin: 'auto', padding: '0 5vw 0 5vw' }}>
          <Card style={{ padding: '20px 30px 30px 40px', width: '100%' }}>
            <Row>
              <div className="list-group list-group-transparent mb-0">
                <Text
                  size="xl"
                  weight={780}
                  color="yellow"
                  align="left"
                  style={{ fontSize: '30px' }}
                >{`Contact Us`}</Text>
              </div>
            </Row>
            <hr
              style={{
                color: 'black',
                marginTop: '1rem',
                marginBottom: '1rem',
              }}
            />
            <Row>
              <div className="note" style={{ paddingBottom: '17px' }}>
                <Text
                  className="noteBoxes type3"
                  size="md"
                  weight={600}
                  color="black"
                  align="left"
                >{`Please fill the following details to create a support ticket on your queries. The team will get back to you shortly.`}</Text>
              </div>
            </Row>

            <div style={{ paddingBottom: '9px' }}>
              <Text size="md" weight={500} color="black" align="left">{`Location`}</Text>
            </div>
            <Row>
              <div
                style={{
                  paddingBottom: '17px',
                  minWidth: '250px',
                  width: '31.5vw',
                  borderRadius: '14px',
                  marginTop: '0px',
                }}
              >
                <Select
                  options={options1}
                  placeholder="Select Location"
                  value={selectedOptions}
                  error={errors.location}
                  onChange={(e) => {
                    handleSelect(e)
                    setValidate(false)
                  }}
                  // onChange={handleSelect}
                  isSearchable={true}
                  required
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      backgroundColor: 'white',
                      border: '1px solid #ccc',
                      borderRadius: '14px',
                      alignText: "left",
                      width: '100%',
                    }),
                    menu: (provided) => ({
                      ...provided,
                      width: '100%',
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      textAlign: 'left',
                      fontSize: '14px',
                      fontWeight: 'lighter',
                      color: "#bebebe",
                      align: "left"
                    }),
                  }}
                />

              </div>
            </Row>

            <Row>
              <div style={{ paddingBottom: '7px' }}>
                <Text size="md" weight={500} color="black" align="left">{`Subject *`}</Text>
              </div>
            </Row>
            <Row>
              <div style={{ paddingBottom: '17px' }}>
                <TextInput
                  placeholder="Enter Subject"
                  required
                  mt="md"
                  name="shortDescription"
                  radius="14px"
                  style={{
                    minWidth: '250px',
                    width: '30vw',
                    borderRadius: '14px',
                    marginTop: '0px',
                  }}
                  {...form.getInputProps('shortDescription')}
                  error={errors.shortDescription}
                />
              </div>
            </Row>
            <Row>
              <div style={{ paddingBottom: '7px' }}>
                <Text
                  size="md"
                  weight={500}
                  color="black"
                  align="left"
                >{`Short Description *`}</Text>
              </div>
            </Row>
            <Row>
              <div style={{ paddingBottom: '17px' }}>
                <Textarea
                  mt="md"
                  required
                  placeholder="Enter Short Description"
                  maxRows={10}
                  minRows={3}
                  autosize
                  name="issueDescription"
                  border="2px"
                  radius="14px"
                  style={{ minWidth: '250px', width: '30vw', marginTop: '0px' }}
                  {...form.getInputProps('issueDescription')}
                  error={errors.issueDescription}
                />
              </div>
            </Row>
            <Row>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'left',
                  flexWrap: 'wrap',
                }}
              >
                <div style={{ paddingBottom: '7px' }}>
                  <Text size="md" weight={500} color="black" align="left">{`Attachment `}</Text>
                </div>
                <div>
                  <Tooltip
                    multiline
                    maxWidth={270}
                    withArrow
                    transition="fade"
                    transitionDuration={200}
                    label={
                      <div>
                        <div>Provide relevant error screenshots regarding your query.</div>{' '}
                        <div>For multiple attachments please provide the zip folder.</div>
                      </div>
                    }
                  >
                    <ActionIcon>
                      <IconInfoCircle size={18} strokeWidth={2} color={'#fab005'} />
                    </ActionIcon>
                  </Tooltip>
                </div>

                <div style={{ paddingLeft: '10px' }}>
                  <Row>
                    <Text
                      noWrap
                      size="11px"
                      weight={400}
                      color="black"
                      align="left"
                      style={{ paddingTop: '2px' }}
                    >{`(Strictly allowed File types : .zip, .png , .jpeg, .jpg, .docx, .xlsx, .csv, .pdf)`}</Text>
                  </Row>
                </div>
              </div>
            </Row>
            <Row>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'left',
                  flexWrap: 'wrap',
                }}
              >
                <div style={{ width: '30vw' }}>
                  <input
                    type="file"
                    className="form-control"
                    id="mp-attachment"
                    multiple={false}
                    style={{ display: 'none' }}
                    accept="image/png,image/jpeg,application/pdf,text/csv,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/zip"
                    onChange={(e) => {
                      setFiles(e.target.files);
                    }}
                  />
                  <div
                    className="upload-sec"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: '9px 9px 3px 9px',
                      border: '1px solid #ccc',
                      borderRadius: '5px',
                      marginTop: '5px',
                      cursor: 'pointer',
                      maxWidth: '30vw',
                    }}
                  >
                    <div style={{ paddingRight: '15px' }}>
                      <label style={{ marginBottom: '0.2rem' }}>Choose Files</label>
                      <div
                        style={{
                          maxWidth: 150,
                          fontWeight: 600,
                          fontSize: 10,
                          paddingBottom: '3px',
                        }}
                      >
                        {' '}
                        (Max-Size: 2MB){' '}
                      </div>
                    </div>
                    <div>
                      <label htmlFor="mp-attachment" id="uploadEvidence">
                        <Button
                          className="icon-button"
                          variant="contained"
                          color="primary"
                          component="span"
                        >
                          <IconFileUpload className="icon-button-contained" />
                        </Button>
                      </label>
                    </div>
                  </div>
                </div>
                <div
                  className="attachment-links"
                  style={{ paddingLeft: '10px', paddingTop: '30px', maxWidth: '30vw' }}
                >
                  {files[0]?.name ? <div>{files[0]?.name}</div> : <div>{files[0]?.fileName}</div>}
                </div>
                <div
                  style={{ paddingLeft: '5px', paddingTop: '30px', width: '3px', height: '3px' }}
                >
                  {files[0]?.name ? (
                    <div>
                      <IconPlaystationX
                        className="close-icon"
                        onClick={removeAttachment}
                        style={{ cursor: 'pointer' }}
                      ></IconPlaystationX>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </Row>
            <Group position="right" mt="xl">
              <Link to="/">
                <Button
                  color="gray"
                  radius="xl"
                  leftIcon={<IconX size={16} color="white" />}
                >{`Cancel`}</Button>
              </Link>
              <Button
                type="submit"
                color="yellow"
                radius="xl"
                leftIcon={<IconCheck size={16} color="white" />}
              >{`Submit`}</Button>
            </Group>
          </Card>
        </div>
      </form>
      <Modal show={showProgress} backdrop="static" keyboard={false} centered>
        <Modal.Header>
          <Modal.Title>Thank You!</Modal.Title>
        </Modal.Header>
        <Modal.Body text-align="center">
          Please wait we are communicating your query to SNOW team.
          <Loader
            variant="bars"
            color="gray"
            size="lg"
            style={{
              margin: 'auto',
              display: 'block',
              paddingTop: '15px',
            }}
          />
        </Modal.Body>
      </Modal>
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
        <Modal.Header>
          <Modal.Title>Note</Modal.Title>
          <Link to="/">
            <IconX color="black" />
          </Link>
        </Modal.Header>

        <Modal.Body text-align="center">
          Your ticket has been raised with Incident Number: {INC_NUM}.
        </Modal.Body>
        <Modal.Footer>
          <Link to="/">
            <Button
              color="gray"
              radius="xl"
              leftIcon={<IconX size={16} color="white" />}
            >{`Close`}</Button>
          </Link>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ContactUs;
